import { FormGroupDirective } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appConnectForm]'
})
export class ConnectFormDirective {
  @Input('appConnectForm')
  set data(val: any) {
    if (val) {
      this.formGroupDirective.form.patchValue(val);
    }
  }

  constructor(
    private formGroupDirective: FormGroupDirective
  ) {
  }
}
