import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { asyncScheduler, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() label?: string;
  @Input() filesLabel: string = 'JPG, PNG, GIF, SVG, SVG';
  @Input() labelForId?: string;
  @Input() sizeLimit: number = 1_000_000;
  @Input() accept: string[] = ['image/*'];

  public file: any | undefined = undefined;

  public readonly fileUploadControl = new FileUploadControl(
    {listVisible: true, accept: this.accept, discardInvalid: true},
    [
      FileUploadValidators.accept(this.accept),
      FileUploadValidators.filesLimit(1),
      FileUploadValidators.sizeLimit(this.sizeLimit)
    ]
  );

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  public onTouched = (m: any) => {
  };
  private onChange = (m: any) => {
  };

  constructor() {
  }

  ngOnInit(): void {
    this.fileUploadControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.onTouched(value[0]);
        this.onChange(value[0]);
      });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const errors = this.fileUploadControl.getError();
    return errors && errors.length > 0 ? errors[0] : null;
  }

  public removeFile(): void {
    this.file = undefined;
    this.onChange(null);

    asyncScheduler.schedule(() => this.fileUploadControl.setValue([]));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.fileUploadControl.disable() : this.fileUploadControl.enable();
  }

  writeValue(value: any): void {
    if (value) {
      this.file = value;
    }
  }
}
