<div class="talent-row cursor-pointer" [routerLink]="'/talent/' + talent.slug">
  <div class="talent-row__name">
    <span
      class="label-status"
      [ngClass]="{
        'text-gray': talent?.status === 'passive',
        'text-red': talent?.status === 'none',
        'text-green': talent?.status === 'active'
      }"
    >
      {{talent?.status}}
    </span>

    {{talent?.user?.fullName}}
  </div>

  <div class="talent-row__item flex flex-col">
    {{vocabularies?.['category']?.terms[talent?.category]?.title}}

    <span class="font-normal">
      {{vocabularies?.['position']?.terms[talent?.position]?.title}}
    </span>
  </div>

  <div class="talent-row__salary font-normal">
    {{talent.salaryFrom}} - {{talent.salaryTo}}$
  </div>

  <div class="talent-row__level flex flex-col">
    <span *ngFor="let language of talent?.languages">
      {{vocabularies?.['languages']?.terms[language.language]?.title}} -
      {{vocabularies?.['languageLevels']?.terms[language.level]?.title}}
    </span>
  </div>

  <div class="talent-row__location">
    <div class="flex items-center gap-2 text-sm">
      <svg-icon src="location.svg" class="flex-none"></svg-icon>
      {{talent?.location?.city}}
    </div>
  </div>

  <div class="talent-row__item flex flex-col">
    <span *ngFor="let level of talent?.level">
      {{vocabularies?.['level']?.terms[level]?.title}}
    </span>
  </div>

  <!--@TODO: version 2.1-->
  <div class="talent-row__favorite" *ngIf="false">
    <div class="favorite">
      <svg-icon src="heart.svg"></svg-icon>
    </div>
  </div>
</div>
