import { localStorageSync } from 'ngrx-store-localstorage';
import { ActionReducer, MetaReducer } from '@ngrx/store';
import { isPlatformBrowser } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { CookieService } from 'ngx-cookie';

import { environment } from '../../environments/environment';

export function emptyReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return reducer;
}

export class CookieStorage implements Storage {
  constructor(
    private cookieService: CookieService,
    private platform: Platform,
  ) {
  }

  readonly length: number = 0;

  clear(): void {
    this.cookieService.removeAll();
  }

  getItem(key: string): string | null {
    const value = this.cookieService.get(key);

    if (value) {
      return JSON.parse(value);
    }

    return null;
  }

  key(index: number): string | null {
    return this.cookieService.hasKey(index.toString()).toString();
  }

  removeItem(key: string): void {
    this.cookieService.remove(key);
  }

  setItem(key: string, value: string): void {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);

    if (isPlatformBrowser(this.platform)) {
      this.cookieService.put(key, JSON.stringify(value), {path: '/', expires: expires.toUTCString()});
    }
  }
}

export function cookieStorageReducer(cookieService: CookieService, platform: Platform): MetaReducer {
  if (environment.local) {
    return emptyReducer;
  }

  return localStorageSync({
    keys: [
      {core: ['language', 'useCookie', 'mainNavOpened']},
      {auth: ['userType', 'authorized']}
    ],
    rehydrate: true,
    checkStorageAvailability: false,
    storage: new CookieStorage(cookieService, platform),
    storageKeySerializer: (key) => `${environment.projectName}_${key}`
  });
}
