<div
  #progressLoader
  class="loader"
  [ngClass]="{
    'black': color === 'black',
    'white': color === 'white'
  }"
>
  <div class="progress" *ngIf="showProgress" @fadeOutOnLeave>
    <div class="progress__bar"></div>
  </div>
  <div class="progress-done" *ngIf="showCheckMark">
    <svg-icon src="checked.svg"></svg-icon>
  </div>
</div>
