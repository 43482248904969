import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ErrorMessageComponent } from './error-message.component';
import { ErrorMessageDirective } from './error-message.directive';
import { ErrorMessagePipe } from './error-message.pipe';

@NgModule({
  declarations: [
    ErrorMessageComponent,
    ErrorMessageDirective,
    ErrorMessagePipe
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    ErrorMessageDirective
  ],
})
export class ErrorMessageModule {
}
