import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  constructor(
    private http: HttpClient
  ) {
  }

  public getVocabularies(): Observable<any> {
    return this.http.get(`${environment.api}/taxonomies/vocabularies`, {
      params: {
        asAnArray: false,
      }
    });
  }
}
