<div class="block-video">
  <div class="block-video__preview" [class.closing]="showVideo">
    <img
      class="w-full h-full object-cover"
      src="https://img.youtube.com/vi/{{id}}/maxresdefault.jpg"
      [alt]="company.name + '-video'"
    >

    <button class="block-video__start" (click)="playVideo()">
      <svg-icon src="arrow-right.svg"></svg-icon>
    </button>
  </div>

  <div class="block-video__video">
    <youtube-player
      [videoId]="id"
      (ready)="savePlayer($event)"
    ></youtube-player>
  </div>
</div>
