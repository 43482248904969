<div class="modal-confirm z-50 mx-auto lg:my-auto bg-yellow text-black">
  <div class="modal-confirm__icon">
    <img src="/assets/smiles/thinking-face.png" alt="Icon">
  </div>

  <div class="modal-confirm__content">{{ label | translate }}</div>

  <p *ngIf="message">{{message | translate}}</p>

  <form
    [formGroup]="form"
    *ngIf="confirmationCheck?.length"
    class="flex flex-col gap-3"
  >
    <span
      class="font-medium"
      [innerHTML]="'modal.confirmation_check' | translate: {value: confirmationCheck | translate}"
    ></span>
    <input type="text" class="yellow" formControlName="string">
  </form>

  <div class="modal-confirm__action max-w-[700px] w-full mx-auto">
    <button class="big-btn big-btn--small bg-black text-white" (click)="result = false; close()">
      {{cancelLabel | translate }}
    </button>

    <button
      *ngIf="!confirmationCheck?.length"
      (click)="result = true; close()"
      class="big-btn big-btn--small big-btn__border"
    >
      {{confirmLabel | translate }}
    </button>

    <button
      *ngIf="confirmationCheck?.length"
      (click)="result = true; close()"
      [disabled]="form.invalid"
      class="big-btn big-btn--small big-btn__border"
    >
      {{confirmLabel | translate}}
    </button>
  </div>
</div>
