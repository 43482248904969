import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Observable, of} from 'rxjs';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';
import { UserType } from '../../../config';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(
    private guardHelperService: GuardHelperService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { url } = state;

    return this.guardHelperService.getUser()
      .pipe(
        switchMap(user => {
          const type = user.type ? user.type : UserType.TALENT;

          const command = [
            type === UserType.TALENT
              ? `/talent/${user?.talent?.slug}/dashboard`
              : `/company/${user?.company?.slug}/dashboard`
          ];

          if (user && user.registrationCompletedAt && !url.includes(command[0])) {
            this.router.navigate(command);
            return of(false);
          }

          return of(true);
        })
      );
  }
}
