import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../../helpers/utils';

@Pipe({
  name: 'appIncludesActions'
})
export class IncludesActionsPipe implements PipeTransform {
  transform(values: any, ...args: any[]): boolean | null {
    return (values ?? []).some((value: string) => Utils.getRequestsName(args).includes(value));
  }
}
