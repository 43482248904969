import { fadeInUpOnEnterAnimation, fadeOutDownOnLeaveAnimation } from 'angular-animations';
import { Component, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-ellipse-menu',
  templateUrl: './ellipse-menu.component.html',
  styleUrls: ['./ellipse-menu.component.scss'],
  animations: [
    fadeInUpOnEnterAnimation({duration: 404}),
    fadeOutDownOnLeaveAnimation({duration: 404})
  ]
})
export class EllipseMenuComponent {
  public isOpenJobMenu = false;

  @ViewChild('ellipseMenu') ellipseMenu: any;

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any): void {
    const clickedInside = this.ellipseMenu?.nativeElement.contains(target);

    if (!clickedInside) {
      this.isOpenJobMenu = false;
    }
  }

  constructor() {
  }

  public onOpenJobMenu(): void {
    this.isOpenJobMenu = !this.isOpenJobMenu
  }
}
