import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PagerComponent),
      multi: true
    }
  ]
})
export class PagerComponent implements OnChanges, ControlValueAccessor {
  @Input() pageCount!: number;
  @Input() itemsToShow: number = 3;

  public currentPage = 1;
  public items: number[] = [];

  private onTouched = () => {
  };
  private onChange = (m: any) => {
  };

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  ngOnChanges({ pageCount }: SimpleChanges) {
    if (pageCount?.currentValue) {
      this.buildPager();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {
    this.currentPage = parseInt(value, 10);
  }

  public changeCurrentPage(goToPage: number) {
    if (goToPage >= 1 && goToPage <= this.pageCount) {
      this.onChange(goToPage);
      this.currentPage = goToPage;
      this.buildPager();
    }
  }

  private buildPager() {
    const pages = [];

    let leftOffset = Math.floor((this.itemsToShow - 1) / 2);
    let rightOffset = Math.ceil((this.itemsToShow - 1) / 2);

    if (this.currentPage - leftOffset < 1) {
      rightOffset += Math.abs(this.currentPage - leftOffset) + 1;
    }

    if (this.currentPage + rightOffset > this.pageCount) {
      leftOffset -= this.pageCount - (this.currentPage + rightOffset);
    }

    const left = Math.max(1, this.currentPage - leftOffset);
    const right = Math.min(this.pageCount, this.currentPage + rightOffset);

    for (let i = left; i <= right; i++) {
      pages.push(i);
    }

    this.items = pages;
  }
}
