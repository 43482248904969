<section class="flex flex-col items-center h-full gap-y-7">
  <div class="tab-items" #tabGroup>
    <div
      *appHasRoles="[roles.COMPANY_ADMIN, roles.COMPANY_EMPLOYEE]"
      class="tab"
      (click)="toggleTab($event, 'talents')"
      routerLink="talents"
      routerLinkActive="active"
    >
      <span class="pointer-events-none">
        {{ 'search.tabs.talents' | translate }}
      </span>
    </div>
    <div
      (click)="toggleTab($event, 'jobs')"
      class="tab"
      routerLink="jobs"
      routerLinkActive="active"
    >
      <span class="pointer-events-none">
        {{ 'search.tabs.jobs' | translate }}
      </span>
    </div>
    <div
      class="tab"
      (click)="toggleTab($event, 'companies')"
      routerLink="companies"
      routerLinkActive="active"
    >
      <span class="pointer-events-none">
        {{ 'search.tabs.companies' | translate }}
      </span>
    </div>
    <div
      class="tab-items__underline"
      [ngStyle]="styles"
    ></div>
  </div>

  <section [@routeSlide]="routeTrigger$ | async" class="w-full relative flex-grow">
    <router-outlet (activate)="onActivate()"></router-outlet>
  </section>
</section>
