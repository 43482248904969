import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { Utils } from '../../../helpers/utils';

@Injectable({ providedIn: 'root' })
export class SearchService {
  constructor(private http: HttpClient) {
  }

  public searchCompanies(body?: any) {
    const data = Utils.filterData(body);

    return this.http.post(`${environment.api}/company/search`, data);
  }

  public searchJobs(body?: any) {
    const data = Utils.filterData(body);

    return this.http.post(`${environment.api}/job/search`, data);
  }

  public searchTalents(body?: any) {
    const data = Utils.filterData(body);

    return this.http.post(`${environment.api}/talent/search`, data);
  }
}
