import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DateValidators } from '../../validators/date.validator';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true
    }
  ]
})
export class DateRangeComponent implements OnInit, ControlValueAccessor {
  @Input() label: string = 'Date';
  @Input() forNow: boolean = true;
  @Input() forNowLabel: string = 'For Now';

  private onTouched = () => {
  };
  private onChange = (m: any) => {
  };

  public id = Math.random().toString(36).substring(2);
  public form = this.formBuilder.group({
    from: [null],
    to: [null],
    forNow: [false],
  }, {
    validators: [
      DateValidators.dateLessThan('from', 'to', 'to'),
    ]
  });

  @AutoUnsubscribe()
  private readonly unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
  ) {
  }

  get forNowFC(): FormControl {
    return this.form.get('forNow') as FormControl;
  }

  get toFC(): FormControl {
    return this.form.get('to') as FormControl;
  }

  get fromFC(): FormControl {
    return this.form.get('from') as FormControl;
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.setValue(value));

    this.forNowFC.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value) {
          this.toFC.disable();
        } else {
          this.toFC.enable();
        }
      });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  writeValue(value: any): void {
    this.form.patchValue(value, {emitEvent: false, onlySelf: true});
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.setRequireValidation(control.hasValidator(Validators.required));
    return this.form.valid ? null : {invalidDateRange: true};
  }

  private setValue(value: any): void {
    this.onChange(value);
    this.onTouched();
  }

  private setRequireValidation(value: boolean): void {
    if (value) {
      this.fromFC.setValidators(Validators.required);
      this.toFC.setValidators(Validators.required);
    }
  }
}
