<div class="ellipse-menu" #ellipseMenu>
  <div class="ellipse-menu__icon" (click)="onOpenJobMenu()">
    <svg-icon src="ellipse-menu.svg"></svg-icon>
  </div>
  <ul
    class="ellipse-menu-dropdown bg-black text-white dark:bg-tundora dark:text-white"
    *ngIf="isOpenJobMenu"
    @fadeInUpOnEnter
    @fadeOutDownOnLeave
  >
    <ng-content></ng-content>
  </ul>
</div>
