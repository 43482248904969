import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';

import { CompanyPageComponent } from './containers/company-page/company-page.component';
import { companyFeatureKey, companyReducer } from './reducers/company.reducer';
import { CompanyRoutingModule } from './company-routing.module';
import { CompanyEffects } from './effects/company.effects';

@NgModule({
  declarations: [
    CompanyPageComponent,
  ],
  imports: [
    CompanyRoutingModule,
    CommonModule,
    StoreModule.forFeature(companyFeatureKey, companyReducer),
    EffectsModule.forFeature([CompanyEffects])
  ]
})
export class CompanyModule {
}
