import { createAction, props } from '@ngrx/store';

import { IActionRequestSuccess, IRequestError } from '../../../models';

const selectTalent = createAction('[Talent] Select Talent', props<{ slug: string }>());

const getTalentProfile = createAction('[Talent] Get Talent Profile Request', props<{ slug: string }>());
const getTalentProfileSuccess = createAction(
  '[Talent] Get Talent Profile Success',
  props<IActionRequestSuccess<{ profile: any }>>(),
);
const getTalentProfileFailure = createAction('[Talent] Get Talent Profile Failure', props<IRequestError>());

const getTalent = createAction('[Talent] Get Talent Request', props<{ slug: string }>());
const getTalentSuccess = createAction('[Talent] Get Talent Success', props<IActionRequestSuccess<{ talent: any }>>());
const getTalentFailure = createAction('[Talent] Get Talent Failure', props<IRequestError>());

const getTalentRecommendedJobs = createAction('[Talent] Get Talent Recommended Jobs Request', props<{ id: string }>());
const getTalentRecommendedJobsSuccess = createAction('[Talent] Get Talent Recommended Jobs Success', props<IActionRequestSuccess<{ recommendedJobs: any[] }>>());
const getTalentRecommendedJobsFailure = createAction('[Talent] Get Talent Recommended Jobs Failure', props<IRequestError>());

const getTalentFeedbacks = createAction('[Talent] Get Talent Feedbacks Request', props<{ id: string }>());
const getTalentFeedbacksSuccess = createAction('[Talent] Get Talent Feedbacks Success', props<IActionRequestSuccess<{ feedbacks: any[] }>>());
const getTalentFeedbacksFailure = createAction('[Talent] Get Talent Feedbacks Failure', props<IRequestError>());

const getTalents = createAction('[Talent] Get Talents Profile Request', props<{ slug: string }>());
const getTalentsSuccess = createAction('[Talent] Get Talents Profile Success', props<IActionRequestSuccess<{ profile: any }>>());
const getTalentsFailure = createAction('[Talent] Get Talents Profile Failure', props<IRequestError>());

const patchPersonalInfo = createAction('[Talent] Patch Personal Info Request', props<{ id: string, body: string, slug: string }>());
const patchPersonalInfoSuccess = createAction(
  '[Talent] Patch Personal Info Success',
  props<IActionRequestSuccess<{ profile: any, slug: string }>>(),
);
const patchPersonalInfoFailure = createAction('[Talent] Patch Personal Info Failure', props<IRequestError>());

const patchExperience = createAction('[Talent] Patch Experience Request', props<{ id: string, body: string }>());
const patchExperienceSuccess = createAction(
  '[Talent] Patch Experience Success',
  props<IActionRequestSuccess<{ profile: any }>>(),
);
const patchExperienceFailure = createAction('[Talent] Patch Experience Failure', props<IRequestError>());

const patchSkills = createAction('[Talent] Patch Skills Request', props<{ id: string, body: string }>());
const patchSkillsSuccess = createAction(
  '[Talent] Patch Skills Success',
  props<IActionRequestSuccess<{ profile: any }>>(),
);
const patchSkillsFailure = createAction('[Talent] Patch Skills Failure', props<IRequestError>());

const patchLanguages = createAction('[Talent] Patch Languages Request', props<{ id: string, body: string }>());
const patchLanguagesSuccess = createAction(
  '[Talent] Patch Languages Success',
  props<IActionRequestSuccess<{ profile: any }>>(),
);
const patchLanguagesFailure = createAction('[Talent] Patch Languages Failure', props<IRequestError>());

const patchAdditionalInfo = createAction('[Talent] Patch Additional Info Request', props<{ id: string, body: string }>());
const patchAdditionalInfoSuccess = createAction(
  '[Talent] Patch Additional Info Success',
  props<IActionRequestSuccess<{ profile: any }>>(),
);
const patchAdditionalInfoFailure = createAction('[Talent] Patch Additional Info Failure', props<IRequestError>());

const createWorkExp = createAction('[Talent] Create Work Exp Request', props<{ id: string, body: string }>());
const createWorkExpSuccess = createAction(
  '[Talent] Create Work Exp Success',
  props<IActionRequestSuccess<{ workExperiences: any[] }>>(),
);
const createWorkExpFailure = createAction('[Talent] Create Work Exp Failure', props<IRequestError>());

const patchWorkExp = createAction('[Talent] Patch Work Exp Request', props<{ id: string, body: any }>());
const patchWorkExpSuccess = createAction(
  '[Talent] Patch Work Exp Success',
  props<IActionRequestSuccess<{ id: string, workExp: any }>>(),
);
const patchWorkExpFailure = createAction('[Talent] Patch Work Exp Failure', props<IRequestError>());

const deleteWorkExp = createAction('[Talent] Delete Work Exp Request', props<{ id: string }>());
const deleteWorkExpSuccess = createAction(
  '[Talent] Delete Work Exp Success',
  props<IActionRequestSuccess<{ id: string }>>(),
);
const deleteWorkExpFailure = createAction('[Talent] Delete Work Exp Failure', props<IRequestError>());

const createEducation = createAction('[Talent] Create Education Request', props<{ id: string, body: string }>());
const createEducationSuccess = createAction(
  '[Talent] Create Education Success',
  props<IActionRequestSuccess<{ educations: any[] }>>(),
);
const createEducationFailure = createAction('[Talent] Create Education Failure', props<IRequestError>());

const patchEducation = createAction('[Talent] Patch Education Request', props<{ id: string, body: any }>());
const patchEducationSuccess = createAction(
  '[Talent] Patch Education Success',
  props<IActionRequestSuccess<{ id: string, education: any }>>(),
);
const patchEducationFailure = createAction('[Talent] Patch Education Failure', props<IRequestError>());

const deleteEducation = createAction('[Talent] Delete Education Request', props<{ id: string }>());
const deleteEducationSuccess = createAction(
  '[Talent] Delete Education Success',
  props<IActionRequestSuccess<{ id: string }>>()
);
const deleteEducationFailure = createAction('[Talent] Delete Education Failure', props<IRequestError>());

const createCourse = createAction('[Talent] Create Course Request', props<{ id: string, body: string }>());
const createCourseSuccess = createAction(
  '[Talent] Create Course Success',
  props<IActionRequestSuccess<{ courses: any[] }>>(),
);
const createCourseFailure = createAction('[Talent] Create Course Failure', props<IRequestError>());

const patchCourse = createAction('[Talent] Patch Course Request', props<{ id: string, body: any }>());
const patchCourseSuccess = createAction(
  '[Talent] Patch Course Success',
  props<IActionRequestSuccess<{ id: string, course: any }>>(),
);
const patchCourseFailure = createAction('[Talent] Patch Course Failure', props<IRequestError>());

const deleteCourse = createAction('[Talent] Delete Course Request', props<{ id: string }>());
const deleteCourseSuccess = createAction(
  '[Talent] Delete Course Success',
  props<IActionRequestSuccess<{ id: string }>>(),
);
const deleteCourseFailure = createAction('[Talent] Delete Course Failure', props<IRequestError>());

const updateTalentSettings = createAction('[Talent] Update Talent Settings Request', props<{ body: any }>());
const updateTalentSettingsSuccess = createAction('[Talent] Update Talent Settings Success', props<{ profile: any }>());
const updateTalentSettingsFailure = createAction('[Talent] Update Talent Settings Failure', props<IRequestError>());

export const TalentActions = {
  selectTalent,

  getTalentProfile,
  getTalentProfileSuccess,
  getTalentProfileFailure,

  getTalent,
  getTalentSuccess,
  getTalentFailure,

  getTalentRecommendedJobs,
  getTalentRecommendedJobsSuccess,
  getTalentRecommendedJobsFailure,

  getTalentFeedbacks,
  getTalentFeedbacksSuccess,
  getTalentFeedbacksFailure,

  getTalents,
  getTalentsSuccess,
  getTalentsFailure,

  patchPersonalInfo,
  patchPersonalInfoSuccess,
  patchPersonalInfoFailure,

  patchExperience,
  patchExperienceSuccess,
  patchExperienceFailure,

  patchSkills,
  patchSkillsSuccess,
  patchSkillsFailure,

  patchLanguages,
  patchLanguagesSuccess,
  patchLanguagesFailure,

  patchAdditionalInfo,
  patchAdditionalInfoSuccess,
  patchAdditionalInfoFailure,

  createWorkExp,
  createWorkExpSuccess,
  createWorkExpFailure,

  patchWorkExp,
  patchWorkExpSuccess,
  patchWorkExpFailure,

  deleteWorkExp,
  deleteWorkExpSuccess,
  deleteWorkExpFailure,

  createEducation,
  createEducationSuccess,
  createEducationFailure,

  patchEducation,
  patchEducationSuccess,
  patchEducationFailure,

  deleteEducation,
  deleteEducationSuccess,
  deleteEducationFailure,

  createCourse,
  createCourseSuccess,
  createCourseFailure,

  patchCourse,
  patchCourseSuccess,
  patchCourseFailure,

  deleteCourse,
  deleteCourseSuccess,
  deleteCourseFailure,

  updateTalentSettings,
  updateTalentSettingsSuccess,
  updateTalentSettingsFailure,
}
