import { createReducer, on } from '@ngrx/store';

import { CoreActions } from '../actions/core.actions';
import { languages } from '../../config';

export const coreFeatureKey = 'core';

export interface State {
  language: string;
  theme: string;
  useCookie: boolean | null;
  vocabularies: any;
  mainNavOpened: boolean;
  patchingEntities: any;
  deletingEntities: any;
}

export const initialState: State = {
  language: languages[0].value,
  theme: 'light',
  useCookie: null,
  vocabularies: {},
  mainNavOpened: false,
  patchingEntities: {},
  deletingEntities: {},
};

export const coreReducer = createReducer(
  initialState,

  on(CoreActions.changeLanguage, (state, {language}) => ({...state, language})),

  on(CoreActions.setTheme, (state, {theme}) => ({...state, theme})),

  on(CoreActions.toggleMainNav, (state, {mainNavOpened}) => ({...state, mainNavOpened})),

  on(CoreActions.getVocabulariesSuccess, (state, {vocabularies}) => ({...state, vocabularies})),

  on(CoreActions.patchingEntity, (state, {id}) => ({
    ...state,
    patchingEntities: {
      ...state.patchingEntities,
      [id]: true,
    },
  })),

  on(CoreActions.patchingEntityFinished, (state, {id}) => ({
    ...state,
    patchingEntities: {
      ...state.patchingEntities,
      [id]: false,
    },
  })),

  on(CoreActions.deletingEntity, (state, {id}) => ({
    ...state,
    deletingEntities: {
      ...state.deletingEntities,
      [id]: true,
    },
  })),

  on(CoreActions.deletingEntityFinished, (state, {id}) => ({
    ...state,
    deletingEntities: {
      ...state.deletingEntities,
      [id]: false,
    },
  })),
);
