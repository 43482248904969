import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule, makeStateKey, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';
import { META_REDUCERS, Store, StoreModule } from '@ngrx/store';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import player from 'lottie-web/build/player/lottie_light';
import { CookieModule, CookieService } from 'ngx-cookie';
import { EffectsModule } from '@ngrx/effects';
import { LottieModule } from 'ngx-lottie';

import { ChangeLanguagesService } from './shared/services/change-languages.service';
import { AppPageComponent } from './core/containers/app-page/app-page.component';
import { loaderReducers, loadingFeatureKey } from './reducers/loader.reducer';
import { errorReducers, errorsFeatureKey } from './reducers/error.reducer';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { cookieStorageReducer } from './reducers/cookie-storage.reducer';
import { stateTransferReducer } from './reducers/state-transfer.reducer';
import { SvgBrowserLoader } from './shared/loaders/svg-browser.loader';
import { localStorageReducer } from './reducers/local-storage.reducer';
import { AmplitudeService } from './shared/services/amplitude.service';
import { AuthActions } from './modules/auth/actions/auth.actions';
import { LoadingEffects } from './effects/loading.effects';
import { environment } from '../environments/environment';
import { CoreActions } from './core/actions/core.actions';
import { ModulesModule } from './modules/modules.module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

export function playerFactory() {
  return player;
}

export function svgLoaderFactory(transferState: TransferState, httpClient: HttpClient) {
  return new SvgBrowserLoader(transferState, httpClient);
}

export const NGRX_STATE = makeStateKey('NGRX_STATE');
@NgModule({
  declarations: [],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    TransferHttpCacheModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    LottieModule.forRoot({ player: playerFactory }),
    StoreModule.forRoot({
      [loadingFeatureKey]: loaderReducers,
      [errorsFeatureKey]: errorReducers,
    }, {}),
    EffectsModule.forRoot([
      LoadingEffects,
    ]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    AngularSvgIconModule.forRoot({
      loader: {
        deps: [TransferState, HttpClient],
        useFactory: svgLoaderFactory,
        provide: SvgLoader,
      }
    }),
    TranslateModule.forRoot({
      useDefaultLang: false,
      loader: {
        provide: TranslateLoader,
        useClass: ChangeLanguagesService,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    CookieModule.withOptions(),
    ModulesModule,
  ],
  providers: [
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        locales: ['en', 'uk'],
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      deps: [Store, AmplitudeService],
      useFactory: (store: Store) => () => {
        store.dispatch(AuthActions.getUser());
        store.dispatch(CoreActions.getVocabularies());
      },
      multi: true
    },
    {
      provide: META_REDUCERS,
      useFactory: localStorageReducer,
      multi: true,
    },
    {
      provide: META_REDUCERS,
      deps: [CookieService, PLATFORM_ID],
      useFactory: cookieStorageReducer,
      multi: true,
    },
    {
      provide: META_REDUCERS,
      deps: [CookieService],
      useFactory: stateTransferReducer,
      multi: true,
    },
  ],
  bootstrap: [AppPageComponent]
})
export class AppModule {
  constructor(
    private readonly transferState: TransferState,
    private readonly store: Store,
  ) {
    const isBrowser = this.transferState.hasKey<any>(NGRX_STATE);

    if (isBrowser) {
      this.setRootState();
    } else {
      this.getRootState();
    }
  }

  private getRootState(): void {
    this.transferState.onSerialize(NGRX_STATE, () => {
      let state: any;

      this.store
        .subscribe((saveState: any) => {state = saveState})
        .unsubscribe();

      return state;
    });
  }

  private setRootState(): void {
    let state = this.transferState.get<any>(NGRX_STATE, null);
    this.transferState.remove(NGRX_STATE);
    this.store.dispatch({type: 'SET_ROOT_STATE', payload: state});
  }
}
