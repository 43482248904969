import { createFeatureSelector, createSelector } from '@ngrx/store';

import { coreFeatureKey, State } from '../reducers/core.reducer';
import { Utils } from '../../helpers/utils';

export const selectCoreState = createFeatureSelector<State>(
  coreFeatureKey
);

export const selectLanguage = createSelector(selectCoreState, (state: any) => state.language);
export const selectTheme = createSelector(selectCoreState, (state: any) => state.theme);

export const selectMainNavOpened = createSelector(selectCoreState, (state: any) => state.mainNavOpened);

export const selectVocabularies = createSelector(selectCoreState, (state: any) => state.vocabularies);
export const selectVocabulary = (machineName: string) => createSelector(selectVocabularies, (vocabularies: any) => {
  return vocabularies && vocabularies.find((v: any) => v.machineName === machineName);
});
export const selectPatchingEntities = createSelector(selectCoreState, (state: any) =>
  Utils.objectKeysToArray(state.patchingEntities),
);
export const selectDeletingEntities = createSelector(selectCoreState, (state: any) =>
  Utils.objectKeysToArray(state.deletingEntities),
);
