import { createReducer, on } from '@ngrx/store';

import { AuthActions } from '../actions/auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  user?: any;
  userType?: string;
  authorized?: boolean;
}

const initialState: State = {
  user: undefined,
  userType: undefined,
  authorized: false,
};

export const authReducer = createReducer(
  initialState,

  on(AuthActions.setUserType, (state, {userType}) => ({...state, userType})),

  on(
    AuthActions.signInSuccess,
    AuthActions.confirmEmailSuccess,
    AuthActions.confirmEmailPasswordSuccess,
    AuthActions.resetPasswordSuccess,
    AuthActions.socialAuthCallbackSuccess,
    (state, {user}) => ({...state, user, authorized: true})
  ),

  on(
    AuthActions.getUserSuccess,
    AuthActions.updateUserSuccess,
    (state, {user}) => ({
      ...state,
      userType: user.type,
      user,
    })
  ),

  on(AuthActions.updateTalentUser, (state, {profile}) => ({
    ...state,
    user: {
      ...state.user,
      ...profile.user,
      talent: {
        ...state.user.talent,
        slug: profile.slug,
        profileCompletedAt: profile.profileCompletedAt,
      }
    }
  })),

  on(AuthActions.updateCompanyUser, (state, {profile}) => ({
    ...state,
    user: {
      ...state.user,
      company: {
        ...state.user.company,
        slug: profile.slug,
      }
    }
  })),

  on(AuthActions.registerTalentSuccess, (state) => ({
    ...state,
    user: {
      ...state.user,
      registrationCompletedAt: true,
    }
  })),

  on(AuthActions.registerCompanySuccess, (state, {company}) => ({
      ...state,
      user: {
        ...state.user,
        registrationCompletedAt: true,
        type: 'company',
        company: {
          slug: company.slug,
          id: company.id
        }
      }
    })
  ),

  on(AuthActions.registerMemberSuccess, (state, {user}) => ({
      ...state,
      user: {
        ...state.user,
        registrationCompletedAt: true,
        type: 'company',
        company: {
          slug: user.company.slug,
          id: user.company.id
        }
      }
    })
  ),

  on(
    AuthActions.logOutSuccess,
    AuthActions.logOutFailure,
    (state) => ({...state, user: undefined, authorized: false})
  ),

  on(AuthActions.mailUnsubscribeSuccess, (state) => {
    return state?.user
      ? ({
        ...state,
        user: {
          ...state.user,
          emailSubscription: false,
        }
      })
      : state;
  })
);
