import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import * as format from 'string-template';

import { Breadcrumb } from '../types/routing-with-breadcrumbs.type';
import { Utils } from '../../../../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  private _defaultBreadcrumbs: Breadcrumb[] = [];

  public get defaultBreadcrumbs() {
    return this._defaultBreadcrumbs;
  }

  private _breadcrumbs$ = new BehaviorSubject(
    this.buildBreadcrumbs(this.router.routerState.root, Utils.getRouteParams(this.router.routerState.root))
  );

  public get breadcrumbs$() {
    return this._breadcrumbs$.asObservable()
      .pipe(
        map((breadcrumbs: Breadcrumb[]) =>
          breadcrumbs.map(breadcrumb => this.applyResolvers(breadcrumb))
        ),
        switchMap(breadcrumbs$ => combineLatest(breadcrumbs$)),
      ) as Observable<Breadcrumb[]>;
  }

  constructor(
    private router: Router,
    private injector: Injector
  ) {
  }

  public build() {
    const breadcrumbs = this.buildBreadcrumbs(this.router.routerState.root, Utils.getRouteParams(this.router.routerState.root));

    this._breadcrumbs$.next(
      this.filterBreadcrumbsDuplicates(breadcrumbs)
    );
  }

  public setDefaultBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    this._defaultBreadcrumbs = breadcrumbs;
    this.build();
  }

  protected buildBreadcrumbs(route: ActivatedRoute, params: Params, addDefaultBreadcrumbs = true) {
    let routeBreadcrumbs: Breadcrumb[] = [];
    if (route.snapshot.data?.hasOwnProperty('breadcrumbs')) {
      routeBreadcrumbs = route.snapshot.data?.['breadcrumbs'];
    }

    let childrenRouteBreadcrumbs: Breadcrumb[] = [];
    if (route?.firstChild) {
      childrenRouteBreadcrumbs = this.buildBreadcrumbs(route?.firstChild, params, false);
    }

    return this.applyUrlParams(
      [
        ...(addDefaultBreadcrumbs ? this.defaultBreadcrumbs : []),
        ...routeBreadcrumbs,
        ...childrenRouteBreadcrumbs
      ],
      params
    );
  }

  protected filterBreadcrumbsDuplicates(breadcrumbs: Breadcrumb[]) {
    return breadcrumbs.reduce<Breadcrumb[]>((acc, breadcrumb) => {
      const isBreadcrumbAlreadyAdded = acc.find(item => item.title === breadcrumb.title && item.url === breadcrumb.url);

      return isBreadcrumbAlreadyAdded ? acc : [...acc, breadcrumb];
    }, []);
  }

  protected applyUrlParams(breadcrumbs: Breadcrumb[], params: Params) {
    return breadcrumbs.map(breadcrumb => {
      if (typeof breadcrumb.url === 'string') {
        return {
          ...breadcrumb,
          url: format(breadcrumb.url, params)
        };
      }

      return breadcrumb;
    });
  }

  protected applyResolvers(breadcrumb: Breadcrumb) {
    const resolvers = breadcrumb?.resolvers?.map(resolver => this.injector.get(resolver)) || [];

    return resolvers.reduce(
      (acc, resolver) => acc.pipe(
        switchMap((breadcrumb: Breadcrumb) => resolver.resolve(of(breadcrumb.title))
          .pipe(map(title => ({ ...breadcrumb, title })))
        )
      ),
      of(breadcrumb)
    );
  }
}
