import { Component } from '@angular/core';

import { ModalComponent } from '../../modules/modal/components/modal.component';

@Component({
  selector: 'app-modal-need-register',
  templateUrl: './modal-need-register.component.html',
  styleUrls: ['./modal-need-register.component.scss']
})
export class ModalNeedRegisterComponent extends ModalComponent<any, any> {
  public message: string = 'modal.need_register';

  constructor() {
    super()
  }
}
