import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { TalentProfileCanActivateGuard } from './guards/talent-profile-can-activate.guard';
import { TalentPageComponent } from './containers/talent-page/talent-page.component';
import { TalentCanActivateGuard } from './guards/talent-can-activate.guard';
import { environment } from '../../../environments/environment';
import { AppRoutes } from '../../models/app-routes.type';
import { AuthGuard } from '../auth/guards/auth.guard';

const routes: AppRoutes = [
  {
    path: ':talentSlug',
    component: TalentPageComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [TalentCanActivateGuard],
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/profile-form/profile-form.module').then(m => m.ProfileFormModule),
        canActivate: [TalentProfileCanActivateGuard],
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [TalentProfileCanActivateGuard],
      },
      {
        path: 'feedbacks',
        loadChildren: () => import('./modules/feedbacks/feedbacks.module').then(m => m.FeedbacksModule),
      },
      {
        path: 'settings',
        title: `${environment.baseTitle} | talent.navigation.settings`,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
        data: {
          theme: 'dark',
        },
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/auth-redirect'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TalentRoutingModule {
}
