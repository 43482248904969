import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { DynamicFormArray, DynamicFormGroup } from '../../../models/dynamic-form-control.type';
import { DynamicFormBuilderService } from '../../services/dynamic-form-builder.service';
import { ModalComponent } from '../../modules/modal/components/modal.component';

type ModalFilterComponentInput = {
  theme: string;
  formStructure: DynamicFormGroup;
  vocabularies: any;
  defaultValues: any;
}

@Component({
  selector: 'app-modal-filter',
  templateUrl: './modal-filter.component.html',
  styleUrls: ['./modal-filter.component.scss']
})
export class ModalFilterComponent extends ModalComponent<ModalFilterComponentInput, any> implements OnInit {
  public theme: any;
  public formStructure!: DynamicFormGroup;
  public aggregations: any;
  public foundItemCount: any;
  public vocabularies: any;
  protected defaultValues!: any;
  protected data$!: Observable<any>;
  public form = this.fb.group({});

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private dynamicFormBuilderService: DynamicFormBuilderService,
  ) {
    super();
  }

  ngOnInit() {
    this.dynamicFormBuilderService.buildForm(this.formStructure, this.form);
    this.dynamicFormBuilderService.patchForm(this.form, this.defaultValues, this.formStructure);

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(() => this.form.valid)
      )
      .subscribe(data => this.observer.next(data));

    this.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.aggregations = data.aggregations;
        this.foundItemCount = data.foundItemCount;
      });
  }

  override close(): Promise<any> {
    return this.closerCallback(this).then(cb => {
      if (this.observer) {
        // We don't need to push the next data here because we do it each time on the valueChanges subscription of form.
        this.observer.complete();
      }
      return cb;
    });
  }

  public getFormControl(key: any): any {
    return this.form.get(key);
  }

  public addFormGroup(fieldSettings: DynamicFormArray): void {
    this.getFormControl(fieldSettings.name).push(this.dynamicFormBuilderService.buildForm(fieldSettings));
  }

  public removeFormGroup(key: string, index: number): void {
    this.getFormControl(key).removeAt(index);
  }

  public submit(): void {
    if (this.form.valid) {
      this.close();
    }
  }
}
