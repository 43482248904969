import { Component, OnInit, Input, Inject, PLATFORM_ID, ElementRef } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { shakeOnEnterAnimation } from 'angular-animations';
import { isPlatformBrowser } from '@angular/common';
import { NgControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-error-message',
  template: `
    <span
      *ngIf="showError"
      role="alert"
      @shakeOnEnter
    >
      {{ngControl.errors | appErrorMessage: patternKey}}
    </span>
  `,
  animations: [
    shakeOnEnterAnimation(),
  ],
})
export class ErrorMessageComponent implements OnInit {
  @Input() ngControl!: NgControl;
  @Input() patternKey?: string;
  @Input() closestParent?: string;
  @Input() showMessage!: boolean;
  @Input() container!: ElementRef;

  public showError = false;

  private readonly isBrowser = isPlatformBrowser(this.platformId);

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
  }

  ngOnInit(): void {
    this.ngControl.statusChanges
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.isNotValid());
  }

  isNotValid(): void {
    const control = this.ngControl.control;
    const invalid = (control && control.invalid && control.touched) || false;
    this.toggleErrorContainer(invalid);

    this.showError = this.showMessage && invalid;
  }

  private toggleErrorContainer(invalid: boolean): void {
    const errorClass = 'error-label';
    let element;

    if (this.isBrowser) {
      element = this.container.nativeElement?.closest(`.${this.closestParent}`);

      invalid
        ? element?.classList.add(errorClass)
        : element?.classList.remove(errorClass);
    }
  }
}
