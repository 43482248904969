import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationNotCompletedGuard implements CanActivate {
  constructor(
    private guardHelperService: GuardHelperService,
    private router: Router
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardHelperService.getUser().pipe(
      switchMap((user) => {
        if (user && !user.registrationCompletedAt) {
          this.router.navigate([`/registration`]);
          return of(false);
        }

        return of(true);
      }),
    );
  }
}
