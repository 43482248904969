<div class="registration-info bg-white dark:bg-black" *ngIf="isHidden" @fadeOutOnLeave>
  <h4
    class="text-center font-bold text-h4 text-current"
    [@fadeInDownOnEnter]="{ value: '', params: { delay: 500} }"
  >
    <ng-container *ngIf="type === 'company'">
      {{ 'page.register.modal.company.title' | translate }}
    </ng-container>

    <ng-container *ngIf="type === 'talent'">
      {{ 'page.register.modal.talent.title' | translate }}
    </ng-container>
  </h4>

  <swiper
    [config]="swiperItems"
    class="w-full overflow-visible"
  >
    <ng-template swiperSlide let-data *ngFor="let item of items; let idx = index">
      <div
        class="registration-info-card text-black"
        [@fadeInOnEnter]="{ value: '', params: { delay: 150 * idx} }"
      >
        <svg-icon [src]="item.icon"></svg-icon>
        <div class="registration-info-card__text">
          {{ item.text | translate }}
        </div>
      </div>
    </ng-template>
  </swiper>

  <button
    class="big-btn big-btn--min-width bg-black text-white dark:bg-white dark:text-black place-self-center"
    [@fadeInUpOnEnter]="{ value: '', params: { duration: 300, delay: 600} }"
    (click)="isHidden = !isHidden"
  >
    {{ 'btn.sign_up' | translate }}
  </button>

  <button
    [routerLink]="type === 'company' ? '/registration/talent' : '/registration/company'"
    class="underline place-self-center"
    [@fadeInUpOnEnter]="{ value: '', params: { duration: 300, delay: 700} }"
  >
    <ng-container *ngIf="type === 'company'">
      {{ 'page.register.im_talent' | translate }}
    </ng-container>

    <ng-container *ngIf="type === 'talent'">
      {{ 'page.register.im_company' | translate }}
    </ng-container>
  </button>
</div>
