import { InjectionToken } from '@angular/core';

export const DefaultModalOptionConfig = new InjectionToken<ModalOptions>('default-modal.config');

export interface ModalOptions {
  closeOnEscape: boolean;
  closeOnClickOutside: boolean;
  bodyClass: string;
  wrapperDefaultClasses: string;
  wrapperClass: string;
  draggableClass: string;
  animationDuration: number;
  autoFocus: boolean;
  draggable: boolean;
  autoClose: boolean | number;
}

export type ModalOptionsOverrides = Partial<ModalOptions>;

export const defaultModalOptions: ModalOptions = {
  closeOnEscape: false,
  closeOnClickOutside: false,
  bodyClass: 'modal-open',
  wrapperDefaultClasses: 'modal fade-anim',
  wrapperClass: 'in',
  draggableClass: 'draggable',
  animationDuration: 300,
  autoFocus: false,
  draggable: false,
  autoClose: false,
};
