<ul role="list" class="pagination select-none">
  <li
    (click)="changeCurrentPage(currentPage - 1)"
    class="pagination__btn pagination__prev"
    [class.disabled]="currentPage === 1"
  >
    <svg-icon src="arrow.svg"></svg-icon>
  </li>

  <ul class="pagination__items">
    <li
      *ngFor="let item of items"
      (click)="changeCurrentPage(item)"
      [ngClass]="{ 'active': item === currentPage }"
      class="pagination__item"
    >
      {{ item }}
    </li>
  </ul>

  <li
    (click)="changeCurrentPage(currentPage + 1)"
    class="pagination__btn pagination__next"
    [class.disabled]="currentPage === pageCount"
  >
    <svg-icon src="arrow.svg"></svg-icon>
  </li>
</ul>
