import { createFeatureSelector, createSelector, State } from '@ngrx/store';

import { errorsFeatureKey } from '../reducers/error.reducer';

export const selectErrorState = createFeatureSelector<State<any>>(errorsFeatureKey);

export const selectLatestError = createSelector(selectErrorState, (state: any) => {
  const errors = state && Object.entries(state)
    .filter(([, error]: any) => !!error && !error?.silent)
    .map(([action, error]: any) => ({...error, action}));

  return errors?.length ? errors[0] : null;
});
