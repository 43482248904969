import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';

import { ModalComponent } from '../../modules/modal/components/modal.component';
import { sameStringValidator } from '../../validators/same-string.validator';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent extends ModalComponent<any, any> implements OnInit {
  public label: string = 'modal.confirm.title';
  public message?: string;
  public cancelLabel: string = 'btn.no';
  public confirmLabel: string = 'btn.yes';
  public confirmationCheck!: string;

  public form!: FormGroup;

  override optionsOverride: any = {
    autoClose: false,
    closeOnEscape: false,
    closeOnClickOutside: false,
  };

  constructor(
    private translateService: TranslateService,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.confirmationCheck && this.confirmationCheck.length > 0) {
      this.form = this.fb.group({
        string: [
          null,
          [
            Validators.required,
            sameStringValidator(this.translateService.instant(this.confirmationCheck))
          ]
        ],
      });
    }
  }
}
