import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { CookieService } from 'ngx-cookie';
import { Store } from '@ngrx/store';
import { Request } from 'express';

import { AuthService } from '../../modules/auth/services/auth.service';
import { AuthActions } from '../../modules/auth/actions/auth.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private isCookieWasSent = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    @Optional() @Inject(REQUEST) private request: Request,
    @Inject(PLATFORM_ID) private platformId: Platform,
    private cookieService: CookieService,
    private authService: AuthService,
    private store: Store,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestOptions: any = {
      withCredentials: true,
    };

    if (this.request && this.request?.headers?.cookie && isPlatformServer(this.platformId)) {
      requestOptions.setHeaders = { Cookie: this.request.headers.cookie };
    }

    req = req.clone(requestOptions);

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 && !req.url.includes('/auth/refresh')) {
            return this.refreshToken(req, next);
          }

          if (req.url.includes('/auth/refresh')) {
            this.store.dispatch(AuthActions.logOutSuccess());
          }
        }

        return throwError(error);
      })
    );
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const observe = isPlatformServer(this.platformId) ? 'response' : 'body';

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken(observe).pipe(
        switchMap(({headers}) => {
          this.isRefreshing = false;

          if (isPlatformServer(this.platformId)) {
            const xAccessToken = headers.get('x-access-token');
            const xRefreshToken = headers.get('x-refresh-token');
            const xAccessTokenParsed = JSON.parse(xAccessToken);
            const xRefreshTokenParsed = JSON.parse(xRefreshToken);

            if (!this.isCookieWasSent) {
              this.cookieService.put('accessToken', xAccessTokenParsed.token, xAccessTokenParsed.options);
              this.cookieService.put('refreshToken', xRefreshTokenParsed.token, xRefreshTokenParsed.options);
              this.isCookieWasSent = true;
            }

            request = request.clone({
              setHeaders: {
                Cookie: `accessToken=${xAccessTokenParsed.token}; refreshToken=${xRefreshTokenParsed.token}`,
              },
            });
          }

          this.refreshTokenSubject.next(true);
          return next.handle(request);
        }),
        catchError((error) => {
          if (isPlatformServer(this.platformId) && this.request) {
            this.request.res?.clearCookie('accessToken');
            this.request.res?.clearCookie('refreshToken');
          }

          this.isRefreshing = false;
          this.store.dispatch(AuthActions.logOutSuccess());
          return throwError(error);
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter(refresh => refresh !== null),
      take(1),
      switchMap(() => next.handle(request))
    );
  }
}
