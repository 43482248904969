import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { selectUser } from '../../modules/auth/selectors/auth.selectors';

@Directive({
  selector: '[appHasRoles]'
})
export class HasRolesDirective implements OnInit {
  public user$ = this.store.pipe(select(selectUser));

  private currentUserRoles!: any[];
  private userRoles!: any[];
  private isHidden = true;

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private element: ElementRef,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.getUser();
  }

  @Input() set appHasRoles(val: any) {
    this.userRoles = val && val.length ? val : [];
  }

  private getUser(): void {
    this.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        if (user) {
          this.currentUserRoles = user.roles.map((role: any) => role.name);

          this.updateView();
        }
      });
  }

  private updateView(): void {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    let hasPermission = false;

    if (this.currentUserRoles && this.currentUserRoles.length) {
      if (this.userRoles.length) {
        hasPermission = !!this.userRoles.filter(x => this.currentUserRoles.includes(x)).length;
      } else {
        hasPermission = true;
      }
    }

    return hasPermission;
  }
}
