import { filter, lastValueFrom, Observable, of, startWith, tap } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { map, switchMap } from 'rxjs/operators';
import { UcFirstPipe } from 'ngx-pipes';

import { environment } from '../../../../environments/environment';
import { Utils } from '../../../helpers/utils';
import { PageTitle } from './page-title.type';

@Component({
  selector: 'app-page-title',
  template: `
    <div class="text-sm">{{ $title | async }}</div>`,
  providers: [UcFirstPipe]
})
export class PageTitleComponent {
  public $title = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => {
      const titles = Utils.getRouteDataByKey<PageTitle>(this.activatedRoute, 'pageTitle');

      return titles[titles.length - 1];
    }),
    filter((pageTitle) => !!pageTitle),
    switchMap(pageTitle => this.applyResolvers(pageTitle)),
    map((pageTitle) => ({
      ...pageTitle,
      title: pageTitle.title
    })),
    tap( async ({title}) => {
      let pageTitle = title ? await lastValueFrom(this.translateService.get(title)) : '';

      this.title.setTitle(`${environment.baseTitle} | ${pageTitle}`);
    }),
    filter(({title}) => !!title),
    switchMap(({title}) => this.translateService.get(title)),
  );

  constructor(
    private injector: Injector,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private title: Title,
  ) {
  }

  private applyResolvers(pageTitle: PageTitle): Observable<PageTitle> {
    const resolvers = pageTitle?.resolvers?.map(resolver => this.injector.get(resolver)) || [];

    return resolvers.reduce(
      (acc, resolver) => acc.pipe(
        switchMap(({title}: PageTitle) => resolver.resolve(of(title))),
        map((title: string) => ({...pageTitle, title}) as PageTitle)
      ),
      of(pageTitle) as Observable<PageTitle>
    );
  }
}
