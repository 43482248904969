import { Component } from '@angular/core';

import { ModalComponent } from '../../modules/modal/components/modal.component';
import { ModalType } from '../../../config';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent extends ModalComponent<any, any> {
  public type!: ModalType;
  public title?: string;
  public message?: string | any;
  public modalType = ModalType;

  constructor() {
    super();
  }
}
