import { State } from '@ngrx/store';

export const errorsFeatureKey = 'errors';

const initialState: State<any> | any = {};

export function errorReducers(state = initialState, action: any): any {
  const {type, error, silent} = action;

  const matches = /(.*)\s(Request|Failure|Clear)/.exec(type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;

  let newState: any = {
    ...state,
  };

  if (requestState !== 'Clear') {
    newState = {
      ...newState,
      [requestName]: requestState === 'Failure' ? {...error, silent} : null
    };
  } else {
    if ((error && !error.length) || !error) {
      newState = {};
    } else {
      error.map((e: string) => {
        newState[e] = null;
      });
    }
  }

  return newState;
}
