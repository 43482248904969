import { trigger, transition, group, query, style, animate } from '@angular/animations';

export class RouterAnimations {
  static routeSlide =
    trigger('routeSlide', [
      transition('* <=> *', group([
        query(':enter, :leave', [
          style({
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            opacity: 1,
          })
        ], {optional: true}),
        query(':enter', [
          style({transform: 'scale(1.03)', opacity: 0}),
          animate('404ms ease-in-out', style({transform: 'scale(1)', opacity: 1}))
        ], {optional: true}),
        query(':leave', [
          style({transform: 'scale(1)', opacity: 1}),
          animate('404ms ease-in-out', style({transform: 'scale(0.95)', opacity: 0}))
        ], {optional: true}),
      ])),
    ]);
}
