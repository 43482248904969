import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';
import { selectUser } from '../../auth/selectors/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class JobEditCanActivateGuard implements CanActivate {
  constructor(
    private guardHelperService: GuardHelperService,
    private router: Router,
    private store: Store
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { jobSlug: slug } = route.params;

    return this.guardHelperService.getJob(slug).pipe(
      withLatestFrom(this.store.pipe(select(selectUser))),
      map(([job, user]) => {
        const {type} = user;

        if (user?.company?.id !== job?.company?.id) {
          this.router.navigate([`/${type}/${user[type].slug}/profile`])
          return false;
        }

        return true;
      })
    );
  }
}
