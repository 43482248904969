import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinct, filter, startWith, Subject, takeUntil } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { TranslateService } from '@ngx-translate/core';
import { map, withLatestFrom } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { enUS, uk } from 'date-fns/locale';

import { CustomModalComponent } from '../../../shared/components/custom-modal/custom-modal.component';
import { BreadcrumbsService } from '../../../shared/modules/breadcrumbs/services/breadcrumbs.service';
import { Breadcrumb } from '../../../shared/modules/breadcrumbs/types/routing-with-breadcrumbs.type';
import { selectLanguage, selectMainNavOpened } from '../../selectors/core.selectors';
import { ModalService } from '../../../shared/modules/modal/services/modal.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { AmplitudeService } from '../../../shared/services/amplitude.service';
import { selectUser } from '../../../modules/auth/selectors/auth.selectors';
import { selectLatestError } from '../../../selectors/error.selector';
import { environment } from '../../../../environments/environment';
import { CoreActions } from '../../actions/core.actions';
import { ModalType } from '../../../config';

@Component({
  selector: 'app-root',
  templateUrl: './app-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppPageComponent implements OnInit {
  public language$ = this.store.pipe(select(selectLanguage));
  public user$ = this.store.pipe(select(selectUser));
  public navigationSettings$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    withLatestFrom(this.user$),
    map(([event, user]) => this.navigationService.getNavigationConfig(this.activatedRoute, user)),
  );
  public mainNavOpened$ = this.store.pipe(select(selectMainNavOpened));
  public mainNav$ = this.navigationService.mainNavigation$;
  public secondaryNav$ = this.navigationService.secondaryNavigation$;

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();
  @AutoUnsubscribe()
  private flushErrors$ = new Subject();

  constructor(
    private dateConfig: DateFnsConfigurationService,
    private breadcrumbsService: BreadcrumbsService,
    private navigationService: NavigationService,
    private amplitudeService: AmplitudeService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private router: Router,
    private store: Store,
    private meta: Meta,
  ) {
  }

  ngOnInit(): void {
    this.globalErrorHandle();

    this.initLanguage();
    this.initTheme();

    if (!environment.production) {
      this.meta.addTag({name: 'robots', content: 'noindex, nofollow'});
    }

    this.store.pipe(select(selectUser))
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(user => user && user.type)
      )
      .subscribe(user => {
        const { slug } = user[user?.type];

        const homeBreadcrumb: Breadcrumb = user.type === 'talent'
          ? { title: 'talent.navigation.home', url: `/talent/${slug}/dashboard` }
          : { title: 'company.navigation.home', url: `/company/${slug}/dashboard` };

        this.breadcrumbsService.setDefaultBreadcrumbs([homeBreadcrumb])
        this.navigationService.buildMainNavigation(user?.type, slug);
        this.navigationService.buildSecondaryNavigation(user?.type, slug);
      });
  }

  public languageChange(language: string): void {
    this.store.dispatch(CoreActions.changeLanguage({language}));
  }

  public toggleMainNav(mainNavOpened: boolean): void {
    this.store.dispatch(CoreActions.toggleMainNav({mainNavOpened}));
  }

  private initTheme(): void {
    this.router.events.pipe(
      takeUntil(this.unsubscribe$),
      filter(event => event instanceof NavigationEnd),
      map(() => this.getThemeConfig()),
      withLatestFrom(this.store.pipe(select(selectUser))),
    ).subscribe(([theme, user]) => {
      if (!user?.type) {
        this.store.dispatch(CoreActions.setTheme({theme}));
      }
    });
  }

  private initLanguage(): void {
    this.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(language => {
        switch (language) {
          case 'en':
            this.dateConfig.setLocale(enUS);
            break;
          case 'uk':
            this.dateConfig.setLocale(uk);
            break;
          default:
            this.dateConfig.setLocale(enUS);
            break;
        }

        this.store.dispatch(CoreActions.changeLanguage({language}));
      });
  }

  private getThemeConfig(): any {
    let child = this.activatedRoute.firstChild;

    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data?.hasOwnProperty('theme')) {
        return child.snapshot.data?.['theme'];
      } else {
        return 'light';
      }
    }
  }

  private globalErrorHandle(): void {
    this.store.pipe(select(selectLatestError))
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(error => !!error),
        distinct(({action}) => action, this.flushErrors$)
      )
      .subscribe(({statusCode, message, silent, action}) => {
        if (statusCode !== 401 && statusCode !== 403) {
          let errorMessage;

          switch (statusCode) {
            case 422:
              errorMessage = 'error.badEntity';
              break;

            case 500:
              errorMessage = 'error.internalServerError';
              break;

            default:
              errorMessage = message;
              break;
          }

          this.amplitudeService.track('server_error', {
            error_message: errorMessage,
            error_code: statusCode,
            error_action: action,
          });

          if (errorMessage && !silent) {
            this.modalService.addModal(
              CustomModalComponent,
              {
                type: ModalType.WARNING,
                message: this.translateService.instant(errorMessage)
              },
              {autoClose: 5000}
            )
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(() => {
                if (action) {
                  this.store.dispatch(CoreActions.clearError({ error: [action] }));
                  this.flushErrors$.next(true);
                }
              });
          }
        } else {
          this.store.dispatch(CoreActions.clearError({ error: [action] }));
          this.flushErrors$.next(true);
        }
      });
  }
}
