import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';

@Injectable({
  providedIn: 'root'
})
export class LoadUserResolver implements Resolve<boolean> {
  constructor(
    private guardHelperService: GuardHelperService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.guardHelperService.getUser();
  }
}
