import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(
    protected sanitizer: DomSanitizer
  ) {
  }

  public transform(value: any): SafeHtml {;
    return value ? this.sanitizer.bypassSecurityTrustHtml(value) : '';
  }
}
