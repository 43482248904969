import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { Directive, ElementRef, Inject, Input, PLATFORM_ID, } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { of, Subject, takeUntil } from "rxjs";
import { delay } from "rxjs/operators";

@Directive({
  selector: '[appLineClamp]',
})
export class LineClampDirective {
  private player!: AnimationPlayer;
  private isShow!: boolean;
  private lineCount!: number;

  private isBrowser = isPlatformBrowser(this.platformId);

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  @Input()
  set line(count: number) {
    this.lineCount = count;
  }

  @Input()
  set show(show: boolean) {
    if (this.isBrowser) {
      const lineHeight = (this.document.defaultView as Window)
        .getComputedStyle(this.elRef.nativeElement).lineHeight.split('px')
      const minHeightBlock = +lineHeight[0] * this.lineCount;

      this.elRef.nativeElement.style.webkitLineClamp = 'inherit';
      this.isShow = show;
      this.createPlayer(minHeightBlock);
      this.player.play();
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    private animationBuilder: AnimationBuilder,
    private elRef: ElementRef
  ) {
  }

  private createPlayer(minHeight: number) {
    if (this.player) {
      this.player.destroy();
    }

    let animationFactory;

    if (this.isShow) {
      this.elRef.nativeElement.style.webkitLineClamp = 'inherit';

      animationFactory = this.animationBuilder.build([
        style({height: `${minHeight}px`}),
        animate(500, style({height: '*'})),
      ]);
    } else {
      animationFactory = this.animationBuilder.build([
        style({height: '*'}),
        animate(500, style({height: `${minHeight}px`})),
      ]);

      of(false)
        .pipe(
          takeUntil(this.unsubscribe$),
          delay(404)
        )
        .subscribe(() => this.elRef.nativeElement.style.webkitLineClamp = this.lineCount)
    }

    this.player = animationFactory.create(this.elRef.nativeElement);
  }
}
