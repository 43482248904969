import {
  Component, ElementRef, HostBinding, HostListener, Input, OnChanges, SimpleChanges
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-smooth-height',
  template: `
    <ng-content></ng-content>
  `,
  animations: [
    trigger('grow', [
      transition('void <=> *', []),
      transition('* => *', [
          style({height: '{{startHeight}}px'}),
          animate('.4s ease-in-out'),
        ],
        {params: {startHeight: '*'}}
      ),
    ]),
  ]
})
export class SmoothHeightComponent implements OnChanges {
  @Input() trigger: any;

  @HostBinding('@grow') grow: any;

  @HostListener('@grow.done') growDone() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  public startHeight!: number;

  constructor(
    private element: ElementRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // Maybe wouldn't work for content trigger without length.
    // Works with string and arrays.

    if (changes['trigger'].currentValue && changes['trigger'].currentValue.length > 1) {
      this.startHeight = this.element.nativeElement.clientHeight;
    }

    this.grow = {
      value: this.trigger,
      params: {startHeight: this.startHeight}
    };
  }
}
