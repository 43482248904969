import {
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true
    }
  ]
})
export class CheckboxGroupComponent implements OnInit, ControlValueAccessor {
  private _items: any[] = [];

  get items() {
    return this._items;
  }

  @Input()
  set items(items: any[]) {
    if (items) {
      this._items = items;
      this.buildForm();
    }
  }

  @Input() bindLabel: string = 'title';
  @Input() bindValue: string = 'id';
  @Input() multiple: boolean = true;
  @Input() counterField?: string;
  @Input() emptyText?: string;

  public form = this.formBuilder.group({});

  private value: any;
  private disabled!: boolean;

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject<void>();

  private onTouched = () => {
  };
  private onChange = (m: any) => {
  };

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        map(val => Object.entries(val).filter(([, value]) => !!value).map(([key]) => key))
      )
      .subscribe(value => this.onChange(value));
  }

  writeValue(value: any[]): void {
    this.value = value;
    this.value && this.patchForm(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    isDisabled ? this.form.disable() : this.form.enable();
  }

  private patchForm(value: any[] = []): void {
    const formValue = value.reduce((prev, curr) => {
      prev[curr] = true;
      return prev;
    }, {});

    this.form.patchValue(formValue, {emitEvent: false, onlySelf: true});
  }

  private buildForm(): void {
    for (const item of this.items) {
      this.form.addControl(
        item[this.bindValue],
        this.formBuilder.control(null),
        {emitEvent: false}
      );

      if (item.hasOwnProperty('disabled') && item.disabled) {
        this.form.get(item[this.bindValue])?.disable({emitEvent: false});
      } else {
        this.form.get(item[this.bindValue])?.enable({emitEvent: false});
      }

      if (!this.multiple) {
        this.form.get(item[this.bindValue])?.valueChanges
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => this.clearGroup([item[this.bindValue]]));
      }
    }

    if (this.disabled !== undefined) {
      if (this.disabled && !this.form.disabled) {
        this.form.disable({emitEvent: false});
      } else if (!this.disabled && this.form.disabled) {
        this.form.enable({emitEvent: false});
      }
    }
  }

  private clearGroup(except: any[] = []): void {
    for (const key in this.form.controls) {
      if (!except.includes(key)) {
        this.form.get(key)?.setValue(false, {emitEvent: false});
      }
    }
  }
}
