import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { CompanyProfileCanActivateGuard } from './guards/company-profile-can-activate.guard';
import { CompanyPageComponent } from './containers/company-page/company-page.component';
import { CompanyCanActivateGuard } from './guards/company-can-activate.guard';
import { IsCompanyVerifiedGuard } from './guards/is-company-verified.guard';
import { CompanyTokenResolver } from './services/company.token-resolver';
import { environment } from '../../../environments/environment';
import { AppRoutes } from '../../models/app-routes.type';
import { AuthGuard } from '../auth/guards/auth.guard';

const routes: AppRoutes = [
  {
    path: ':companySlug',
    component: CompanyPageComponent,
    canActivate: [CompanyCanActivateGuard],
    data: {
      breadcrumbs: [
        {
          title: `{${CompanyTokenResolver.COMPANY_NAME}}`,
          url: '/company/{companySlug}',
          resolvers: [CompanyTokenResolver]
        }
      ]
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
        title: `${environment.baseTitle} | company.navigation.profile`,
        canActivate: [CompanyCanActivateGuard],
        data: {
          theme: 'dark'
        }
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/profile-form/profile-form.module').then(m => m.ProfileFormModule),
        title: `${environment.baseTitle} | company.navigation.profile_form`,
        canActivate: [
          AuthGuard,
          CompanyProfileCanActivateGuard,
        ],
        data: {
          theme: 'dark',
        }
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        title: `${environment.baseTitle} | company.navigation.dashboard`,
        canActivate: [
          AuthGuard,
          CompanyProfileCanActivateGuard,
        ],
        data: {
          theme: 'dark',
        }
      },
      {
        path: 'post-job',
        loadChildren: () => import('./../../modules/jobs/modules/job-form/job-form.module').then(m => m.JobFormModule),
        title: `${environment.baseTitle} | company.navigation.job_new`,
        canActivate: [
          AuthGuard,
          CompanyProfileCanActivateGuard,
          IsCompanyVerifiedGuard
        ],
        data: {
          theme: 'dark',
          breadcrumbs: [
            {
              title: 'company.navigation.job_list',
              url: '/company/{companySlug}/jobs'
            },
            {
              title: 'company.navigation.job_new'
            }
          ],
          pageTitle: { title: 'company.navigation.job_new' }
        }
      },
      {
        path: 'jobs',
        loadChildren: () => import('./../../modules/jobs/jobs.module').then(m => m.JobsModule),
        data: {
          theme: 'dark'
        }
      },
      {
        path: 'settings',
        title: `${environment.baseTitle} | company.navigation.settings`,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
        data: {
          theme: 'dark',
        },
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/auth-redirect'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule {
}
