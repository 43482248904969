import { HttpClient } from '@angular/common/http';
import { serialize } from 'object-to-formdata';
import { Injectable } from '@angular/core';
import { delay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TalentService {
  constructor(
    private http: HttpClient
  ) {
  }

  public getTalent(slug: string): Observable<any> {
    return this.http.get(`${environment.api}/talent/${slug}`);
  }

  public patchPersonalInfo(id: string, body: any): Observable<any> {
    const serializeOptions = {indices: true, dotsForObjectNotation: false};

    return this.http.patch(`${environment.api}/talent/${id}/personal-info`, serialize(body, serializeOptions));
  }

  public patchAdditionalInfo(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/talent/${id}/additional-info`, body);
  }

  public patchExperience(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/talent/${id}/experience`, body);
  }

  public patchSkills(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/talent/${id}/skills`, body);
  }

  public patchLanguages(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/talent/${id}/languages`, body);
  }


  // Work Experience.
  public createWorkExp(id: string, body: any): Observable<any> {
    return this.http.post(`${environment.api}/talent/${id}/work-experience`, body).pipe(
      delay(1000) // for better UI animation.
    );
  }

  public patchWorkExp(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/talent/work-experience/${id}`, body);
  }

  public deleteWorkExp(id: string): Observable<any> {
    return this.http.delete(`${environment.api}/talent/work-experience/${id}`);
  }


  // Education.
  public createEducation(id: string, body: any): Observable<any> {
    return this.http.post(`${environment.api}/talent/${id}/education`, body).pipe(
      delay(1000) // for better UI animation.
    );
  }

  public patchEducation(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/talent/education/${id}`, body);
  }

  public deleteEducation(id: string): Observable<any> {
    return this.http.delete(`${environment.api}/talent/education/${id}`);
  }


  // Courses.
  public createCourse(id: string, body: any): Observable<any> {
    const serializeOptions = {indices: true, dotsForObjectNotation: false};

    return this.http.post(`${environment.api}/talent/${id}/course`, serialize(body, serializeOptions)).pipe(
      delay(1000) // for better UI animation.
    );
  }

  public patchCourse(id: string, body: any): Observable<any> {
    const serializeOptions = {indices: true, dotsForObjectNotation: false};

    return this.http.patch(`${environment.api}/talent/course/${id}`, serialize(body, serializeOptions));
  }

  public deleteCourse(id: string): Observable<any> {
    return this.http.delete(`${environment.api}/talent/course/${id}`);
  }

  public getTalentFeedbacks(id: string): Observable<any>  {
    return this.http.get(`${environment.api}/talent/${id}/job-feedbacks`);
  }

  public getTalentRecommendedJobs(id: string): Observable<any>  {
    return this.http.get(`${environment.api}/talent/${id}/recommended-jobs`);
  }

  public checkTalentSlug(slug: string): Observable<any> {
    return this.http.get(`${environment.api}/talent/is-slug-available/${slug}`);
  }

  public updateTalentSettings(body: any): Observable<any> {
    return this.http.patch(`${environment.api}/talent/update-settings`, body);
  }
}
