import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeOutOnLeaveAnimation } from 'angular-animations';
import { UntypedFormBuilder } from '@angular/forms';

import { AmplitudeService } from '../../../shared/services/amplitude.service';
import { environment } from '../../../../environments/environment';
import { INavigationInterface } from '../../../models';
import { languages } from '../../../config';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fadeOutOnLeaveAnimation()
  ]
})
export class AppComponent implements OnInit {
  @Input() navigationSettings!: any;
  @Input() mainNavOpened!: boolean;
  @Input() language!: string;
  @Input() mainNav: INavigationInterface[] | null = [];
  @Input() secondaryNav: INavigationInterface[] | null = [];
  @Input() user!: any;
  @Output() languageChange = new EventEmitter();
  @Output() toggleMainNav = new EventEmitter();

  public languagesList = languages;
  public languageSwitcher = this.formBuilder.control(null);
  public landingURL = environment.landingURL;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private amplitudeService: AmplitudeService,
  ) {
  }

  ngOnInit(): void {
    this.languageSwitcher.patchValue(this.language);
  }

  public postJob(): void {
    this.amplitudeService.track('post_job_click', {button_position: 'header'});
  }
}
