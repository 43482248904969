import { FormBuilder, Validators } from '@angular/forms';
import { Component } from '@angular/core';

import { PasswordNotMatchValidator } from '../../validators/password-not-match.validator';
import { PasswordMatchValidator } from '../../validators/password-match.validator';
import { ModalComponent } from '../../modules/modal/components/modal.component';

@Component({
  selector: 'app-modal-change-password',
  templateUrl: './modal-change-password.component.html'
})
export class ModalChangePasswordComponent extends ModalComponent<any, any> {
  public showPassword = true;

  public minLength = 8;
  public form = this.formBuilder.group({
    currentPassword: ['', [Validators.required, Validators.minLength(this.minLength)]],
    newPassword: ['', [Validators.required, Validators.minLength(this.minLength)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(this.minLength)]],
  }, {
    validators: [
      PasswordNotMatchValidator('newPassword', 'confirmPassword'),
      PasswordMatchValidator('currentPassword', 'newPassword')
    ],
  });

  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
  }

  public togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  public submit(): void {
    if (this.form.valid) {
      this.result = {
        password: this.form.value.currentPassword,
        newPassword: this.form.value.newPassword,
      };
      this.close();
    }
  }
}
