import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { RouteWithAnimationComponent } from '../../../shared/components/abstract/route-with-animation.component';
import { selectUser } from '../../../modules/auth/selectors/auth.selectors';

@Component({
  selector: 'app-animated-page',
  template: `
    <div class="relative flex h-full w-full" [@routeSlide]="routeTrigger$ | async">
      <router-outlet (activate)="onActivate()"></router-outlet>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAnimatedPageComponent extends RouteWithAnimationComponent {
  public user$ = this.store.pipe(select(selectUser));

  constructor(
    private store: Store,
  ) {
    super();
  }
}
