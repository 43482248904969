import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnDestroy,
  ReflectiveInjector,
  Type,
  ViewChild,
  ViewContainerRef, ComponentRef,
} from '@angular/core';
import { ModalComponent } from './modal.component';

@Component({
  selector: 'app-modal-wrapper',
  template: `
    <div #wrapper [ngClass]="modalClasses" [ngStyle]="{ display: 'block' }" role="dialog">
      <ng-template #viewContainer></ng-template>
    </div>
  `,
})
export class ModalWrapperComponent implements OnDestroy {
  @ViewChild('viewContainer', {read: ViewContainerRef, static: true}) viewContainer: any;
  @ViewChild('wrapper', {read: ElementRef, static: true}) wrapper!: ElementRef;

  public modalClasses = 'modal fade-anim';
  public content!: ModalComponent<any, any>;

  public clickOutsideCallback!: (event: MouseEvent) => void;

  constructor(
    private resolver: ComponentFactoryResolver
  ) {
  }

  addComponent<T, T1>(component: Type<ModalComponent<T, T1>>): { ref: ComponentRef<ModalComponent<T, T1>>, component: ModalComponent<T, T1> } {
    const factory = this.resolver.resolveComponentFactory(component);
    const injector = ReflectiveInjector.fromResolvedProviders([], this.viewContainer.injector);
    const componentRef = factory.create(injector);
    this.viewContainer.insert(componentRef.hostView);
    this.content = <ModalComponent<T, T1>>componentRef.instance;
    this.content.wrapper = this.wrapper;
    return {ref: componentRef, component: this.content};
  }

  onClickOutsideModalContent(callback: () => void) {
    const containerEl = this.wrapper.nativeElement;

    this.clickOutsideCallback = (event: MouseEvent) => {
      if (event.target === containerEl) {
        callback();
      }
    };

    containerEl.addEventListener('click', this.clickOutsideCallback, false);
  }

  ngOnDestroy() {
    if (this.clickOutsideCallback) {
      const containerEl = this.wrapper.nativeElement;
      containerEl.removeEventListener('click', this.clickOutsideCallback, false);
      this.clickOutsideCallback = (null as any);
    }
  }
}
