import { Action, State } from '@ngrx/store';

export const loadingFeatureKey = 'loading';

const initialState: State<any> | any = {};

export function loaderReducers(state = initialState, action: Action): any {
  const {type}: any = action;

  const matches = /(.*)\s(Request|Success|Failure)/.exec(type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === 'Request',
  };
}
