<quill-editor
  placeholder=""
  [formControl]="editorFC"
  (onBlur)="changedStatus()"
  [maxLength]="maxlength"
  [readOnly]="disabled"
  (onContentChanged)="counter($event)"
  class="{{height}} w-full {{classList}}"
></quill-editor>

<span *ngIf="showCounter" class="textarea-counter">
  {{count}}/{{maxlength}}
</span>
