import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { fadeOutOnLeaveAnimation } from 'angular-animations';
import { asyncScheduler } from 'rxjs';

@Component({
  selector: 'app-loader-progress',
  templateUrl: './loader-progress.component.html',
  styleUrls: ['./loader-progress.component.scss'],
  animations: [
    fadeOutOnLeaveAnimation({duration: 300})
  ]
})
export class LoaderProgressComponent implements OnChanges, AfterViewInit {
  @Input() loading: boolean | null = false;
  @Input() color = 'white'; // black
  @Input() infinite = false;
  @Input() withDoneMark = false;

  @ViewChild('progressLoader') loader: any;

  public showProgress: boolean = false;
  public showCheckMark: boolean = false;

  private loaderElRef!: Element;

  constructor(
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit(): void {
    this.loaderElRef = this.loader?.nativeElement;

    if (!this.loaderElRef) {
      return;
    }

    if (this.loaderElRef && this.infinite) {
      this.loaderElRef?.classList.add('show');
      this.showProgress = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.loaderElRef) {
      return;
    }

    if (changes['loading']?.currentValue && !changes['loading']?.previousValue) {
      this.loaderElRef.classList.add('show');
      this.showProgress = true;
    }

    if (!changes['loading']?.currentValue && changes['loading']?.previousValue) {
      if (this.withDoneMark) {
        this.showProgress = false;
        this.showCheckMark = true;

        asyncScheduler.schedule(() => {
          this.loaderElRef.classList?.remove('show');
          this.loaderElRef.classList?.add('hide');
        }, 1200)
      } else {
        this.loaderElRef.classList?.add('hide');
        this.loaderElRef.classList?.remove('show');
        this.showProgress = false;

        asyncScheduler.schedule(() => {
          this.loaderElRef.classList?.remove('show');
        }, 600)
      }
    }

    this.loaderElRef.addEventListener('animationend', (event: any) => {
      if (event.animationName === 'hideLoaderProgress') {
        this.loaderElRef.classList.remove('hide');
        this.showCheckMark = false;
        this.cdRef.detectChanges();
      }
    });
  }
}
