<div
  class="modal z-50 mx-auto lg:my-auto"
  [class.modal--big]="type === modalType.WARNING"
  [ngClass]="{
    'text-black bg-white dark:text-white dark:bg-black': type === modalType.ERROR,
    'text-black bg-yellow': type === modalType.WARNING || type === modalType.SUCCESS
  }"
>
  <div class="modal-icon">
    <img *ngIf="type === modalType.SUCCESS" src="/assets/smiles/clapperboard.png" alt="Icon">
    <img *ngIf="type === modalType.WARNING" src="/assets/smiles/warning.png" alt="Icon">
    <img *ngIf="type === modalType.ERROR" src="/assets/smiles/error.png" alt="Icon">
  </div>

  <div class="modal-header" *ngIf="title && title.length">
    <h5 class="text-h5">{{title}}</h5>
  </div>

  <ng-container *ngIf="message && message.length">
    <div class="modal-body">
      {{message}}
    </div>
  </ng-container>

  <div class="modal-footer" *ngIf="type !== modalType.SUCCESS">
    <button (click)="close()" class="big-btn big-btn__border big-btn--max-width">
      {{ 'btn.ok' | translate }}
    </button>
  </div>
</div>
