import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, of } from 'rxjs';

import {
  ModalCompanyNotVerifiedComponent
} from '../../../shared/components/modal-company-not-verified/modal-company-not-verified.component';
import {
  ModalCongratulationComponent
} from '../../../shared/components/modal-congratulation/modal-congratulation.component';
import {
  ModalNeedRegisterComponent
} from '../../../shared/components/modal-need-register/modal-need-register.component';
import { ModalApplyJobComponent } from '../../../shared/components/modal-apply-job/modal-apply-job.component';
import { CustomModalComponent } from '../../../shared/components/custom-modal/custom-modal.component';
import { ModalService } from '../../../shared/modules/modal/services/modal.service';
import { selectTalentProfile } from '../../talent/selectors/talent.selectors';
import { selectVocabularies } from '../../../core/selectors/core.selectors';
import { AmplitudeService } from '../../../shared/services/amplitude.service';
import { selectUser } from '../../auth/selectors/auth.selectors';
import { JobsService } from '../services/jobs.service';
import { JobsActions } from '../actions/job.actions';
import { ModalType } from '../../../config';

@Injectable()
export class JobsEffects {
  constructor(
    private amplitudeService: AmplitudeService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private jobsService: JobsService,
    private actions$: Actions,
    private router: Router,
    private store: Store,
  ) {
  }

  createJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.createJob),
      exhaustMap(({body}) =>
        this.jobsService.createJob(body).pipe(
          map(job => JobsActions.createJobSuccess({job})),
          catchError(({error}) => of(JobsActions.createJobFailure({error})))
        )
      )
    )
  );

  createJobSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.createJobSuccess),
      withLatestFrom(this.store.pipe(select(selectUser))),
      tap(([{job}, user]: any) => {
        this.router.navigate([`/company/${user.company.slug}/jobs/${job.slug}/edit`]);
        this.amplitudeService.track('job_created', {job_id: job.id, company_id: user.company.id});
      })
    ), {dispatch: false},
  );

  postJobNotVerified$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.postJob),
      withLatestFrom(this.store.pipe(select(selectUser))),
      filter(([, user]: any) => user?.company && !user?.company?.verified),
      switchMap(() => {
        return this.modalService.addModal(
          ModalCompanyNotVerifiedComponent, {}, {autoClose: 5000}
        );
      })
    ), {dispatch: false}
  );

  postJobVerified$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.postJob),
      withLatestFrom(this.store.pipe(select(selectUser))),
      filter(([, user]: any) => user?.company && user?.company?.verified),
      tap(([, user]) => {
        this.router.navigate(['company', user.company.slug, 'post-job']);
      })
    ), {dispatch: false}
  );

  openJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.openJob),
      exhaustMap(({id, slug, redirect}) =>
        this.jobsService.openJob(id).pipe(
          map(job => JobsActions.openJobSuccess({job, slug, redirect})),
          catchError(({error}) => of(JobsActions.openJobFailure({error})))
        )
      )
    )
  );

  openJobSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.openJobSuccess),
      filter(({redirect}) => redirect),
      tap(({job}) => {
        this.router.navigate([`/company/${job.company.slug}/jobs/list/open`]);
        this.amplitudeService.track('job_published', {job_id: job.id, company_id: job.company.id});
      })
    ), {dispatch: false}
  );

  openJobSuccessModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.openJobSuccess),
      switchMap(() => {
        return this.modalService.addModal(
          CustomModalComponent, {
            type: ModalType.SUCCESS,
            message: this.translateService.instant('modal.jobOpenedSuccessfully'),
          }, {autoClose: 5000}
        );
      })
    ), {dispatch: false}
  );

  closeJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.closeJob),
      exhaustMap(({id}) =>
        this.jobsService.closeJob(id).pipe(
          map(job => JobsActions.closeJobSuccess({job})),
          catchError(({error}) => of(JobsActions.closeJobFailure({error})))
        )
      )
    )
  );

  closeJobSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.closeJobSuccess),
      tap(({job}) => {
        this.amplitudeService.track('job_unpublished', {job_id: job.id, company_id: job.company.id});
      })
    ), {dispatch: false}
  );

  patchGeneralInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.patchGeneralInfo),
      exhaustMap(({id, body}) =>
        this.jobsService.patchGeneralInfo(id, body).pipe(
          map(job => JobsActions.patchGeneralInfoSuccess({id, job})),
          catchError(({error}) => of(JobsActions.patchGeneralInfoFailure({error})))
        )
      )
    )
  );

  patchConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.patchConditions),
      exhaustMap(({id, body}) =>
        this.jobsService.patchConditions(id, body).pipe(
          map(job => JobsActions.patchConditionsSuccess({id, job})),
          catchError(({error}) => of(JobsActions.patchConditionsFailure({error})))
        )
      )
    )
  );

  patchSalary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.patchSalary),
      exhaustMap(({id, body}) =>
        this.jobsService.patchSalary(id, body).pipe(
          map(job => JobsActions.patchSalarySuccess({id, job})),
          catchError(({error}) => of(JobsActions.patchSalaryFailure({error})))
        )
      )
    )
  );

  patchAbout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.patchAbout),
      exhaustMap(({id, body}) =>
        this.jobsService.patchAbout(id, body).pipe(
          map(job => JobsActions.patchAboutSuccess({id, job})),
          catchError(({error}) => of(JobsActions.patchAboutFailure({error})))
        )
      )
    )
  );

  patchRequirements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.patchRequirements),
      exhaustMap(({id, body}) =>
        this.jobsService.patchRequirements(id, body).pipe(
          map(job => JobsActions.patchRequirementsSuccess({id, job})),
          catchError(({error}) => of(JobsActions.patchRequirementsFailure({error})))
        )
      )
    )
  );

  patchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.patchResults),
      exhaustMap(({id, body}) =>
        this.jobsService.patchResults(id, body).pipe(
          map(job => JobsActions.patchResultsSuccess({id, job})),
          catchError(({error}) => of(JobsActions.patchResultsFailure({error})))
        )
      )
    )
  );

  getJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.getJobs),
      exhaustMap(({slug}) =>
        this.jobsService.getJobs(slug).pipe(
          map(jobs => JobsActions.getJobsSuccess({jobs})),
          catchError(({error}) => of(JobsActions.getJobsFailure({error})))
        )
      )
    )
  );

  getJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.getJob),
      exhaustMap(({slug}) =>
        this.jobsService.getJob(slug).pipe(
          map(job => JobsActions.getJobSuccess({job})),
          catchError((err) => {
            const {error} = err;

            if (error.statusCode === 404) {
              return of(JobsActions.getJobSuccess({job: {notFound: true, slug}}))
            }

            return of(JobsActions.getJobFailure({error, silent: true}));
          })
        )
      )
    )
  );

  getJobFeedbacks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.getJobFeedbacks),
      exhaustMap(({slug}) =>
        this.jobsService.getJobFeedbacks(slug).pipe(
          map(({feedbacks, flow}) => JobsActions.getJobFeedbacksSuccess({feedbacks, flow})),
          catchError(({error}) => of(JobsActions.getJobFeedbacksFailure({error})))
        )
      )
    )
  );

  feedbackToJobOpenModalAuthorized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.feedbackToJobOpenModal),
      withLatestFrom(
        this.store.pipe(select(selectVocabularies)),
        this.store.pipe(select(selectUser))
      ),
      filter(([, , user]) => !!user),
      tap(([{job}]) => this.amplitudeService.track('apply_job_click', {job_id: job.id})),
      switchMap(([{job}, vocabularies]) =>
        this.modalService.addModal(
          ModalApplyJobComponent,
          {
            job,
            vocabularies,
          },
          {autoClose: false}
        ).pipe(
          map((body) => {
            return body
              ? JobsActions.feedbackToJob({id: job.id, body, slug: job.slug})
              : JobsActions.feedbackToJobCancel({job});
          })
        )
      )
    )
  );

  feedbackToJobCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.feedbackToJobCancel),
      tap(({job}) => this.amplitudeService.track('apply_job_cancel', {job_id: job.id})),
    ), {dispatch: false}
  );

  feedbackToJobOpenModalAnonymous$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.feedbackToJobOpenModal),
      withLatestFrom(this.store.pipe(select(selectUser))),
      filter(([, user]) => !user),
      switchMap(() => {
        return this.modalService.addModal(
          ModalNeedRegisterComponent, {}, {autoClose: 5000}
        );
      })
    ), {dispatch: false}
  );

  feedbackToJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.feedbackToJob),
      exhaustMap(({id, body, slug}) =>
        this.jobsService.feedbackToJob(id, body).pipe(
          map(feedback => JobsActions.feedbackToJobSuccess({feedback})),
          catchError(({error}) => of(JobsActions.feedbackToJobFailure({error})))
        )
      )
    )
  );

  feedbackToJobSuccessModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.feedbackToJobSuccess),
      withLatestFrom(this.store.select(selectTalentProfile)),
      switchMap(([_, talent]) => {
        return this.modalService.addModal(
          ModalCongratulationComponent,
          {
            slug: talent.slug,
          },
          {autoClose: 5000}
        );
      })
    ), {dispatch: false}
  );

  deleteJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.deleteJob),
      exhaustMap(({id, slug}) =>
        this.jobsService.deleteJob(id).pipe(
          map(() => JobsActions.deleteJobSuccess({slug})),
          catchError(({error}) => of(JobsActions.deleteJobFailure({error})))
        )
      )
    )
  );

  updateFeedbackStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.updateFeedbackStep),
      exhaustMap(({id, body}) =>
        this.jobsService.updateFeedbackStep(id, body).pipe(
          map(flowStep => JobsActions.updateFeedbackStepSuccess({id, flowStep})),
          catchError(({error}) => of(JobsActions.updateFeedbackStepFailure({error})))
        )
      )
    )
  );

  createFeedbackComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.createFeedbackComment),
      exhaustMap(({id, body}) =>
        this.jobsService.createFeedbackComment(id, body).pipe(
          map(comment => JobsActions.createFeedbackCommentSuccess({id, comment})),
          catchError(({error}) => of(JobsActions.createFeedbackCommentFailure({error})))
        )
      )
    )
  );

  updateFeedbackComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.updateFeedbackComment),
      exhaustMap(({id, feedbackId, body}) =>
        this.jobsService.updateFeedbackComment(id, body).pipe(
          map(comment => JobsActions.updateFeedbackCommentSuccess({id, feedbackId, comment})),
          catchError(({error}) => of(JobsActions.updateFeedbackCommentFailure({error})))
        )
      )
    )
  );

  deleteFeedbackComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.deleteFeedbackComment),
      exhaustMap(({id, feedbackId}) =>
        this.jobsService.deleteFeedbackComment(id).pipe(
          map(() => JobsActions.deleteFeedbackCommentSuccess({id, feedbackId})),
          catchError(({error}) => of(JobsActions.deleteFeedbackCommentFailure({error})))
        )
      )
    )
  );
}
