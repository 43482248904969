<section class="flex flex-col items-center h-full gap-y-7">
  <div class="tab-items" #tabGroup>
    <div
      class="tab"
      (click)="toggleTab($event, 'open')"
      routerLink="open"
      routerLinkActive="active"
    >
      <span class="pointer-events-none">
        {{ 'company.jobs_list.tabs.open' | translate}}
      </span>
    </div>
    <div
      (click)="toggleTab($event, 'draft')"
      class="tab"
      routerLink="draft"
      routerLinkActive="active"
    >
      <span class="pointer-events-none">
        {{ 'company.jobs_list.tabs.draft' | translate}}
      </span>
    </div>
    <div
      class="tab"
      (click)="toggleTab($event, 'close')"
      routerLink="close"
      routerLinkActive="active"
    >
      <span class="pointer-events-none">
        {{ 'company.jobs_list.tabs.close' | translate}}
      </span>
    </div>
    <div
      class="tab-items__underline"
      [ngStyle]="styles"
    ></div>
  </div>

  <section [@routeSlide]="routeTrigger$ | async" class="w-full relative flex-grow">
    <router-outlet (activate)="onActivate()"></router-outlet>
  </section>
</section>
