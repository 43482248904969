<div class="navigation-menu" [class.opened]="isOpen" [class.show-menu]="mobileBtnOpen">
  <div class="navigation-menu__header">
    <a routerLink="/dashboard" class="navigation-menu__logo">
      <svg-icon class="flex w-9" src="logo-icon.svg" [ngStyle]="{'width.px': 30}"></svg-icon>
      <svg-icon
        class="logo-text hidden w-20 lg:flex" src="logo-text.svg"
        *ngIf="isOpen"
        @fadeInRightOnEnter
        @fadeOutRightOnLeave
      ></svg-icon>
    </a>

    <label [attr.for]="'language'" class="ml-auto lg:hidden not-opacity">
      <ng-select
        class="language"
        [formControl]="languageSwitcher"
        (change)="languageChange.emit($event.value)"
        [items]="languagesList"
        labelForId="language"
        [multiple]="false"
        [clearable]="false"
        [searchable]="false"
        bindLabel="name"
        bindValue="value"
      ></ng-select>
    </label>

    <div class="mobile-btn lg:hidden" [class.btn-opened]="mobileBtnOpen" (click)="onOpenMenu()">
      <div></div>
    </div>
  </div>

  <nav class="navigation-menu__menu" *ngIf="navigation && navigation.length > 0">
    <ul>
      <li *ngFor="let item of navigation; let idx = index">
        <ng-container *ngIf="!item?.openNewWindow; else openNewWindow">
          <a

            [routerLinkActiveOptions]="{ exact: false }"
            [routerLink]="item.url"
            routerLinkActive="active"
            [class.selected]="item.selected && item.selected.paths.length > 0 && item.selected.paths.includes(activeURL)"
          >
            <svg-icon *ngIf="item?.icon" [src]="item.icon!"></svg-icon>
            <span
              *ngIf="isOpen"
              [@fadeInRightOnEnter]="{ value: '', params: { duration: 600, delay: 150 * idx} }"
              @fadeOutRightOnLeave
            >
              {{ item.name | translate }}
            </span>
          </a>
        </ng-container>

        <ng-template #openNewWindow>
          <a
            *appHasRoles="[roles.COMPANY_ADMIN, roles.COMPANY_EMPLOYEE]"
            [href]="item?.url" target="_blank"
          >
            <svg-icon *ngIf="item?.icon" [src]="item.icon!"></svg-icon>
            <span
              *ngIf="isOpen"
              [@fadeInRightOnEnter]="{ value: '', params: { duration: 600, delay: 150 * idx} }"
              @fadeOutRightOnLeave
            >
              {{ item.name | translate }}
            </span>
          </a>
        </ng-template>
      </li>
    </ul>
  </nav>

  <div
    class="navigation-menu__footer mt-auto m-2.5 flex flex-col gap-y-2 lg:hidden"
    [class.navigation-menu__footer--show]="isOpen"
  >
    <app-user-menu
      class="py-6 flex justify-center"
      [userName]="user?.fullName || user?.email"
      [userType]="user?.type"
      [navigation]="secondaryNav"
    ></app-user-menu>

    <button
      *ngIf="user?.type === 'company'"
      class="big-btn bg-black text-white mt-14"
      [routerLink]="'/company/' + user?.company?.slug + '/post-job'"
    >
      {{ 'btn.add_new_job' | translate }}
    </button>
  </div>

  <button
    (click)="onToggleMenu()"
    [class.open]="isOpen"
    [disabled]="disabledBtnToggle"
    class="mt-auto ml-auto not-hover toggle-menu hidden lg:flex"
  >
    <div class="toggle-menu__wrapper">
      <svg-icon class="toggle-menu__icon toggle-menu__top" src="toggle-menu-arrow-top.svg"></svg-icon>
      <svg-icon class="toggle-menu__icon toggle-menu__bottom" src="toggle-menu-arrow-bot.svg"></svg-icon>
    </div>
  </button>
</div>
