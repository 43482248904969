import { createAction, props } from '@ngrx/store';

import { IActionRequest, IActionRequestError, IActionRequestSuccess } from '../../../models';

const getCompanies = createAction('[Search] Get Company Request', props<IActionRequest<{ body?: any }>>());
const getCompaniesSuccess = createAction('[Search] Get Company Success', props<IActionRequestSuccess<{ result: any }>>());
const getCompaniesFailure = createAction('[Search] Get Company Failure', props<IActionRequestError>());

const getJobs = createAction('[Search] Get Jobs Request', props<IActionRequest<{ body?: any }>>());
const getJobsSuccess = createAction('[Search] Get Jobs Success', props<IActionRequestSuccess<{ result: any }>>());
const getJobsFailure = createAction('[Search] Get Jobs Failure', props<IActionRequestError>());

const getTalents = createAction('[Search] Get Talents Request', props<IActionRequest<{ body?: any }>>());
const getTalentsSuccess = createAction('[Search] Get Talents Success', props<IActionRequestSuccess<{ result: any }>>());
const getTalentsFailure = createAction('[Search] Get Talents Failure', props<IActionRequestError>());

export const SearchActions = {
  getCompanies,
  getCompaniesSuccess,
  getCompaniesFailure,

  getJobs,
  getJobsSuccess,
  getJobsFailure,

  getTalents,
  getTalentsSuccess,
  getTalentsFailure,
};
