import { ActionReducer, MetaReducer } from '@ngrx/store';

import { environment } from '../../environments/environment';

export function emptyReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return reducer;
}

export function setStateReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state: any, action: any) {
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }

    return reducer(state, action);
  };
}

export function stateTransferReducer(): MetaReducer {
  if (environment.local) {
    return emptyReducer;
  }

  return setStateReducer;
}
