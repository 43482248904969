import { ApplicationRef, ComponentFactoryResolver, Injector } from '@angular/core';

import { ModalService, ModalServiceConfig } from './modal.service';

export function ModalServiceFactory(
  resolver: ComponentFactoryResolver,
  applicationRef: ApplicationRef,
  injector: Injector,
  options: ModalServiceConfig,
  platformId: any
) {
  return new ModalService(resolver, applicationRef, injector, options, platformId);
}
