<div class="job-card text-black dark:text-white" [routerLink]="'/company/' + job?.company?.slug + '/jobs/' + job?.slug + '/kanban'">
  <div class="job-card__date bg-black lg:bg-transparent xlg:bg-black lg:text-black xlg:text-white">
    {{ job?.createdAt | dfnsParseIso | dfnsFormat: 'dd.MM.yyyy' }}
  </div>

  <div class="job-card__main">
    <div class="job-card__info">
      <div class="label-status text-yellow">{{ job?.status }}</div>

      <div class="job-card__name">
        {{vocabularies?.['position']?.terms[job?.position]?.title}}
      </div>

      <div class="job-card__salary font-normal">
        {{ job?.salaryFrom }} - {{ job?.salaryTo }}
      </div>

      <!--@TODO: version 2.1-->
      <div class="job-card__create font-normal" *ngIf="false"></div>
    </div>

    <div class="job-card__items">
      <span>
        <svg-icon src="location.svg"></svg-icon>
        {{ job?.location?.city }}
      </span>
      <span>{{vocabularies?.['category']?.terms[job?.category]?.title}}</span>
    </div>

    <div class="job-card__action">
      <div class="counter-number bg-black text-white lg:text-black lg:bg-white xlg:text-white xlg:bg-black">
        <svg-icon class="counter-number__icon" src="icon-five-finger.svg"></svg-icon>
        <span class="text-es bg-yellow text-black">
          {{ job?.newAppliedCount }}
        </span>
      </div>

      <!--@TODO: version 2.1-->
      <div class="counter-number bg-black text-white lg:text-black lg:bg-white xlg:text-white xlg:bg-black" *ngIf="false">
        <svg-icon class="counter-number__icon" src="icon-bell.svg"></svg-icon>
        <span class="text-es bg-yellow text-black">
          {{ job?.countChat }}
        </span>
      </div>
    </div>
  </div>
</div>
