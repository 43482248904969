<div class="modal-form modal-form--small text-black">
  <div class="modal-form__title">
    {{ 'modal.change_password.title' | translate }}
  </div>

  <form class="modal-form__main" [formGroup]="form" (submit)="submit()">
    <div class="modal-form__wrapper">
      <div class="form-group required required--white">
        <label for="current-password">
          {{ 'modal.change_password.current_password' | translate }}
        </label>

        <div class="password-wrapper">
          <input
            id="current-password"
            class="yellow"
            [type]="showPassword ? 'password' : 'text'"
            formControlName="currentPassword"
            appErrorMessage
          >

          <div (click)="togglePassword()" class="eye">
            <svg-icon *ngIf="showPassword" src="eye-close.svg"></svg-icon>
            <svg-icon *ngIf="!showPassword" src="eye-open.svg"></svg-icon>
          </div>
        </div>
      </div>

      <div class="form-group required required--white">
        <label for="new-password">
          {{ 'modal.change_password.new_password' | translate }}
        </label>

        <div class="password-wrapper">
          <input
            id="new-password"
            class="yellow"
            [type]="showPassword ? 'password' : 'text'"
            formControlName="newPassword"
            appErrorMessage
          >

          <div (click)="togglePassword()" class="eye">
            <svg-icon *ngIf="showPassword" src="eye-close.svg"></svg-icon>
            <svg-icon *ngIf="!showPassword" src="eye-open.svg"></svg-icon>
          </div>
        </div>
      </div>

      <div class="form-group required required--white">
        <label for="confirm-password">
          {{ 'modal.change_password.confirm_password' | translate }}
        </label>

        <div class="password-wrapper">
          <input
            id="confirm-password"
            class="yellow"
            [type]="showPassword ? 'password' : 'text'"
            formControlName="confirmPassword"
            appErrorMessage
          >

          <div (click)="togglePassword()" class="eye">
            <svg-icon *ngIf="showPassword" src="eye-close.svg"></svg-icon>
            <svg-icon *ngIf="!showPassword" src="eye-open.svg"></svg-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-form__action flex justify-end">
      <button
        [disabled]="form.invalid || form.disabled"
        class="big-btn big-btn--small w-full bg-white justify-end"
        type="submit"
      >
        {{ 'btn.save' | translate }}
      </button>
    </div>
  </form>

  <button class="modal-form__close" (click)="close()">
    <svg-icon src="close.svg"></svg-icon>
  </button>
</div>
