import {
  eachDayOfInterval, startOfMonth, endOfMonth, getDate,
  getMonth, getYear, isToday, isSameDay, isSameMonth,
  isSameYear, isBefore, isAfter, getDay, subDays,
  setDay, format, addMonths, subMonths, setYear,
  addYears, subYears, eachMonthOfInterval, startOfYear,
  endOfYear, setMonth
} from 'date-fns';
import {
  Component, OnInit, Input, OnChanges, SimpleChanges, HostListener, ElementRef, Inject, ChangeDetectorRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { fromEvent, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';

import {
  DatepickerOptions, defaultOptions,
  IDay, mergeDatepickerOptions
} from './datepicker-options.interface';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DatePickerComponent,
    multi: true
  }]
})
export class DatePickerComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() options: DatepickerOptions = {
    ...defaultOptions,
    locale: this.dateConfig.locale(),
  };
  @Input() placeholder: any = '';
  @Input() isOpened = false;
  @Input() positionRight = false;
  @Input() color = 'white'; // yellow black
  @Input() id?: string;

  public innerValue: Date = new Date();
  public displayValue = '';
  public view: string = this.options.view;
  public years: { year: number; isThisYear: boolean }[] = [];
  public days: IDay[] = [];
  public months: { month: Date; isThisMonth: boolean }[] = [];
  public dayNames: string[] = [];
  public date: Date = new Date();
  public isDisabled = false;

  @AutoUnsubscribe()
  private sub: Subscription = new Subscription();
  private doc: Document;

  get value(): Date {
    return this.innerValue;
  }

  set value(val: Date) {
    this.innerValue = val;
    this.displayValue = format(this.innerValue, this.options.format as string, {locale: this.options.locale});
    this.onTouched();
    this.onChange(this.innerValue);
  }

  get month(): string {
    return format(this.date, this.options.formatTitleMonths as string, {locale: this.options.locale});
  }

  get title(): string {
    return format(this.date, this.options.formatTitleYears as string, {locale: this.options.locale});
  }

  constructor(
    private dateConfig: DateFnsConfigurationService,
    @Inject(DOCUMENT) document: any,
    private ref: ChangeDetectorRef,
    public elementRef: ElementRef,
  ) {
    this.doc = document as Document;
  }

  ngOnInit(): void {
    this.view = this.options.view;
    this.date = new Date();
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('options' in changes) {
      this.options = mergeDatepickerOptions(this.options);

      if (this.sub) {
        this.sub.unsubscribe();
      }

      if (this.options.enableKeyboard) {
        this.sub = fromEvent<KeyboardEvent>(this.doc || document, 'keyup')
          .pipe(filter(() => this.isOpened))
          .subscribe(e => {
            e.preventDefault();
            e.stopPropagation();

            switch (e.key) {
              case 'Down':
              case 'ArrowDown':
                this.prevYear();
                break;
              case 'Up':
              case 'ArrowUp':
                this.nextYear();
                break;
              case 'Left':
              case 'ArrowLeft':
                this.prevMonth();
                break;
              case 'Right':
              case 'ArrowRight':
                this.nextMonth();
                break;
              case 'Esc':
              case 'Escape':
              case 'Enter':
                this.isOpened = false;
                break;
              default:
                return;
            }
          });
      }
    }
  }

  toggle(): void {
    this.isOpened = !this.isOpened;

    if (this.isOpened) {
      this.view = this.options.view;
      this.date = this.value;
      this.initDays();
    }
  }

  toggleView(type: string): void {
    this.view = `${type}`;

    if (this.view === 'years') {
      this.ref.detectChanges();
      this.scrollToYear();
    }
  }

  nextMonth(): void {
    this.date = addMonths(this.date, 1);
    this.initDays();
  }

  prevMonth(): void {
    this.date = subMonths(this.date, 1);
    this.initDays();
  }

  nextYear(): void {
    this.date = addYears(this.date, 1);
    this.initDays();
  }

  prevYear(): void {
    this.date = subYears(this.date, 1);
    this.initDays();
  }

  setDate(i: number): void {
    this.date = this.days[i].date;
    this.value = this.date;
    this.initDays();
    this.isOpened = false;
  }

  setYear(i: number): void {
    this.date = setYear(this.date, this.years[i].year);
    this.value = this.date;
    this.initDays();
    this.initYears();
  }

  setMonth(i: number): void {
    this.date = setMonth(this.date, i);
    this.value = this.date;
    this.initMonths();

    this.toggleView('years')

    this.isOpened = true;
  }

  private scrollToYear(): void {
    this.elementRef.nativeElement.querySelector('.year-unit.is-selected').scrollIntoView({block: 'nearest'});
  }

  private init(): void {
    this.initDayNames();
    this.initDays();
    this.initMonths();
    this.initYears();
  }

  private initDays(): void {
    const date = this.date || new Date();
    const [start, end] = [startOfMonth(date), endOfMonth(date)];

    this.days = eachDayOfInterval({start, end}).map((d: Date) => this.generateDay(d));

    const tmp = getDay(start) - (this.options.firstCalendarDay as number);
    const prevDays = tmp < 0 ? 7 - (this.options.firstCalendarDay as number) : tmp;
    for (let i = 1; i <= prevDays; i++) {
      const d = subDays(start, i);
      this.days.unshift(this.generateDay(d, false));
    }
  }

  public initMonths(): void {
    const date = this.date || new Date();
    const [start, end] = [startOfYear(date), endOfYear(date)];
    this.months = eachMonthOfInterval({start, end}).map(month => ({
      month,
      isThisMonth: getMonth(month) === getMonth(this.date),
    }));
  }

  private initYears(): void {
    const range = (this.options.maxYear as number) - (this.options.minYear as number) + 1;
    this.years = Array.from(new Array(range), (_, i) => i + (this.options.minYear as number)).map(year => {
      return {year, isThisYear: year === getYear(this.date)};
    });
  }

  private initDayNames(): void {
    this.dayNames = [];
    const start = this.options.firstCalendarDay as number;
    for (let i = start; i <= 6 + start; i++) {
      const date = setDay(new Date(), i);
      this.dayNames.push(format(date, this.options.formatDays as string, {locale: this.options.locale}));
    }
  }

  private generateDay(date: Date, inThisMonth: boolean = true): IDay {
    return {
      date,
      day: getDate(date),
      month: getMonth(date),
      year: getYear(date),
      inThisMonth,
      isToday: isToday(date),
      isSelected:
        isSameDay(date, this.innerValue) && isSameMonth(date, this.innerValue) && isSameYear(date, this.innerValue),
      isSelectable: this.isDateSelectable(date)
    };
  }

  private isDateSelectable(date: Date): boolean {
    if (this.options.minDate && isBefore(date, this.options.minDate)) {
      return false;
    }

    return !(this.options.maxDate && isAfter(date, this.options.maxDate));
  }

  writeValue(val: Date): void {
    if (!val) {
      return;
    }

    this.date = new Date(val);
    this.innerValue = val instanceof Date ? val : this.date;
    this.displayValue = format(this.innerValue, this.options.format as string, {locale: this.options.locale});
    this.init();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private onTouched = () => {
  };
  private onChange = (m: any) => {
  };

  @HostListener('document:click', ['$event']) onBlur(e: MouseEvent): void {
    if (!this.isOpened) {
      return;
    }

    const input = this.elementRef.nativeElement.querySelector('.datepicker-container > input');
    if (!input || e.target === input || input.contains(e.target)) {
      return;
    }

    const container = this.elementRef.nativeElement.querySelector('.datepicker-container > .calendar-container');
    if (
      container &&
      container !== e.target &&
      !container.contains(e.target) &&
      !(e.target as HTMLElement).classList.contains('year-unit') &&
      !(e.target as HTMLElement).classList.contains('month-unit')
    ) {
      this.isOpened = false;
    }

    if (this.displayValue === '') {
      this.onTouched();
      this.onChange(null);
    }
  }
}
