import {
  animationFrameScheduler,
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  endWith,
  interval,
  Subject,
  takeUntil,
  takeWhile
} from 'rxjs';
import { Directive, ElementRef, Inject, Input, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { isPlatformBrowser } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { map, switchMap } from 'rxjs/operators';

const easeOutQuad = (x: number): number => x * (2 - x);

@Directive({
  selector: '[appCountUp]'
})
export class CountUpDirective implements OnInit {
  @Input('appCountUp')
  set countTo(count: number) {
    const { innerText } = this.elementRef.nativeElement;

    if (innerText) {
      this.countFrom$.next(parseInt(innerText, 10));
    }

    this.countTo$.next(count);
  }

  @Input()
  set countFrom(value: number) {
    this.displayCurrentCount(value);
    this.countFrom$.next(value);
  }

  @Input()
  set duration(duration: number) {
    this.duration$.next(duration);
  }

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  private readonly countFrom$ = new BehaviorSubject(0);
  private readonly countTo$ = new BehaviorSubject(0);
  private readonly duration$ = new BehaviorSubject(2000);
  private readonly currentCount$ = combineLatest([
    this.countFrom$,
    this.countTo$,
    this.duration$,
  ]).pipe(
    switchMap(([countFrom, countTo, duration]) => {
      const startTime = animationFrameScheduler.now();

      return interval(0, animationFrameScheduler).pipe(
        map(() => animationFrameScheduler.now() - startTime),
        map((elapsedTime) => elapsedTime / duration),
        takeWhile((progress) => progress <= 1),
        map(easeOutQuad),
        map((progress) => Math.round(progress * (countTo - countFrom) + countFrom)),
        endWith(countTo),
        distinctUntilChanged()
      );
    })
  );


  constructor(
    @Inject(PLATFORM_ID) private platformId: Platform,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.currentCount$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((currentCount) => this.displayCurrentCount(currentCount));
    }
  }

  private displayCurrentCount(count: number): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', count);
  }
}
