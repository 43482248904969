import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import * as qs from 'qs';

import { DynamicFormGroup } from '../../models/dynamic-form-control.type';
import { Utils } from '../../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class UrlQueryService {

  constructor(
    @Inject(PLATFORM_ID) protected platformId: any,
    private activatedRoute: ActivatedRoute,
    private route: Router
  ) {
  }

  protected mapIdsToTermNames(values: any, vocabularies: any) {
    return Object.keys(values)
      // Skip null fields.
      .filter(fieldName => !!values[fieldName])
      // Skip empty arrays.
      .filter(fieldName => !Array.isArray(values[fieldName]) || !!values[fieldName].length)
      .reduce((acc, fieldName) => {
        const fieldValue = values[fieldName];

        // Handle nested objects.
        if (Utils.isObject(fieldValue?.[0])) {
          const preparedValues: any[] = fieldValue
            .map((item: any) => this.mapIdsToTermNames(item, vocabularies))
            // Skip empty objects.
            .filter((value: any) => !Utils.isObjectEmpty(value));

          // Add field only when we have values of it.
          return preparedValues.length ? {...acc, [fieldName]: preparedValues} : acc;
        } else if (vocabularies?.[fieldName]) {
          const {terms} = vocabularies[fieldName];

          // Update ids with user-friendly values.
          return {
            ...acc,
            [fieldName]: Array.isArray(fieldValue)
              ? fieldValue.map(id => terms[id]?.title)
              : terms[fieldValue]?.title
          };
        }

        return {...acc, [fieldName]: fieldValue};
      }, {});
  }

  protected mapTermNamesToIds(values: any, vocabularies: any) {
    return Object.keys(values)
      .reduce((acc, fieldName) => {
        const fieldQueryValue = values[fieldName];
        let fieldFormValue = fieldQueryValue;

        // Handle nested objects.
        if (Utils.isObject(fieldQueryValue?.[0])) {
          // Add field only when we have values of it.
          fieldFormValue = fieldQueryValue
            .map((item: any) => this.mapTermNamesToIds(item, vocabularies))
            // Skip empty objects.
            .filter((value: any) => !Utils.isObjectEmpty(value));
        } else if (vocabularies?.[fieldName]) {
          const terms = Object.values(vocabularies[fieldName].terms) as any[];

          // Update user-friendly values with terms ids.
          fieldFormValue = Array.isArray(fieldQueryValue)
            ? fieldQueryValue.map((title: any) => terms.find((term: any) => term.title === title)?.id).filter(item => !!item)
            : terms.find((term: any) => term.title === fieldQueryValue)?.id;
        }


        if (fieldFormValue && (!Utils.isObjectEmpty(fieldQueryValue) || fieldName?.length !== 0)) {
          return {...acc, [fieldName]: fieldFormValue};
        }

        return acc;
      }, {});
  }

  prepareVocabularyTermsToQuery(values: any, vocabularies: any) {
    const queryValues = this.mapIdsToTermNames(values, vocabularies);

    return !Utils.isObjectEmpty(queryValues)
      ? qs.stringify(queryValues, {encode: true}).split('&')
        .reduce((acc, item) => {
          const [key, value] = item.split('=');

          return {...acc, [key]: value};
        }, {})
      : {};
  }

  prepareVocabularyTermsToForm(queryParams: any, vocabularies: any, formStructure: DynamicFormGroup) {
    const queryString = Object.keys(queryParams).map((key) => `${key}=${queryParams[key]}`);
    const queryValues = qs.parse(queryString.join('&'));

    const values = Object.keys(queryValues).reduce((acc, key) => {
      if (formStructure.fields.find(({name}) => name === key)) {
        return {...acc, [key]: queryValues[key]};
      }

      return acc;
    }, {});

    return this.mapTermNamesToIds(values, vocabularies);
  }

  saveFormValuesToQueryParams(queryParams: any) {
    // @TODO: Need to find a better way to handle SSR. Need to move to AbstractSearchFiltersComponent.
    if (isPlatformBrowser(this.platformId)) {
      this.route.navigate([], {
        queryParams: {
          ...queryParams,
          page: this.activatedRoute.snapshot.queryParams?.['page'] || 1
        }
      });
    } else {
      this.route.navigate([], {queryParams});
    }
  }
}
