import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RouterAnimations } from '../../animations';

@Component({
  template: '',
  animations: [
    RouterAnimations.routeSlide
  ]
})
export abstract class RouteWithAnimationComponent {
  private routeTrigger = false;
  private itemChange$ = new BehaviorSubject<any>(this.routeTrigger);

  public routeTrigger$ = this.itemChange$.asObservable();

  protected constructor() {
  }

  public onActivate(): void {
    this.routeTrigger = !this.routeTrigger;
    this.itemChange$.next(this.routeTrigger);
  }
}
