import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { JobsListPageComponent } from './containers/jobs-list-page/jobs-list-page.component';
import { JobsListCanActivateGuard } from './guards/jobs-list-can-activate.guard';
import { JobEditCanActivateGuard } from './guards/job-edit-can-activate.guard';
import { JobViewCanActivateGuard } from './guards/job-view-can-activate.guard';
import { JobTokenResolver } from './services/job.token-resolver';
import { environment } from '../../../environments/environment';
import { AppRoutes } from '../../models/app-routes.type';
import { AuthGuard } from '../auth/guards/auth.guard';

const routes: AppRoutes = [
  {
    path: 'list',
    component: JobsListPageComponent,
    canActivate: [AuthGuard, JobsListCanActivateGuard],
    data: {
      breadcrumbs: [
        {title: 'company.navigation.job_list', url: '/company/{companySlug}/jobs/list'},
      ],
      pageTitle: {title: 'company.navigation.job_list'}
    },
    children: [
      {
        path: 'open',
        loadChildren: () => import('./modules/open-jobs/open-jobs.module').then(m => m.OpenJobsModule),
        title: `${environment.baseTitle} | company.jobs_list.tabs.open`,
      },
      {
        path: 'draft',
        loadChildren: () => import('./modules/draft-jobs/draft-jobs.module').then(m => m.DraftJobsModule),
        title: `${environment.baseTitle} | company.jobs_list.tabs.draft`,
      },
      {
        path: 'close',
        loadChildren: () => import('./modules/closed-jobs/closed-jobs.module').then(m => m.ClosedJobsModule),
        title: `${environment.baseTitle} | company.jobs_list.tabs.closed`,
      },
      {
        path: '**',
        redirectTo: 'open'
      },
    ]
  },
  {
    path: '',
    loadChildren: () => import('./modules/jobs-list/jobs-list.module').then(m => m.JobsListModule),
    title: `${environment.baseTitle} | company.navigation.company_jobs`,
    data: {
      navigationSettings: {
        anonymous: {
          showNavigation: false,
        }
      },
    }
  },
  {
    path: ':jobSlug',
    loadChildren: () => import('./modules/job/job.module').then(m => m.JobModule),
    canActivate: [JobViewCanActivateGuard],
    title: `${environment.baseTitle} | company.navigation.job`,
    data: {
      navigationSettings: {
        anonymous: {
          showNavigation: false
        }
      },
      breadcrumbs: [
        {
          title: 'company.navigation.job_list',
          url: '/company/{companySlug}/jobs'
        },
        {
          title: 'company.navigation.job'
        },
      ]
    }
  },
  {
    path: ':jobSlug/edit',
    canActivate: [AuthGuard, JobEditCanActivateGuard],
    loadChildren: () => import('./modules/job-form/job-form.module').then(m => m.JobFormModule),
    title: `${environment.baseTitle} | company.navigation.job_edit`,
    data: {
      breadcrumbs: [
        {title: 'company.navigation.job_list', url: '/company/{companySlug}/jobs/list'},
        {title: `company.navigation.job`, url: '/company/{companySlug}/jobs/{jobSlug}'},
        {title: `company.navigation.job_edit`}
      ],
      pageTitle: {title: 'company.navigation.job_edit'}
    }
  },
  {
    path: ':jobSlug/kanban',
    canActivate: [AuthGuard, JobEditCanActivateGuard],
    loadChildren: () => import('./modules/kanban/kanban.module').then(m => m.KanbanModule),
    title: `${environment.baseTitle} | company.navigation.kanban`,
    data: {
      breadcrumbs: [
        {
          title: 'company.navigation.job_list',
          url: '/company/{companySlug}/jobs/list'
        },
        {
          title: `company.navigation.job`,
          url: '/company/{companySlug}/jobs/{jobSlug}'
        },
        {
          title: `company.navigation.kanban`
        }
      ],
      pageTitle: {
        title: `{${JobTokenResolver.JOB_NAME}}`,
        resolvers: [JobTokenResolver]
      }
    }
  },
  {
    path: '**',
    redirectTo: '/auth-redirect'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobsRoutingModule {
}
