import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  Type,
  ViewContainerRef,
  ViewChild,
  Renderer2,
  ComponentRef,
  NgZone,
} from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  DefaultModalOptionConfig,
  ModalOptions,
  ModalOptionsOverrides
} from './../modal-options';
import { ModalDraggableDirective } from './../modal-draggable.directive';
import { ModalWrapperComponent } from './modal-wrapper.component';
import { ModalComponent } from './modal.component';

@Component({
  selector: 'app-modal-holder',
  template: '<ng-template #viewContainer></ng-template>',
})
export class ModalHolderComponent {
  @ViewChild('viewContainer', {read: ViewContainerRef, static: true}) viewContainer: any;

  public modals: Array<ModalComponent<any, any>> = [];
  public previousActiveElement: any = null;


  constructor(
    private resolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private ngZone: NgZone,
    @Inject(DefaultModalOptionConfig) private defaultSimpleModalOptions: ModalOptions,
  ) {
  }

  addModal<T, T1>(
    component: Type<ModalComponent<T, T1>>,
    data?: T | any,
    options?: ModalOptionsOverrides,
  ): Observable<T1 | any> {
    if (!this.viewContainer) {
      return of(null);
    }

    const factory = this.resolver.resolveComponentFactory(ModalWrapperComponent);
    const componentRef = this.viewContainer.createComponent(factory);
    const modalWrapper: ModalWrapperComponent = <ModalWrapperComponent>(
      componentRef.instance
    );
    const {ref: _componentRef, component: _component} = modalWrapper.addComponent(component);

    _component.options = options = Object.assign({}, this.defaultSimpleModalOptions, options) as ModalOptions;

    if (_component?.optionsOverride) {
      _component.options = {
        ..._component.options,
        ..._component?.optionsOverride
      };
    }

    modalWrapper.modalClasses = options.wrapperDefaultClasses!;

    this.modals.push(_component);

    this.wait().then(() => {
      this.toggleWrapperClass(modalWrapper.wrapper, options?.wrapperClass!);
      this.toggleBodyClass(options?.bodyClass!);

      if (options?.draggable) {
        this.setDraggable(_componentRef, options);
      }
      this.wait(options?.animationDuration).then(() => {
        this.autoFocusFirstElement(_component.wrapper, options?.autoFocus!);
        _component.markAsReady();
      });
    });

    _component.onClosing((modal: any) => this.removeModal(modal));

    if (typeof options.autoClose !== 'boolean') {
      setTimeout(() => {
        _component.close();
      }, options.autoClose);
    }

    this.configureCloseOnClickOutside(modalWrapper);

    _component.mapDataObject(data);

    return _component.setupObserver();
  }

  removeModal(closingModal: ModalComponent<any, any>): Promise<any> {
    const options = closingModal.options;
    this.toggleWrapperClass(closingModal.wrapper, options.wrapperClass);

    return this.wait(options.animationDuration).then(() => {
      this.removeModalFromArray(closingModal);
      this.toggleBodyClass(options.bodyClass);
      this.restorePreviousFocus();
    });
  }

  removeAllModals(): Promise<any> {
    return Promise.all(this.modals.map(modal => this.removeModal(modal)));
  }

  private toggleBodyClass(bodyClass: string): void {
    if (!bodyClass) {
      return;
    }

    const body = document.getElementsByTagName('body')[0];
    const bodyClassItems = bodyClass.split(' ');

    if (!this.modals.length) {
      body.classList.remove(...bodyClassItems);
    } else {
      body.classList.add(...bodyClassItems);
    }
  }

  private configureCloseOnClickOutside(modalWrapper: ModalWrapperComponent) {
    modalWrapper.onClickOutsideModalContent(() => {
      if (modalWrapper.content.options.closeOnClickOutside) {
        modalWrapper.content.close();
      }
    });
  }

  private autoFocusFirstElement(componentWrapper: ElementRef, autoFocus: boolean) {
    if (autoFocus) {
      const focusable = componentWrapper.nativeElement.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );
      if (focusable && focusable.length) {
        this.previousActiveElement = (document.activeElement as any);
        focusable[0].focus();
      }
    }
  }

  private restorePreviousFocus() {
    if (this.previousActiveElement) {
      this.previousActiveElement.focus();
      this.previousActiveElement = null;
    }
  }

  private toggleWrapperClass(modalWrapperEl: ElementRef, wrapperClass: string): void {
    const wrapperClassList = modalWrapperEl.nativeElement.classList;
    const wrapperClassItems = wrapperClass.split(' ');

    if (wrapperClassList.toString().indexOf(wrapperClass) !== -1) {
      wrapperClassList.remove(...wrapperClassItems);
    } else {
      wrapperClassList.add(...wrapperClassItems);
    }
  }

  private setDraggable(component: ComponentRef<ModalComponent<any, any>>, options: ModalOptionsOverrides): void {
    const draggableDirective = new ModalDraggableDirective(component.location, this.ngZone, this.renderer);
    draggableDirective.dragTarget = component.location.nativeElement;
    draggableDirective.dragHandle = component.instance.handle ? component.instance.handle.nativeElement : undefined;
    draggableDirective.ngAfterViewInit();
    component.location.nativeElement.classList.add(options.draggableClass);
  }


  private wait(ms: number = 0): Promise<unknown> {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(null), ms);
    });
  }

  private removeModalFromArray(component: any): void {
    const index = this.modals.indexOf(component);
    if (index > -1) {
      this.viewContainer.remove(index);
      this.modals.splice(index, 1);
    }
  }
}
