import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { Component, Input } from '@angular/core';

import { INavigationInterface } from '../../../models';
import { Roles } from '../../../config';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  animations: [
    fadeInOnEnterAnimation({duration: 300}),
    fadeOutOnLeaveAnimation({duration: 300})
  ]
})
export class UserMenuComponent {
  @Input() userName!: string;
  @Input() userType!: string;
  @Input() navigation!: INavigationInterface[] | null;

  public roles = Roles;
  public isOpenedUserMenu = false;

  constructor() {
  }
}
