import { HttpClient } from '@angular/common/http';
import { serialize } from 'object-to-formdata';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private cookieService: CookieService,
    private http: HttpClient
  ) {
  }

  public isUserAuthenticated(): boolean {
    return this.cookieService.hasKey('accessToken') && this.cookieService.hasKey('refreshToken');
  }

  public socialAuth(provider: string): Observable<any> {
    return this.http.get(`${environment.api}/social/${provider}`);
  }

  public socialAuthCallback(provider: string, params: any): Observable<any> {
    return this.http.get(`${environment.api}/social/${provider}/callback`, {params});
  }

  public signIn(body: any): Observable<any> {
    return this.http.post(`${environment.api}/auth/sign-in`, body);
  }

  public signUp(body: any): Observable<any> {
    return this.http.post(`${environment.api}/auth/register`, body);
  }

  public confirmEmail(body: any): Observable<any> {
    return this.http.post(`${environment.api}/auth/confirm`, body);
  }

  public confirmEmailPassword(body: any): Observable<any> {
    return this.http.post(`${environment.api}/auth/confirm/password`, body);
  }

  public logOut(): Observable<any> {
    return this.http.post(`${environment.api}/auth/log-out`, {});
  }

  public getUser(): Observable<any> {
    return this.http.get(`${environment.api}/auth/me`);
  }

  public updateUser(body: any): Observable<any> {
    const serializeOptions = {indices: true, dotsForObjectNotation: false};

    return this.http.patch(`${environment.api}/users`, serialize(body, serializeOptions));
  }

  public forgotPassword(email: string): Observable<any> {
    return this.http.post(`${environment.api}/auth/forgot-password`, {email});
  }

  public resetPassword(body: string): Observable<any> {
    return this.http.post(`${environment.api}/auth/reset-password`, body);
  }

  public refreshToken(observe: any = 'body'): Observable<any> {
    return this.http.get(`${environment.api}/auth/refresh`, { observe });
  }

  public resendConfirmationLink(email: string): Observable<any> {
    return this.http.post(`${environment.api}/auth/confirmation-resend`, {email});
  }

  public registerTalent(body: any): Observable<any> {
    return this.http.post(`${environment.api}/talent`, body);
  }

  public registerCompany(body: any): Observable<any> {
    const serializeOptions = {indices: true, dotsForObjectNotation: false};

    return this.http.post(`${environment.api}/company`, serialize(body, serializeOptions));
  }

  public changePassword(body: any): Observable<any> {
    return this.http.patch(`${environment.api}/auth/change-password`, body);
  }

  public deleteUser(): Observable<any> {
    return this.http.delete(`${environment.api}/users`);
  }

  public registerMember(body: any): Observable<any> {
    const serializeOptions = {indices: true, dotsForObjectNotation: false};

    return this.http.post(`${environment.api}/company/member`, serialize(body, serializeOptions));
  }

  public mailUnsubscribe(body: any): Observable<any> {
    return this.http.post(`${environment.api}/users/mail/unsubscribe`, body);
  }
}
