import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';

import { TalentPageComponent } from './containers/talent-page/talent-page.component';
import { talentFeatureKey, talentReducer } from './reducers/talent.reducer';
import { TalentRoutingModule } from './talent-routing.module';
import { TalentEffects } from './effects/talent.effects';

@NgModule({
  declarations: [
    TalentPageComponent
  ],
  imports: [
    CommonModule,
    TalentRoutingModule,
    StoreModule.forFeature(talentFeatureKey, talentReducer),
    EffectsModule.forFeature([TalentEffects])
  ]
})
export class TalentModule {
}
