<ng-container [formGroup]="form">
  <label [for]="'from-' + id">{{label | translate}}</label>

  <div class="flex gap-4 items-center relative">
    <div class="relative w-full">
      <app-date-picker
        formControlName="from"
        appErrorMessage
        [id]="'from-' + id"
        class="w-full relative"
      ></app-date-picker>
    </div>

    <span class="h-px bg-black w-4 lg:w-7 flex-none"></span>

    <div class="relative w-full">
      <app-date-picker
        formControlName="to"
        appErrorMessage
        class="w-full relative"
      ></app-date-picker>
    </div>
  </div>

  <app-checkbox class="mt-4" [label]="forNowLabel" formControlName="forNow" *ngIf="forNow"></app-checkbox>
</ng-container>
