import { AfterViewInit, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { of, Subject, takeUntil } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-accordion-talent',
  templateUrl: './accordion-talent.component.html',
  styleUrls: ['./accordion-talent.component.scss']
})
export class AccordionTalentComponent implements AfterViewInit{
  private lineCount!: number;
  private player!: AnimationPlayer;

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  @Input() className!: string;
  @Input() title: any;
  @Input() description: any;

  @Input() set showLine(count: number) {
    this.lineCount = count;
  }

  public isShow!: boolean;
  public showBtnAccordion = false;

  @ViewChild('descriptionText') descriptionText!: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private animationBuilder: AnimationBuilder,
    private elRef: ElementRef
  ) {
  }

  ngAfterViewInit(): void {
    const lineHeight = this.document?.defaultView?.getComputedStyle(this.elRef.nativeElement).lineHeight.split('px');

    if (!lineHeight) {
      return;
    }

    const minHeightBlock = +lineHeight[0] * this.lineCount;

    if (this.descriptionText.nativeElement.clientHeight > minHeightBlock) {
      this.showBtnAccordion = true;
      this.show();
    }
  }

  public toggleAccordion(): void {
    this.isShow = !this.isShow;
    this.show();
  }

  public show(): void {
    const lineHeight = this.document?.defaultView?.getComputedStyle(this.elRef.nativeElement).lineHeight.split('px')

    if (!lineHeight) {
      return;
    }

    const minHeightBlock = +lineHeight[0] * this.lineCount;

    this.elRef.nativeElement.style.webkitLineClamp = 'inherit';
    this.createPlayer(minHeightBlock);
    this.player.play();
  }

  private createPlayer(minHeight: number) {
    if (this.player) {
      this.player.destroy();
    }

    let animationFactory;

    if (this.isShow) {
      this.descriptionText.nativeElement.style.webkitLineClamp = 'inherit';

      animationFactory = this.animationBuilder.build([
        style({height: `${minHeight}px`}),
        animate(500, style({height: '*'})),
      ]);
    } else {
      animationFactory = this.animationBuilder.build([
        style({height: '*'}),
        animate(500, style({height: `${minHeight}px`})),
      ]);

      of(false)
        .pipe(
          takeUntil(this.unsubscribe$),
          delay(404)
        )
        .subscribe(() => this.descriptionText.nativeElement.style.webkitLineClamp = this.lineCount)
    }

    this.player = animationFactory.create(this.descriptionText.nativeElement);
  }
}
