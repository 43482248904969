import { Directive, OnInit, OnDestroy, Input, Inject, PLATFORM_ID } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[appSaveForm]'
})
export class SaveFormDirective implements OnInit, OnDestroy {
  @Input() formId!: string;
  @Input() formSubmit?: Observable<any>;

  public formSubmitted = false;
  private id!: string;
  private readonly window: Window;

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    private formGroupDirective: FormGroupDirective,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.window = this.document.defaultView as Window;
  }

  ngOnInit() {
    this.id = this.getUniqueFormId(`form-${this.formId}`);

    if (isPlatformBrowser(this.platformId) && this.id) {
      this.patchForm();

      this.formGroupDirective.form.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.saveForm();
        });

      if (this.formSubmit) {
        this.formSubmit
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
            this.formSubmitted = true;
            this.window.localStorage.removeItem(this.id);
          });
      }
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId) && this.id && !this.formSubmitted) {
      this.saveForm();
    }
  }

  private getUniqueFormId(formId: string): string {
    const formIds = Object.keys(localStorage).filter(key => key.startsWith('form-'));

    if (!formIds.includes(`form-${formId}`)) {
      return formId;
    }

    for (let i = 1; i < Number.MAX_SAFE_INTEGER; i++) {
      const newFormId = `${formId}-${i}`;

      if (!formIds.includes(newFormId)) {
        return newFormId;
      }
    }

    return formId;
  }

  private patchForm() {
    const formValue = JSON.parse(this.window.localStorage.getItem(this.id)!);

    if (formValue) {
      this.formGroupDirective.form.patchValue(formValue);
    }
  }

  private saveForm() {
    this.window.localStorage.setItem(this.id, JSON.stringify(this.formGroupDirective.form.value));
  }
}
