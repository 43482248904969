<ng-container *ngIf="file; else newFile">
  <div class="file-preview relative overflow-hidden">
    <div class="remove-file" (click)="removeFile()">
      <svg-icon src="close.svg"></svg-icon>
    </div>
    <div class="file-info">
      <svg-icon
        class="flex text-white" src="picture.svg"
        [ngStyle]="{ 'width.px': 20, 'height.px': 18 }"
      ></svg-icon>
      <span class="file-name">{{ file.fileName }}</span>
    </div>
  </div>
</ng-container>

<ng-template #newFile>
  <file-upload
    [id]="labelForId"
    appErrorMessage
    [control]="fileUploadControl"
    [multiple]="false"
    [animation]="false"
    class="file-upload"
  >
    <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
      <div>
        <div class="file-upload-text" *ngIf="isFileDragDropAvailable">
          <div class="file-upload-text__title font-bold" *ngIf="label">
            {{label | translate }}
          </div>
          <div class="file-upload-text__sub-title">
            {{ 'label.files_upload' | translate: { size: sizeLimit / 1000 / 1000, formats: filesLabel} }}
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template let-i="index" let-file="file" let-control="control" #item>
      <div class="remove-file" (click)="control.removeFile(file)">
        <svg-icon src="close.svg"></svg-icon>
      </div>
      <div class="file-info">
        <svg-icon
          class="flex text-white" src="picture.svg"
          [ngStyle]="{ 'width.px': 20, 'height.px': 18 }"
        ></svg-icon>
        <span class="file-name">{{ file.name }}</span>
      </div>
    </ng-template>
  </file-upload>
</ng-template>
