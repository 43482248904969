import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';

import { AppAnimatedPageComponent } from './containers/app-animated-page/app-animated-page.component';
import { AppPageComponent } from './containers/app-page/app-page.component';
import { coreFeatureKey, coreReducer } from './reducers/core.reducer';
import { AppComponent } from './components/app/app.component';
import { SharedModule } from '../shared/shared.module';
import { CoreEffects } from './effects/core.effects';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    StoreModule.forFeature(coreFeatureKey, coreReducer),
    EffectsModule.forFeature([CoreEffects])
  ],
  declarations: [
    AppAnimatedPageComponent,
    AppPageComponent,
    AppComponent,
  ],
  exports: [
    AppPageComponent,
  ]
})
export class CoreModule {
}
