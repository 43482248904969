import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-talent-row',
  templateUrl: './talent-row.component.html',
  styleUrls: ['./talent-row.component.scss']
})
export class TalentRowComponent {
  @Input() talent!: any;
  @Input() vocabularies!: any;

  constructor() {
  }
}
