import { Action, createFeatureSelector, createSelector, select, State } from '@ngrx/store';
import { interval, pipe, combineLatest, filter, distinctUntilChanged } from 'rxjs';
import { delayWhen, map } from 'rxjs/operators';

import { loadingFeatureKey } from '../reducers/loader.reducer';
import { Utils } from '../helpers/utils';

export const selectLoadingState = createFeatureSelector<State<any>>(loadingFeatureKey);
export const selectLoadingActions = createSelector(selectLoadingState, (state: any) => state);

// @TODO: Need to refactor selector.
// https://timdeschryver.dev/blog/parameterized-selectors#factory-selectors
export const selectLoading = createSelector(selectLoadingActions, (state: any, actions: string[]) => {
  const isActionLoading = actions.find(action => state.hasOwnProperty(action));

  if (!isActionLoading) {
    return null;
  }

  return actions.some((action) => state && !!state[action]);
});

export const selectLoadingWithDelay = (actions: Action[], delay: number = 1000) => {
  const requestsName = Utils.getRequestsName(actions);

  return pipe(
    select(selectLoading, requestsName),
    filter(val => val !== null),
    delayWhen(val => val === false ? interval(delay) : interval(0))
  );
};

export const selectLoadingObject = createSelector(selectLoadingActions, (state: any, actions: string[]) => {
  const isActionLoading = actions.find(action => state.hasOwnProperty(action));

  if (!isActionLoading) {
    return null;
  }

  return actions.reduce((acc, action) => ({...acc, [action]: !!state[action]}), {});
});

export const selectLoadingObjectWithDelay = (action: Action, delay: number) => {
  const requestName = Utils.getRequestName(action);

  return pipe(
    select(selectLoadingObject, [requestName]),
    delayWhen((actions: any) => actions?.[requestName] === false ? interval(delay) : interval(0))
  );
};

export const selectLoadingArrayWithDelay = (actions: Action[], delay: number = 1000) => {
  return (state: any) => {
    return combineLatest(actions.map(act => state.pipe(selectLoadingObjectWithDelay(act, delay))))
      .pipe(
        map((val: any[]) => val
          .filter(v => v !== null && Object.values(v)[0] === true)
          .map((obj) => Object.keys(obj)[0])
        ),
        distinctUntilChanged((a, b) => Utils.isArraysEqual(a, b)),
      );
  }
};

