import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { FormGroupDirective } from '@angular/forms';
import { Directive, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { FormHelperService } from '../services/form-helper.service';

@Directive({
  selector: '[appResetForm]'
})
export class ResetFormDirective implements OnInit {
  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    private formGroupDirective: FormGroupDirective,
    private formHelperService: FormHelperService,
  ) {
  }

  ngOnInit(): void {
    this.formHelperService.clearForm$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.formGroupDirective.form.reset());
  }
}
