import { createAction, props } from '@ngrx/store';

import { IRequestError } from '../../../models';

const setUserType = createAction('[Auth] Set User Type', props<{ userType: string }>());

const signIn = createAction('[Auth] Sign In Request', props<{ credentials: any }>());
const signInSuccess = createAction('[Auth] Sign In Success', props<{ user: any }>());
const signInFailure = createAction('[Auth] Sign In Failure', props<IRequestError>());

const signUp = createAction('[Auth] Sign Up Request', props<{ body: any }>());
const signUpSuccess = createAction('[Auth] Sign Up Success');
const signUpFailure = createAction('[Auth] Sign Up Failure', props<IRequestError>());

const forgotPassword = createAction('[Auth] Forgot Password Request', props<{ email: any }>());
const forgotPasswordSuccess = createAction('[Auth] Forgot Password Success');
const forgotPasswordFailure = createAction('[Auth] Forgot Password Failure', props<IRequestError>());

const resetPassword = createAction('[Auth] Reset Password Request', props<{ body: any }>());
const resetPasswordSuccess = createAction('[Auth] Reset Password Success', props<{ user: any }>());
const resetPasswordFailure = createAction('[Auth] Reset Password Failure', props<IRequestError>());

const resendConfirmation = createAction('[Auth] Resend Confirmation Request', props<{ email: any }>());
const resendConfirmationSuccess = createAction('[Auth] Resend Confirmation Success');
const resendConfirmationFailure = createAction('[Auth] Resend Confirmation Failure', props<IRequestError>());

const confirmEmail = createAction('[Auth] Confirm Email Request', props<{ token: any }>());
const confirmEmailSuccess = createAction('[Auth] Confirm Email Success', props<{ user: any }>());
const confirmEmailFailure = createAction('[Auth] Confirm Email Failure', props<IRequestError>());

const confirmEmailPassword = createAction('[Auth] Confirm Email Password Request', props<{ token: any, password: string }>());
const confirmEmailPasswordSuccess = createAction('[Auth] Confirm Email Password Success', props<{ user: any }>());
const confirmEmailPasswordFailure = createAction('[Auth] Confirm Email Password Failure', props<IRequestError>());

const socialAuth = createAction('[Auth] Social Auth Request', props<{ provider: string }>());
const socialAuthSuccess = createAction('[Auth] Social Auth Success', props<{ url: string }>());
const socialAuthFailure = createAction('[Auth] Social Auth Failure', props<IRequestError>());

const socialAuthCallback = createAction('[Auth] Social Auth Callback Request', props<{ params: any, provider: string }>());
const socialAuthCallbackSuccess = createAction('[Auth] Social Auth Callback Success', props<{ user: any }>());
const socialAuthCallbackFailure = createAction('[Auth] Social Auth Callback Failure', props<IRequestError>());

const logOut = createAction('[Auth] Log Out Request');
const logOutSuccess = createAction('[Auth] Log Out Success');
const logOutFailure = createAction('[Auth] Log Out Failure', props<IRequestError>());

const registerTalent = createAction('[Auth] Register Talent Request', props<{ body: any }>());
const registerTalentSuccess = createAction('[Auth] Register Talent Success');
const registerTalentFailure = createAction('[Auth] Register Talent Failure', props<IRequestError>());

const registerCompany = createAction('[Auth] Register Company Request', props<{ body: any }>());
const registerCompanySuccess = createAction('[Auth] Register Company Success', props<{ company: any }>());
const registerCompanyFailure = createAction('[Auth] Register Company Failure', props<IRequestError>());

const registerMember = createAction('[Auth] Register Member Request', props<{ body: any }>());
const registerMemberSuccess = createAction('[Auth] Register Member Success', props<{ user: any }>());
const registerMemberFailure = createAction('[Auth] Register Member Failure', props<IRequestError>());

const getUser = createAction('[Auth] Get User Request');
const getUserSuccess = createAction('[Auth] Get User Success', props<{ user: any }>());
const getUserFailure = createAction('[Auth] Get User Failure', props<IRequestError>());

const updateUser = createAction('[Auth] Update User Request', props<{ body: any }>());
const updateUserSuccess = createAction('[Auth] Update User Success', props<{ user: any }>());
const updateUserFailure = createAction('[Auth] Update User Failure', props<IRequestError>());

const changePassword = createAction('[Auth] Change Password Request', props<{ body: any }>());
const changePasswordSuccess = createAction('[Auth] Change Password Success', props<{ user: any }>());
const changePasswordFailure = createAction('[Auth] Change Password Failure', props<IRequestError>());

const deleteUser = createAction('[Auth] Delete User Request');
const deleteUserSuccess = createAction('[Auth] Delete User Success');
const deleteUserFailure = createAction('[Auth] Delete User Failure', props<IRequestError>());

const mailUnsubscribe = createAction('[Auth] Mail Unsubscribe Request', props<{ token: any, email: string }>());
const mailUnsubscribeSuccess = createAction('[Auth] Mail Unsubscribe Request Success');
const mailUnsubscribeFailure = createAction('[Auth] Mail Unsubscribe Request Failure', props<IRequestError>());

const updateTalentUser = createAction('[Auth] Update Talent User', props<{ profile: any }>());
const updateCompanyUser = createAction('[Auth] Update Company User', props<{ profile: any }>());

export const AuthActions = {
  setUserType,

  signIn,
  signInSuccess,
  signInFailure,

  signUp,
  signUpSuccess,
  signUpFailure,

  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,

  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,

  resendConfirmation,
  resendConfirmationSuccess,
  resendConfirmationFailure,

  confirmEmail,
  confirmEmailSuccess,
  confirmEmailFailure,

  confirmEmailPassword,
  confirmEmailPasswordSuccess,
  confirmEmailPasswordFailure,

  socialAuth,
  socialAuthSuccess,
  socialAuthFailure,

  socialAuthCallback,
  socialAuthCallbackSuccess,
  socialAuthCallbackFailure,

  logOut,
  logOutSuccess,
  logOutFailure,

  registerTalent,
  registerTalentSuccess,
  registerTalentFailure,

  registerCompany,
  registerCompanySuccess,
  registerCompanyFailure,

  registerMember,
  registerMemberSuccess,
  registerMemberFailure,

  getUser,
  getUserSuccess,
  getUserFailure,

  updateUser,
  updateUserSuccess,
  updateUserFailure,

  changePassword,
  changePasswordSuccess,
  changePasswordFailure,

  deleteUser,
  deleteUserSuccess,
  deleteUserFailure,

  mailUnsubscribe,
  mailUnsubscribeSuccess,
  mailUnsubscribeFailure,

  updateTalentUser,
  updateCompanyUser,
}
