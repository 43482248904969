<div
  class="cropper-input {{ classInput }}"
>
  <file-upload
    [multiple]="false"
    accept="image/*"
    [animation]="false"
    class="file-upload w-full"
    [control]="fileUploadControl"
  ></file-upload>

  <div class="cropper-input__value" *ngIf="file">
    <div class="cropper-input__picture">
      <img [src]="croppedImage" alt="">
    </div>
    <div class="cropper-input__name">
      {{ fileName }}
    </div>

    <div
      class="cropper-input__remove"
      [class.disabled]="this.fileUploadControl.disabled"
      (click)="removeImage()"
    >
      <svg-icon src="close.svg" [ngClass]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
    </div>
  </div>

  <div class="cropper-input__main" *ngIf="!file">
    <span class="cropper-input__title">{{ label | translate }}</span>
    <span class="cropper-input__sub-title">1MB max in JPG, PNG, GIF, SVG, SVG formats</span>
  </div>
</div>
