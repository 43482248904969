import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subject, takeUntil } from 'rxjs';

import { ModalCropperComponent } from '../modal-cropper/modal-cropper.component';
import { ModalService } from '../../modules/modal/services/modal.service';

@Component({
  selector: 'app-user-avatar-cropper',
  templateUrl: './user-avatar-cropper.component.html',
  styleUrls: ['./user-avatar-cropper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserAvatarCropperComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserAvatarCropperComponent),
      multi: true
    }
  ]
})
export class UserAvatarCropperComponent implements ControlValueAccessor, OnInit, Validator {
  @Input() classInput!: string;
  @Input() label = 'label.upload_photo';
  @Input() avatarLabel?: string;
  @Input() sizeLimit: number = 1_000_000;

  public onTouched = (m: any) => {
  };
  private onChange = (m: any) => {
  };

  public croppedImage: any = '';
  public fileName: any;
  public file: any | undefined = undefined;

  public readonly fileUploadControl = new FileUploadControl(
    {listVisible: true, accept: ['image/*'], discardInvalid: true, multiple: false},
    [
      FileUploadValidators.accept(['image/*']),
      FileUploadValidators.filesLimit(1),
      FileUploadValidators.sizeLimit(this.sizeLimit)
    ]
  );

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.fileUploadControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data.length) {
          this.fileChangeEvent(data, !!this.fileUploadControl?.getError().length);
        }
      });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const errors = this.fileUploadControl.getError();
    return errors && errors.length > 0 ? errors[0] : null;
  }

  public fileChangeEvent(event: File[], isErrors = true): void {
    if (isErrors) {
      this.onTouched(null);
      this.onChange(null);
      this.fileUploadControl.clear();

      return;
    }

    const imageFile: File = event[0];

    if (imageFile) {
      this.fileName = imageFile.name;
      this.modalService
        .addModal(ModalCropperComponent, { imageFile }, { autoClose: false })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((croppedImage) => {
          if (croppedImage) {
            this.croppedImage = croppedImage;
            this.saveImage();
          } else {
            this.removeImage();
          }
        });
    }
  }

  public saveImage(): void {
    this.file = this.dataURLtoFile(this.croppedImage, this.fileName);
    this.onTouched(this.file);
    this.onChange(this.file);
    this.cdRef.detectChanges();
  }

  public removeImage(): void {
    if (this.fileUploadControl.disabled) {
      return;
    }

    this.file = undefined;
    this.fileName = null;
    this.onChange(null);
    this.fileUploadControl.clear();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    if (value) {
      this.croppedImage = value?.url;
      this.file = value;
      this.fileName = value?.fileName;
    }
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.fileUploadControl.disable() : this.fileUploadControl.enable();
  }

  private dataURLtoFile(dataUrl: any, filename: any): any {
    let arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }
}
