<button
  [type]="typeButton"
  [disabled]="disabled"
  #btnLoading
  class="not-hover"
  [class.full-width]="fullWidth"
>
  <div class="wrapper">
    <span>{{ labelSave | translate }}</span>
    <span>
      {{ labelLoading | translate }}
      <i>.</i><i>.</i><i>.</i>
    </span>
    <span class="finish">
      {{ labelDone | translate }}
      <svg-icon src="check-box.svg"></svg-icon>
    </span>
  </div>
</button>
