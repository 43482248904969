import { ProfileMatch, TYPE_DESKTOP } from 'social-links';

const upworkMatches: ProfileMatch[] = [
  {
    match: '(https?://)?(www.)?upwork.com/freelancers/~({PROFILE_ID})/?', group: 3, type: TYPE_DESKTOP,
    pattern: 'https://upwork.com/freelancers/~{PROFILE_ID}'
  },
];

const techCrunchMatches: ProfileMatch[] = [
  {
    match: '(https?://)?(www.)?techcrunch.com/(.*)', group: 3, type: TYPE_DESKTOP,
    pattern: 'https://techcrunch.com'
  },
];

const clutchMatches: ProfileMatch[] = [
  {
    match: '(https?://)?(www.)?clutch.co/profile/({PROFILE_ID})', group: 3, type: TYPE_DESKTOP,
    pattern: 'https://clutch.co/profile/{PROFILE_ID}'
  },
];

const appleStoreMatches: ProfileMatch[] = [
  {
    match: '(https?://)?(www.)?apps.apple.com/[a-zA-Z]{2}/app/(.*)', group: 3, type: TYPE_DESKTOP,
    pattern: 'https://apps.apple.com/ua/app/'
  },
];

const googlePlayMatches: ProfileMatch[] = [
  {
    match: '(https?:\\/\\/)?(www.)?play.google.com\\/store\\/apps\\/details\\?(id(\\d+)|id=([\\w.]+))', group: 5, type: TYPE_DESKTOP,
    pattern: 'https://play.google.com/store/apps/details?id={PROFILE_ID}'
  },
];

const youtubeVideoMatches: ProfileMatch[] = [
  {
    match: '^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$', group: 2,
    pattern: 'https://youtube.com/watch'
  },
];

const linkedinCompanyMatches: ProfileMatch[] = [
  {
    match: '(https?://)?(www.)?linkedin.com/company/({PROFILE_ID})/?', group: 3, type: TYPE_DESKTOP,
    pattern: 'https://linkedin.com/company/{PROFILE_ID}'
  },
  {match: '({PROFILE_ID})', group: 1},
];

export {
  upworkMatches,
  techCrunchMatches,
  clutchMatches,
  appleStoreMatches,
  googlePlayMatches,
  youtubeVideoMatches,
  linkedinCompanyMatches,
}
