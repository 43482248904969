import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-dots',
  templateUrl: './loader-dots.component.html',
  styleUrls: ['./loader-dots.component.scss']
})
export class LoaderDotsComponent {
  constructor() {
  }
}
