import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-block-you-tube',
  templateUrl: './block-you-tube.component.html',
  styleUrls: ['./block-you-tube.component.scss']
})
export class BlockYouTubeComponent implements OnInit {
  @Input() id!: any;
  @Input() company!: any;

  public showVideo = false;

  private apiLoaded = false;
  private player: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
    if (!this.apiLoaded) {
      const tag = this.document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      this.document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

  public savePlayer(player: YT.PlayerEvent): void {
    this.player = player.target;
  }

  public playVideo(): void {
    this.showVideo = true;
    this.player.playVideo();
  }
}
