import { Component } from '@angular/core';

import { ModalComponent } from '../../modules/modal/components/modal.component';

@Component({
  selector: 'app-modal-company-not-verified',
  templateUrl: './modal-company-not-verified.component.html',
  styleUrls: ['./modal-company-not-verified.component.scss']
})
export class ModalCompanyNotVerifiedComponent extends ModalComponent<any, any> {
  constructor() {
    super()
  }
}
