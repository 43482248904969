import {
  ChangeDetectorRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Component,
  OnDestroy,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { fadeInRightOnEnterAnimation, fadeOutRightOnLeaveAnimation } from "angular-animations";
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { filter, of, Subject, takeUntil } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { delay } from "rxjs/operators";

import { INavigationInterface } from '../../../models';
import { languages, Roles } from '../../../config';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  animations: [
    fadeInRightOnEnterAnimation(),
    fadeOutRightOnLeaveAnimation()
  ],
})
export class NavigationMenuComponent implements OnInit, OnDestroy {
  @Input() navigation!: INavigationInterface[] | null;
  @Input() secondaryNav: INavigationInterface[] | null = [];
  @Input() user!: any;
  @Input() language!: string;
  @Input() set opened(value: boolean) {
    this.isOpen = value;
  }

  @Output() toggle = new EventEmitter();
  @Output() languageChange = new EventEmitter();

  @HostBinding('class.active') isOpen: boolean = false;

  public mobileBtnOpen = false;
  public disabledBtnToggle = false;
  public activeURL = this.router.url;
  public languagesList = languages;
  public languageSwitcher = this.formBuilder.control(null);
  public roles = Roles;

  private isBrowser = isPlatformBrowser(this.platformId);

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private formBuilder: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    private router: Router
  ) {
  }

  @HostListener('window:resize', ['$event.target'])
  onResize(event: any): void {
    if (event.innerWidth <= 992) {
      this.isOpen = false;
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe((event: RouterEvent | any) => {
        this.activeURL = event.url;

        if  (this.mobileBtnOpen && this.isOpen) {
          this.onOpenMenu();
        }
      });

    this.languageSwitcher.patchValue(this.language);
  }

  public onOpenMenu(): void {
    this.mobileBtnOpen = !this.mobileBtnOpen;
    this.isOpen = !this.isOpen;

    if (this.isBrowser) {
      this.document.body.classList.toggle('overflow-hidden');
      this.document.querySelector('.app-container__main')?.classList.toggle('blur');
    }
  }

  public onToggleMenu(): void {
    this.disabledBtnToggle = true;
    this.isOpen = !this.isOpen;
    this.toggle.emit(this.isOpen);

    of(false)
      .pipe(
        takeUntil(this.unsubscribe$),
        delay(450)
      )
      .subscribe(data => {
        this.disabledBtnToggle = data;
        this.ref.detectChanges();
      })
  }

  ngOnDestroy(): void {
    if (this.isOpen) {
      this.document.body.classList.remove('overflow-hidden');
      this.document.querySelector('.app-container__main')?.classList.remove('blur');
    }
  }
}
