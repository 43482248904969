import { Component, Input } from '@angular/core';
import { SwiperOptions } from 'swiper';
import {
  fadeInDownOnEnterAnimation,
  fadeInUpOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  fadeInOnEnterAnimation
} from 'angular-animations';

@Component({
  selector: 'app-registration-info',
  templateUrl: './registration-info.component.html',
  styleUrls: ['./registration-info.component.scss'],
  animations: [
    fadeInDownOnEnterAnimation(),
    fadeInUpOnEnterAnimation(),
    fadeInOnEnterAnimation({duration: 600}),
    fadeOutOnLeaveAnimation()
  ]
})
export class RegistrationInfoComponent {
  @Input() type?: string;
  @Input() items?: any[];

  public isHidden = true;

  public swiperItems: SwiperOptions = {
    loop: false,
    slidesPerView: 1,
    grabCursor: true,
    breakpoints: {
      1200: {
        spaceBetween: 10,
        slidesPerView: 4,
        grabCursor: true,
        pagination: false
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 5,
      },
      300: {
        slidesPerView: 1,
        spaceBetween: 5,
      }
    },
    pagination: {
      clickable: true,
    }
  };

  constructor() {
  }
}
