import { createAction, props } from '@ngrx/store';

import { IActionRequest, IActionRequestError, IActionRequestSuccess } from '../../../models';

const selectCompany = createAction('[Company] Select Company', props<{ slug: string }>());

const getCompanyProfile = createAction(
  '[Company] Get Company Profile Request',
  props<{ slug: string }>(),
);
const getCompanyProfileSuccess = createAction(
  '[Company] Get Company Profile Success',
  props<IActionRequestSuccess<{ profile: any }>>(),
);
const getCompanyProfileFailure = createAction(
  '[Company] Get Company Profile Failure',
  props<IActionRequestError>(),
);

const getCompany = createAction('[Company] Get Company Request', props<IActionRequest<{ slug: string }>>());
const getCompanySuccess = createAction('[Company] Get Company Success', props<IActionRequestSuccess<{ company: any }>>());
const getCompanyFailure = createAction('[Company] Get Company Failure', props<IActionRequestError>());

const getCompanyRecommendedTalents = createAction('[Company] Get Company Recommended Talents Request', props<IActionRequest<{ id: string }>>());
const getCompanyRecommendedTalentsSuccess = createAction('[Company] Get Company Recommended Talents Success', props<IActionRequestSuccess<{ recommendedTalents: any }>>());
const getCompanyRecommendedTalentsFailure = createAction('[Company] Get Company Recommended Talents Failure', props<IActionRequestError>());

const getCompanyLatestFeedbacks = createAction('[Company] Get Company Latest Feedbacks Request', props<IActionRequest<{ id: string }>>());
const getCompanyLatestFeedbacksSuccess = createAction('[Company] Get Company Latest Feedbacks Success', props<IActionRequestSuccess<{ latestFeedbacks: any }>>());
const getCompanyLatestFeedbacksFailure = createAction('[Company] Get Company Latest Feedbacks Failure', props<IActionRequestError>());

const patchGeneralInfo = createAction(
  '[Company] Patch General Info Request',
  props<IActionRequest<{ id: string, body: string, slug: string }>>()
);
const patchGeneralInfoSuccess = createAction(
  '[Company] Patch General Info Success',
  props<IActionRequestSuccess<{ profile: any, slug: string }>>(),
);
const patchGeneralInfoFailure = createAction(
  '[Company] Patch General Info Failure',
  props<IActionRequestError>(),
);

const patchAbout = createAction('[Company] Patch About Request', props<IActionRequest<{ id: string, body: string }>>());
const patchAboutSuccess = createAction('[Company] Patch About Success', props<IActionRequestSuccess<{ profile: any }>>());
const patchAboutFailure = createAction('[Company] Patch About Failure', props<IActionRequestError>());

const patchResults = createAction('[Company] Patch Results Request', props<IActionRequest<{ id: string, body: string }>>());
const patchResultsSuccess = createAction('[Company] Patch Results Success', props<IActionRequestSuccess<{ profile: any }>>());
const patchResultsFailure = createAction('[Company] Patch Results Failure', props<IActionRequestError>());

const patchClients = createAction('[Company] Patch Clients Request', props<IActionRequest<{ id: string, body: string }>>());
const patchClientsSuccess = createAction('[Company] Patch Clients Success', props<IActionRequestSuccess<{ profile: any }>>());
const patchClientsFailure = createAction('[Company] Patch Clients Failure', props<IActionRequestError>());

const patchVideos = createAction('[Company] Patch Videos Request', props<IActionRequest<{ id: string, body: string }>>());
const patchVideosSuccess = createAction('[Company] Patch Videos Success', props<IActionRequestSuccess<{ profile: any }>>());
const patchVideosFailure = createAction('[Company] Patch Videos Failure', props<IActionRequestError>());

const patchPhotos = createAction('[Company] Patch Photos Request', props<IActionRequest<{ id: string, body: string }>>());
const patchPhotosSuccess = createAction('[Company] Patch Photos Success', props<IActionRequestSuccess<{ profile: any }>>());
const patchPhotosFailure = createAction('[Company] Patch Photos Failure', props<IActionRequestError>());

const patchValues = createAction('[Company] Patch Values Request', props<IActionRequest<{ id: string, body: string }>>());
const patchValuesSuccess = createAction('[Company] Patch Values Success', props<IActionRequestSuccess<{ profile: any }>>());
const patchValuesFailure = createAction('[Company] Patch Values Failure', props<IActionRequestError>());

const patchResponsibilities = createAction(
  '[Company] Patch Responsibilities Request',
  props<IActionRequest<{ id: string, body: string }>>(),
);
const patchResponsibilitiesSuccess = createAction(
  '[Company] Patch Responsibilities Success',
  props<IActionRequestSuccess<{ profile: any }>>(),
);
const patchResponsibilitiesFailure = createAction(
  '[Company] Patch Responsibilities Failure',
  props<IActionRequestError>(),
);

const patchBenefits = createAction('[Company] Patch Benefits Request', props<IActionRequest<{ id: string, body: string }>>());
const patchBenefitsSuccess = createAction('[Company] Patch Benefits Success', props<IActionRequestSuccess<{ profile: any }>>());
const patchBenefitsFailure = createAction('[Company] Patch Benefits Failure', props<IActionRequestError>());

const patchTeam = createAction('[Company] Patch Team Request', props<IActionRequest<{ id: string, body: string }>>());
const patchTeamSuccess = createAction('[Company] Patch Team Success', props<IActionRequestSuccess<{ profile: any }>>());
const patchTeamFailure = createAction('[Company] Patch Team Failure', props<IActionRequestError>());

const createProduct = createAction('[Company] Create Product Request', props<IActionRequest<{ id: string, body: string }>>());
const createProductSuccess = createAction('[Company] Create Product Success', props<IActionRequestSuccess<{ products: any[] }>>());
const createProductFailure = createAction('[Company] Create Product Failure', props<IActionRequestError>());

const patchProduct = createAction(
  '[Company] Patch Product Request',
  props<IActionRequest<{ id: string, body: any }>>(),
);
const patchProductSuccess = createAction(
  '[Company] Patch Product Success',
  props<IActionRequestSuccess<{ id: string, product: any }>>(),
);
const patchProductFailure = createAction(
  '[Company] Patch Product Failure',
  props<IActionRequestError>(),
);

const deleteProduct = createAction('[Company] Delete Product Request', props<IActionRequest<{ id: string }>>());
const deleteProductSuccess = createAction('[Company] Delete Product Success', props<IActionRequestSuccess<{ id: string }>>());
const deleteProductFailure = createAction('[Company] Delete Product Failure', props<IActionRequestError>());

export const CompanyActions = {
  selectCompany,

  getCompanyProfile,
  getCompanyProfileSuccess,
  getCompanyProfileFailure,

  getCompany,
  getCompanySuccess,
  getCompanyFailure,

  getCompanyRecommendedTalents,
  getCompanyRecommendedTalentsSuccess,
  getCompanyRecommendedTalentsFailure,

  getCompanyLatestFeedbacks,
  getCompanyLatestFeedbacksSuccess,
  getCompanyLatestFeedbacksFailure,

  patchGeneralInfo,
  patchGeneralInfoSuccess,
  patchGeneralInfoFailure,

  patchAbout,
  patchAboutSuccess,
  patchAboutFailure,

  patchResults,
  patchResultsSuccess,
  patchResultsFailure,

  patchClients,
  patchClientsSuccess,
  patchClientsFailure,

  patchTeam,
  patchTeamSuccess,
  patchTeamFailure,

  patchVideos,
  patchVideosSuccess,
  patchVideosFailure,

  patchPhotos,
  patchPhotosSuccess,
  patchPhotosFailure,

  patchValues,
  patchValuesSuccess,
  patchValuesFailure,

  patchResponsibilities,
  patchResponsibilitiesSuccess,
  patchResponsibilitiesFailure,

  patchBenefits,
  patchBenefitsSuccess,
  patchBenefitsFailure,

  createProduct,
  createProductSuccess,
  createProductFailure,

  patchProduct,
  patchProductSuccess,
  patchProductFailure,

  deleteProduct,
  deleteProductSuccess,
  deleteProductFailure,
}
