import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { CompanyActions } from '../actions/company.actions';

export const companyFeatureKey = 'company';

export interface State extends EntityState<any> {
  profile: any;
  selectedCompanyId: string | null;
  recommendedTalents: any[];
  latestFeedbacks: any[];
}

export const adapter: EntityAdapter<any> = createEntityAdapter({
  selectId: (company: any) => company.slug,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  profile: null,
  selectedCompanyId: null,
  recommendedTalents: [],
  latestFeedbacks: [],
});

export const companyReducer = createReducer(
  initialState,

  on(CompanyActions.getCompanyProfileSuccess, (state, {profile}) => adapter.addOne(profile, state)),

  on(CompanyActions.getCompanySuccess, (state, {company}) => adapter.addOne(company, state)),

  on(CompanyActions.getCompanyRecommendedTalentsSuccess, (state, {recommendedTalents}) => ({...state, recommendedTalents})),

  on(CompanyActions.getCompanyLatestFeedbacksSuccess, (state, {latestFeedbacks}) => ({...state, latestFeedbacks})),

  on(CompanyActions.selectCompany, (state, {slug}) => ({...state, selectedCompanyId: slug})),

  on(
    CompanyActions.getCompanyProfileSuccess,
    CompanyActions.patchGeneralInfoSuccess,
    CompanyActions.patchAboutSuccess,
    CompanyActions.patchResultsSuccess,
    CompanyActions.patchClientsSuccess,
    CompanyActions.patchTeamSuccess,
    CompanyActions.patchVideosSuccess,
    CompanyActions.patchPhotosSuccess,
    CompanyActions.patchValuesSuccess,
    CompanyActions.patchResponsibilitiesSuccess,
    CompanyActions.patchBenefitsSuccess,
    (state, {profile}) => ({...state, profile})
  ),

  on(
    CompanyActions.getCompanyProfileSuccess,
    CompanyActions.patchGeneralInfoSuccess,
    CompanyActions.patchAboutSuccess,
    CompanyActions.patchResultsSuccess,
    CompanyActions.patchClientsSuccess,
    CompanyActions.patchTeamSuccess,
    CompanyActions.patchVideosSuccess,
    CompanyActions.patchPhotosSuccess,
    CompanyActions.patchValuesSuccess,
    CompanyActions.patchResponsibilitiesSuccess,
    CompanyActions.patchBenefitsSuccess,
    (state, {profile}) => adapter.updateOne({id: profile.slug, changes: profile}, state)
  ),

  on(
    CompanyActions.patchGeneralInfoSuccess,
    (state, {profile, slug}) => adapter.mapOne({
      id: slug,
      map: () => profile,
    }, state)
  ),

  // Products.
  on(CompanyActions.createProductSuccess, (state, {products}) => {
    const profile = {
      ...state.profile,
      products
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),

  on(CompanyActions.patchProductSuccess, (state, {id, product}) => {
    const productIndex = state.profile.products.findIndex((c: any) => c.id === id);
    const products = [...state.profile.products];
    products[productIndex] = product;

    const profile = {
      ...state.profile,
      products
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),

  on(CompanyActions.deleteProductSuccess, (state, {id}) => {
    const profile = {
      ...state.profile,
      products: [
        ...state.profile.products.filter((product: any) => product.id !== id),
      ]
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),
);

export const selectId = (state: State) => state.selectedCompanyId;
