<div class="grid gap-y-2.5 {{ className }}">
  <div class="talent-item">

    <div class="talent-item__name font-extrabold">
      <span class="text-silver">{{ title | translate }}</span>

      <div
        *ngIf="showBtnAccordion"
        class="arrow-btn bg-yellow dark:bg-alabaster w-full max-w-[25px]"
        [class.open]="isShow"
        (click)="toggleAccordion()"
      >
        <span class="arrow-btn__icon text-black"></span>
      </div>
    </div>

    <div class="talent-item__main">
      <p
        #descriptionText
        class="font-medium talent-item__content overflow-hidden quill-content"
        [class.is-shadow]="showBtnAccordion"
        [class.is-open]="showBtnAccordion && isShow"
        [innerHTML]="description | safe"
      ></p>
    </div>

  </div>
</div>
