<div class="talent-job-summary-list">
  <div class="talent-job-summary-list__main cursor-pointer" (click)="toggleRowItem()">
    <div class="talent-job-summary-list__name font-bold">{{ label | translate }}</div>
    <div class="talent-job-summary-list__counter bg-yellow text-black">{{ items.length }}</div>

    <div class="arrow-btn w-7 ml-auto" [class.open]="isOpen">
      <span class="arrow-btn__icon"></span>
    </div>
  </div>

  <div class="talent-job-summary-list__items overflow-hidden" [@openClose]="isOpenRow ? 'open' : 'closed'">
    <div class="py-7 xl:py-12 flex flex-col gap-5">
      <div
        class="talent-job-summary-list-items cursor-pointer"
        *ngFor="let item of items"
        [routerLink]="'/talent/' + talent?.slug + '/feedbacks/'"
      >
        <div class="font-normal">
          <span>{{ item?.job?.company?.name }}</span>
        </div>

        <div class="flex flex-col">
          <span>{{ vocabularies?.category?.terms?.[item?.job?.category]?.title }}</span>
          <span>{{ vocabularies?.position?.terms?.[item?.job?.position]?.title }}</span>
        </div>

        <div class="font-normal flex justify-self-end">
          <ng-container *ngIf="!item?.job.salaryHidden; else salaryHidden">
            {{ item?.job.salaryFrom }} - {{ item?.job.salaryTo }}$
          </ng-container>
          <ng-template #salaryHidden>
            —
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
