import { AbstractControl, ValidatorFn } from '@angular/forms';

export class DateValidators {
  static dateLessThan(fromField: string, toField: string, fieldForError: string): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const from = c.get(fromField)?.value;
      const to = c.get(toField)?.value;

      if ((from !== null && to !== null) && from > to) {
        if (fieldForError) {
          c.get(fieldForError)?.setErrors({invalidDateRange: true});
        }

        return fieldForError ? null : {invalidDateRange: true};
      }

      return null;
    };
  }
}
