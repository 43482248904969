import { createFeatureSelector, createSelector } from '@ngrx/store';

import { adapter, jobFeatureKey, selectId, State } from '../reducers/jobs.reducer';

export const selectJobState = createFeatureSelector<State>(
  jobFeatureKey
);

export const {
  selectIds,
  selectEntities: selectJobEntities,
  selectAll: selectAllJobs,
  selectTotal,
} = adapter.getSelectors(selectJobState);


export const selectSelectedJobId = createSelector(
  selectJobState,
  selectId
);

export const selectJobFlow = createSelector(
  selectJobState,
  (state: State) => state.flow,
);

export const selectJobFeedbacks = createSelector(
  selectJobState,
  (state: State) => state.feedbacks,
);

export const selectSelectedJob = createSelector(
  selectJobEntities,
  selectSelectedJobId,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

export const selectFeedback = (id: string) => createSelector(
  selectJobFeedbacks, (feedbacks: any[]) => feedbacks && feedbacks.find((f: any) => f.id === id),
);
