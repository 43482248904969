<ng-template #multipleOption let-item="item" let-label="label">
  {{item[label]}} <i class="font-normal text-sm">({{item.count}})</i>
</ng-template>

<div class="modal-form modal-form--small bg-black text-white dark:bg-white dark:text-black">
  <div class="modal-form__title flex flex-col">
    {{ 'search.modal.title' | translate }}

    <span
      class="font-medium text-sm"
      [innerHTML]="'search.modal.found' | translate: {count: foundItemCount}"
    ></span>
  </div>

  <form class="modal-form__main gap-y-8" [formGroup]="form" (submit)="submit()">
    <div
      *ngFor="let field of formStructure.fields"
      class="form-group w-full"
    >
      <label [for]="field.name" *ngIf="!!field.label">
        {{ field.label | translate }}
      </label>

      <app-multiple-select
        *ngIf="field.type === 'select-multiple'"
        [labelForId]="field.name"
        [color]="theme === 'light' ? 'black' : 'white'"
        [formControlName]="field.name"
        [items]="field | processSearchItems : vocabularies : aggregations"
        [bindValue]="field.bindValue || 'id'"
        [bindLabel]="field.bindLabel || 'title'"
        [optionTemplate]="multipleOption"
      ></app-multiple-select>

      <app-location-autocomplete
        *ngIf="field.type === 'location-autocomplete'"
        [labelForId]="field.name"
        [formControlName]="field.name"
        [color]="theme === 'light' ? 'black' : 'white'"
      ></app-location-autocomplete>

      <ng-select
        *ngIf="field.type === 'select'"
        appOpenSelect
        labelForId="select"
        class="select"
        [class.dark]="theme === 'light'"
        [formControlName]="field.name"
        [items]="field | processSearchItems : vocabularies : aggregations"
        [multiple]="false"
        [clearable]="false"
        [bindValue]="field.bindValue || 'id'"
        [bindLabel]="field.bindLabel || 'title'"
      >
        <ng-template ng-label-tmp let-item="item">
          {{item[field.bindLabel || 'title']}} <i class="font-normal text-sm">({{item.count}})</i>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item[field.bindLabel || 'title']}} <i class="font-normal text-sm">({{item.count}})</i>
        </ng-template>
      </ng-select>

      <app-checkbox-group
        *ngIf="field.type === 'checkbox'"
        [formControlName]="field.name"
        [bindValue]="field.bindValue || 'id'"
        [bindLabel]="field.bindLabel || 'title'"
        [items]="field | processSearchItems : vocabularies : aggregations"
        [emptyText]="'search.not_found_select' | translate"
        counterField="count"
      ></app-checkbox-group>

      <app-salary-field
        *ngIf="field.type === 'salary'"
        [formControlName]="field.name"
        [theme]="theme"
        [sharedOption]="field.sharedOption"
      ></app-salary-field>

      <div *ngIf="field.type === 'switch'">
        <app-switch
          [formControlName]="field.name"
          [themeColor]="theme === 'light' ? 'white' : 'black'"
        ></app-switch>
      </div>

      <div
        *ngIf="field.type === 'formArray'"
        class="flex flex-col gap-y-5"
        [formArrayName]="field.name"
      >
        <div
          *ngFor="let _ of getFormControl(field?.name)?.controls; let fieldIdx = index"
          [formGroupName]="fieldIdx"
          class="flex gap-x-3 "
          [ngClass]="{ 'with-icon items-center' : fieldIdx !== 0}"
        >
          <div class="flex flex-col gap-y-4 flex-grow">
            <ng-container *ngFor="let innerField of field.fields">
              <ng-select
                *ngIf="innerField.type === 'select'"
                appOpenSelect
                labelForId="select"
                class="select"
                [class.dark]="theme === 'light'"
                [items]="innerField | processSearchItems : vocabularies : aggregations"
                [formControlName]="innerField.name"
                [multiple]="innerField.multiple || false"
                [clearable]="innerField.clearable || false"
                [bindLabel]="innerField.bindLabel || 'title'"
                [bindValue]="innerField.bindValue || 'id'"
              ></ng-select>
            </ng-container>
          </div>

          <div class="flex circle-btn bg-alabaster text-black icon" *ngIf="fieldIdx !== 0">
            <svg-icon
              (click)="removeFormGroup(field.name, fieldIdx)"
              src="close.svg"
            ></svg-icon>
          </div>
        </div>

        <button type="button" class="self-end text-small underline" (click)="addFormGroup(field)">
          {{ 'btn.add_more' | translate }}
        </button>
      </div>
    </div>

    <div class="flex flex-col md:flex-row gap-3 justify-end">
      <button type="reset" class="big-btn big-btn--small bg-white text-black dark:bg-mine-shaft dark:text-white" (click)="close()">
        {{ 'btn.reset_form' | translate }}
      </button>
      <button type="submit" class="big-btn big-btn--small bg-white text-black dark:bg-mine-shaft dark:text-white">
        {{ 'btn.show_results' | translate }}
      </button>
    </div>
  </form>

  <button class="modal-form__close bg-white text-black dark:bg-mine-shaft dark:text-white" (click)="close()">
    <svg-icon src="close.svg"></svg-icon>
  </button>
</div>
