import { fadeInUpOnEnterAnimation, fadeOutDownOnLeaveAnimation } from 'angular-animations';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input, OnInit, TemplateRef, } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleSelectComponent),
      multi: true
    }
  ],
  animations: [
    fadeInUpOnEnterAnimation({duration: 404}),
    fadeOutDownOnLeaveAnimation({duration: 404})
  ]
})
export class MultipleSelectComponent implements OnInit, ControlValueAccessor {
  @Input() items!: any[];
  @Input() bindLabel: string = 'title';
  @Input() bindValue: string = 'id';
  @Input() labelForId?: string;
  @Input() closeOnSelect: boolean = true;
  @Input() color = 'white' // black, yellow
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() maxSelectedItems = 20;

  public selectFC = this.formBuilder.control([]);

  private onTouched = () => {
  };
  private onChange = (m: any) => {
  };

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.selectFC.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.onChange(value));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.selectFC.patchValue(value, {emitEvent: false, onlySelf: true});
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.selectFC.disable() : this.selectFC.enable();
  }

  public getTag(id: string): any {
    return this.items?.find(item => item?.[this.bindValue] === id);
  }

  public removeTag(tagId: string) {
    this.onTouched();

    if (this.selectFC.disabled) {
      return;
    }

    const newValue: any = this.selectFC.value?.filter(id => id !== tagId);
    this.selectFC.patchValue(newValue);
  }

  public onBlur(): void {
    this.onTouched();
  }
}
