import { localStorageSync } from 'ngrx-store-localstorage';
import { ActionReducer, MetaReducer } from '@ngrx/store';

import { environment } from '../../environments/environment';

export function emptyReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return reducer;
}

export function localStorageReducer(): MetaReducer {
  if (!environment.local) {
    return emptyReducer;
  }

  return localStorageSync({
    keys: [
      {core: ['language', 'useCookie', 'mainNavOpened']},
      {auth: ['user', 'userType', 'authorized']}
    ],
    rehydrate: true,
    checkStorageAvailability: true,
    syncCondition: () => true,
    storageKeySerializer: (key) => `${environment.projectName}_${key}`
  });
}
