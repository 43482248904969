import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { filter, Observable } from 'rxjs';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';
import { selectUser } from '../../auth/selectors/auth.selectors';
import { UserType } from '../../../config';

@Injectable({
  providedIn: 'root'
})
export class TalentCanActivateGuard implements CanActivate {
  constructor(
    private guardHelperService: GuardHelperService,
    private router: Router,
    private store: Store
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardHelperService.getTalent(route.params['talentSlug'])
      .pipe(
        withLatestFrom(this.store.pipe(select(selectUser))),
        filter(([talent, user]) => !!talent && !!user),
        map(([talent, user]) => {
          if (!user) {
            return false;
          }

          const { type } = user;

          if (user.type === UserType.TALENT && user.id !== talent.id) {
            this.router.navigate([`/${type}/${user[type].slug}/profile`])
            return false;
          }

          if (!talent.profileCompletedAt) {
            this.router.navigate([`/${type}/${user[type].slug}/profile`])
            return false;
          }

          return true;
        }),
      );
  }
}
