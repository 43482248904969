import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCounter]'
})
export class CounterDirective implements AfterViewInit {
  public el: any;
  public max!: number;

  constructor(
    private readonly renderer: Renderer2,
    private elRef: ElementRef,
  ) {
  }

  @HostListener('input', ['$event'])
  public onEvent() {
    let valueToTransform = this.elRef.nativeElement.value.length;
    this.counter(this.el, valueToTransform, this.max);
  }

  ngAfterViewInit(): void {
    this.max = this.elRef.nativeElement.getAttribute('maxlength');

    if (this.max) {
      this.el = this.renderer.createElement('span');
      this.el.classList.add('textarea-counter');

      this.elRef.nativeElement?.parentElement?.appendChild(this.el);
      this.counter(this.el, this.elRef?.nativeElement?.value?.length, this.max);
    }
  }

  private counter(element: any, currentValue: number, length: number): void {
    element.innerText = `${currentValue}/${length}`
  }
}
