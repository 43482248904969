<app-component
  [user]="user$ | async"
  [language]="language$ | async"
  [mainNav]="mainNav$ | async"
  [mainNavOpened]="mainNavOpened$ | async"
  [secondaryNav]="secondaryNav$ | async"
  [navigationSettings]="$any(navigationSettings$ | async)"
  (languageChange)="languageChange($event)"
  (toggleMainNav)="toggleMainNav($event)"
></app-component>
