import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { JobsActions } from '../actions/job.actions';

export const jobFeatureKey = 'job';

export interface State extends EntityState<any> {
  selectedJobId: string | null;
  feedbacks: any[];
  flow: any[];
}

export const adapter: EntityAdapter<any> = createEntityAdapter({
  selectId: (job: any) => job.slug,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedJobId: null,
  feedbacks: [],
  flow: [],
});

export const jobReducer = createReducer(
  initialState,

  on(
    JobsActions.createJobSuccess,
    JobsActions.getJobSuccess,
    (state, {job}) => adapter.addOne(job, {...state})
  ),

  on(JobsActions.deleteJobSuccess, (state, {slug}) => adapter.removeOne(slug, {...state})),

  on(JobsActions.getJobsSuccess, (state, {jobs}) => adapter.upsertMany(jobs, {...state})),

  on(
    JobsActions.openJobSuccess,
    (state, {job, slug}) => adapter.mapOne({
      id: slug,
      map: () => job,
    }, state)
  ),

  on(JobsActions.feedbackToJobSuccess, (state, {feedback}) =>
    adapter.updateOne({
      id: feedback?.job?.slug,
      changes: {
        appliedAt: new Date().toISOString(),
        isApplied: true,
      }
    }, state)
  ),

  on(
    JobsActions.closeJobSuccess,
    JobsActions.getJobSuccess, (state, {job}) =>
      adapter.updateOne({id: job.slug, changes: job}, state),
  ),

  on(
    JobsActions.patchGeneralInfoSuccess,
    JobsActions.patchAboutSuccess,
    JobsActions.patchResultsSuccess,
    JobsActions.patchSalarySuccess,
    JobsActions.patchRequirementsSuccess,
    JobsActions.patchConditionsSuccess,
    (state, {job}) => adapter.updateOne({id: job.slug, changes: job}, state)
  ),

  on(JobsActions.getJobFeedbacksSuccess, (state, {feedbacks, flow}) => ({...state, feedbacks, flow})),

  on(JobsActions.updateFeedbackStepSuccess, (state, {id, flowStep}) => {
    const index = state.feedbacks.findIndex(f => f.id === id);
    const feedback = state.feedbacks[index];
    const feedbacks = [...state.feedbacks];
    feedbacks[index] = {...feedback, flowStep};

    return {...state, feedbacks};
  }),

  on(JobsActions.createFeedbackCommentSuccess, (state, {id, comment}) => {
    const index = state.feedbacks.findIndex(f => f.id === id);
    const feedback = state.feedbacks[index];
    const newFeedback = {
      ...feedback,
      comments: [
        comment,
        ...feedback.comments,
      ]
    };
    const newFeedbacks = [...state.feedbacks];
    newFeedbacks[index] = newFeedback;

    return {...state, feedbacks: newFeedbacks};
  }),

  on(JobsActions.updateFeedbackCommentSuccess, (state, {id, feedbackId, comment}) => {
    const feedbackIndex = state.feedbacks.findIndex(f => f.id === feedbackId);
    const feedback = state.feedbacks[feedbackIndex];
    const feedbacks = [...state.feedbacks];
    const commentIndex = feedback.comments.findIndex((c: any) => c.id === id);
    const comments = [...feedback.comments];

    comments[commentIndex] = comment;
    feedbacks[feedbackIndex] = {
      ...feedback,
      comments,
    };

    return {
      ...state,
      feedbacks,
    };
  }),

  on(JobsActions.deleteFeedbackCommentSuccess, (state, {id, feedbackId}) => {
    const feedbackIndex = state.feedbacks.findIndex(f => f.id === feedbackId);
    const feedback = state.feedbacks[feedbackIndex];
    const feedbacks = [...state.feedbacks];
    feedbacks[feedbackIndex] = {
      ...feedback,
      comments: feedback.comments.filter((comment: any) => comment.id !== id),
    };

    return {
      ...state,
      feedbacks,
    };
  }),

  on(JobsActions.selectJob, (state, {slug}) => ({...state, selectedJobId: slug})),
);

export const selectId = (state: State) => state.selectedJobId;
