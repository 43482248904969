import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appUniqueBy'
})
export class UniqueByPipe implements PipeTransform {
  constructor() {
  }

  transform(value: any[], propertyName: string, byValue: any[]): any[] {
    const unique = new Set();

    value?.forEach(x => unique.add(x));

    return byValue
      ? value?.filter(x => !byValue.includes(x[propertyName]))
      : Array.from(unique);
  }
}
