import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { RouteWithAnimationComponent } from '../../../../shared/components/abstract/route-with-animation.component';
import { JobsActions } from '../../actions/job.actions';

@Component({
  selector: 'app-jobs-list-page',
  templateUrl: './jobs-list-page.component.html',
  styleUrls: ['./jobs-list-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsListPageComponent extends RouteWithAnimationComponent implements OnInit {
  public activeTab = 'talent';

  get activatedTab() {
    return this.tabGroup?.nativeElement?.querySelector('.active');
  }

  get styles() {
    return {'left.px': this.activatedTab?.offsetLeft || 0, 'width.px': this.activatedTab?.clientWidth || 0}
  }

  @ViewChild('tabGroup') tabGroup!: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    const { companySlug: slug }: any = this.activatedRoute.snapshot?.parent?.parent?.params;

    if (slug) {
      this.store.dispatch(JobsActions.getJobs({slug}));
    }
  }

  public toggleTab(event: any, name: string): void {
    this.activeTab = name;
  }
}
