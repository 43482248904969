import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { filter, startWith, Subject, takeUntil } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { BreadcrumbsService } from '../../services/breadcrumbs.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  public breadcrumbs$ = this.breadcrumbsService.breadcrumbs$;

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    private router: Router,
    private breadcrumbsService: BreadcrumbsService
  ) {
  }

  ngOnInit() {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event instanceof NavigationEnd),
        startWith(null)
      )
      .subscribe(() => this.breadcrumbsService.build());
  }
}
