import { createFeatureSelector, createSelector } from '@ngrx/store';

import { authFeatureKey, State } from '../reducers/auth.reducers';

export const selectAuthState = createFeatureSelector<State>(
  authFeatureKey
);

export const selectUser = createSelector(selectAuthState, (state: any) => state?.user);

export const selectUserType = createSelector(selectAuthState, (state: any) => state?.userType);
