import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { asyncScheduler } from 'rxjs';

@Component({
  selector: 'app-button-loading',
  templateUrl: './button-loading.component.html',
  styleUrls: ['./button-loading.component.scss']
})
export class ButtonLoadingComponent implements OnChanges {
  @Input() typeButton = 'button';
  @Input() loading: boolean | null = false;
  @Input() disabled = false;
  @Input() labelSave = 'btn.save';
  @Input() labelLoading = 'btn.loading';
  @Input() labelDone = 'btn.done';
  @Input() fullWidth = false;

  @ViewChild('btnLoading') btnLoading: any;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const btn = this.btnLoading?.nativeElement;

    if (changes['loading']?.currentValue && !changes['loading']?.previousValue) {
      btn.classList.add('animated', 'active', 'loading', 'disabled');
    }

    if (!changes['loading']?.currentValue && changes['loading']?.previousValue) {
      btn.classList?.add('done');
      btn.classList?.remove('loading');

      asyncScheduler.schedule(() => {
        btn.classList?.remove('animated', 'active', 'done', 'disabled');
      }, 2000);
    }
  }
}
