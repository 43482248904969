import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, tap } from 'rxjs';

import { CustomModalComponent } from '../../../shared/components/custom-modal/custom-modal.component';
import { ModalService } from '../../../shared/modules/modal/services/modal.service';
import { AuthActions } from '../../auth/actions/auth.actions';
import { TalentService } from '../services/talent.service';
import { TalentActions } from '../actions/talent.actions';
import { ModalType } from '../../../config';

@Injectable()
export class TalentEffects {
  constructor(
    private translateService: TranslateService,
    private talentService: TalentService,
    private modalService: ModalService,
    private actions$: Actions,
    private router: Router,
  ) {
  }

  loadTalentRecommendedJobsAndLatestFeedbacks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.getTalentProfileSuccess),
      switchMap(({ profile }) => [
        TalentActions.getTalentRecommendedJobs({ id: profile.id }),
        TalentActions.getTalentFeedbacks({ id: profile.id }),
      ])
    )
  );

  getTalentProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.getTalentProfile),
      exhaustMap(({slug}) =>
        this.talentService.getTalent(slug).pipe(
          map(profile => TalentActions.getTalentProfileSuccess({profile})),
          catchError(({error}) => of(TalentActions.getTalentProfileFailure({error})))
        )
      )
    )
  );

  getTalent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.getTalent),
      exhaustMap(({slug}) =>
        this.talentService.getTalent(slug).pipe(
          map(talent => TalentActions.getTalentSuccess({talent})),
          catchError(({error}) => of(TalentActions.getTalentFailure({error, silent: true})))
        )
      )
    )
  );

  getTalentRecommendedJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.getTalentRecommendedJobs),
      exhaustMap(({id}) =>
        this.talentService.getTalentRecommendedJobs(id).pipe(
          map(recommendedJobs => TalentActions.getTalentRecommendedJobsSuccess({recommendedJobs})),
          catchError(({error}) => of(TalentActions.getTalentRecommendedJobsFailure({error})))
        )
      )
    )
  );

  getTalentLatestFeedbacks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.getTalentFeedbacks),
      exhaustMap(({id}) =>
        this.talentService.getTalentFeedbacks(id).pipe(
          map(feedbacks => TalentActions.getTalentFeedbacksSuccess({feedbacks})),
          catchError(({error}) => of(TalentActions.getTalentFeedbacksFailure({error})))
        )
      )
    )
  );

  patchPersonalInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.patchPersonalInfo),
      exhaustMap(({id, body, slug}) =>
        this.talentService.patchPersonalInfo(id, body).pipe(
          switchMap(profile => [
            TalentActions.patchPersonalInfoSuccess({profile, slug}),
            AuthActions.updateTalentUser({profile})
          ]),
          catchError(({error}) => of(TalentActions.patchPersonalInfoFailure({error})))
        )
      )
    )
  );

  patchPersonalInfoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.patchPersonalInfoSuccess),
      tap(({profile}) => {
        this.router.navigate([`/talent/${profile.slug}/profile`]);
      })
    ), { dispatch: false }
  );

  patchLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.patchLanguages),
      exhaustMap(({id, body}) =>
        this.talentService.patchLanguages(id, body).pipe(
          map(profile => TalentActions.patchLanguagesSuccess({profile})),
          catchError(({error}) => of(TalentActions.patchLanguagesFailure({error})))
        )
      )
    )
  );

  patchExperience$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.patchExperience),
      exhaustMap(({id, body}) =>
        this.talentService.patchExperience(id, body).pipe(
          map(profile => TalentActions.patchExperienceSuccess({profile})),
          catchError(({error}) => of(TalentActions.patchExperienceFailure({error})))
        )
      )
    )
  );

  patchSkills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.patchSkills),
      exhaustMap(({id, body}) =>
        this.talentService.patchSkills(id, body).pipe(
          map(profile => TalentActions.patchSkillsSuccess({profile})),
          catchError(({error}) => of(TalentActions.patchSkillsFailure({error})))
        )
      )
    )
  );

  patchAdditionalInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.patchAdditionalInfo),
      exhaustMap(({id, body}) =>
        this.talentService.patchAdditionalInfo(id, body).pipe(
          map(profile => TalentActions.patchAdditionalInfoSuccess({profile})),
          catchError(({error}) => of(TalentActions.patchAdditionalInfoFailure({error})))
        )
      )
    )
  );

  createWorkExp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.createWorkExp),
      exhaustMap(({id, body}) =>
        this.talentService.createWorkExp(id, body).pipe(
          map(workExperiences => TalentActions.createWorkExpSuccess({workExperiences})),
          catchError(({error}) => of(TalentActions.createWorkExpFailure({error})))
        )
      )
    )
  );

  patchWorkExp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.patchWorkExp),
      exhaustMap(({id, body}) =>
        this.talentService.patchWorkExp(id, body).pipe(
          map(workExp => TalentActions.patchWorkExpSuccess({id, workExp})),
          catchError(({error}) => of(TalentActions.patchWorkExpFailure({error})))
        )
      )
    )
  );

  deleteWorkExp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.deleteWorkExp),
      exhaustMap(({id}) =>
        this.talentService.deleteWorkExp(id).pipe(
          map(() => TalentActions.deleteWorkExpSuccess({id})),
          catchError(({error}) => of(TalentActions.deleteWorkExpFailure({error})))
        )
      )
    )
  );

  createEducation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.createEducation),
      exhaustMap(({id, body}) =>
        this.talentService.createEducation(id, body).pipe(
          map(educations => TalentActions.createEducationSuccess({educations})),
          catchError(({error}) => of(TalentActions.createEducationFailure({error})))
        )
      )
    )
  );

  patchEducation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.patchEducation),
      exhaustMap(({id, body}) =>
        this.talentService.patchEducation(id, body).pipe(
          map(education => TalentActions.patchEducationSuccess({id, education})),
          catchError(({error}) => of(TalentActions.patchEducationFailure({error})))
        )
      )
    )
  );

  deleteEducation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.deleteEducation),
      exhaustMap(({id}) =>
        this.talentService.deleteEducation(id).pipe(
          map(() => TalentActions.deleteEducationSuccess({id})),
          catchError(({error}) => of(TalentActions.deleteEducationFailure({error})))
        )
      )
    )
  );

  createCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.createCourse),
      exhaustMap(({id, body}) =>
        this.talentService.createCourse(id, body).pipe(
          map(courses => TalentActions.createCourseSuccess({courses})),
          catchError(({error}) => of(TalentActions.createCourseFailure({error})))
        )
      )
    )
  );

  patchCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.patchCourse),
      exhaustMap(({id, body}) =>
        this.talentService.patchCourse(id, body).pipe(
          map(course => TalentActions.patchCourseSuccess({id, course})),
          catchError(({error}) => of(TalentActions.patchCourseFailure({error})))
        )
      )
    )
  );

  deleteCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.deleteCourse),
      exhaustMap(({id}) =>
        this.talentService.deleteCourse(id).pipe(
          map(() => TalentActions.deleteCourseSuccess({id})),
          catchError(({error}) => of(TalentActions.deleteCourseFailure({error})))
        )
      )
    )
  );

  getTalentProfileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.getTalentProfileFailure),
      map(() => AuthActions.logOutSuccess()),
    )
  );

  updateTalentSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.updateTalentSettings),
      exhaustMap(({body}) =>
        this.talentService.updateTalentSettings(body).pipe(
          switchMap(profile => [
            TalentActions.updateTalentSettingsSuccess({profile}),
            AuthActions.updateTalentUser({profile})
          ]),
          catchError(({error}) => of(TalentActions.updateTalentSettingsFailure({error})))
        )
      )
    )
  );

  updateTalentSettingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TalentActions.updateTalentSettingsSuccess),
      tap(({profile}) => {
        this.router.navigate([`/talent/${profile.slug}/settings/account`]);
      }),
      switchMap(() => {
        return this.modalService.addModal(
          CustomModalComponent,
          {
            type: ModalType.SUCCESS,
            message: this.translateService.instant('modal.settings_updated')
          },
          {autoClose: 5000}
        );
      }),
    ), { dispatch: false }
  );
}
