<div
  class="short-filter-item shadow-cod-gray-two dark:shadow-alabaster"
  [class.active]="opened"
  [class.is-value]="selectFC.value?.length"
  (click)="opened = !opened"
>
  <span *ngIf="title">
    {{ title | translate }}
  </span>

  <div class="counter-filter dark:shadow-alabaster ring-yellow">
    <span class="text-white dark:text-black">
      {{ selectFC.value?.length ? selectFC.value?.length : 0 }}
    </span>
  </div>
</div>

<div class="short-filter-content w-[300px] text-black">
  <div
    *ngIf="opened"
    @fadeInUpOnEnter
    @fadeOutDownOnLeave
  >
    <app-smooth-height
      class="filter-item-results bg-white"
      [class.is-empty]="!selectFC.value?.length"
      [trigger]="selectFC.value"
    >
      <div class="flex flex-col gap-y-1.5">
        <div class="filter-item-results__items flex flex-wrap gap-2 items-start p-2">
          <span
            *ngFor="let id of selectFC.value"
            (click)="removeTag($event.currentTarget, id)"
            class="filter-item-results__item flex gap-2 py-3 px-5 text-small bg-alabaster cursor-pointer rounded-full"
            [class.disabled]="selectFC.disabled"
            @fadeOutOnLeave
          >
            {{getTag(id)?.[bindLabel]}}
            <svg-icon src="close.svg" class="flex w-2.5"></svg-icon>
          </span>
        </div>

        <button
          class="text-black underline underline-offset-1 text-small self-center"
          (click)="removeAll()"
          [class.opacity-0]="!selectFC.value?.length"
        >
          {{ 'btn.clear_all' | translate }}
        </button>
      </div>
    </app-smooth-height>
  </div>

  <div
    class="filter-items bg-white"
    *ngIf="opened"
    @fadeInUpOnEnter
    @fadeOutDownOnLeave
  >
    <ng-select
      class="filter-select"
      notFoundText="{{ 'search.not_found_select' | translate }}"
      [searchable]="true"
      [isOpen]="true"
      [clearable]="false"
      [items]="items"
      [multiple]="true"
      [formControl]="selectFC"
      [bindLabel]="bindLabel"
      [bindValue]="bindValue"
    >
      <ng-template ng-label-tmp></ng-template>

      <ng-template ng-option-tmp let-item="item">
        {{item[bindLabel]}} <i class="font-normal text-sm">({{item.count}})</i>
      </ng-template>
    </ng-select>
  </div>
</div>
