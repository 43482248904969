import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {
  constructor(
    private http: HttpClient
  ) { }


  public locationAutocomplete(params: any): Observable<any> {
    return this.http.get(`${environment.api}/locations/autocomplete`, {params})
  }
}
