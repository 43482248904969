import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { filter, Observable } from 'rxjs';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';
import { selectUser } from '../../auth/selectors/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class CompanyCanActivateGuard implements CanActivate {
  constructor(
    private guardHelperService: GuardHelperService,
    private router: Router,
    private store: Store
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardHelperService.getCompany(route.params['companySlug'])
      .pipe(
        withLatestFrom(this.store.pipe(select(selectUser))),
        filter(([company]) => !!company),
        map(([company, user]) => {
          if (user?.company?.id !== company?.id && !company.verified) {
            const {type} = user;

            this.router.navigate([`/${type}/${user[type].slug}/profile`])
            return false;
          }

          return true;
        }),
      );
  }
}
