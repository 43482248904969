import { FileUploadControl, FileUploadValidators } from "@iplab/ngx-file-upload";
import { FormBuilder, Validators } from "@angular/forms";
import { Component } from '@angular/core';

import { ModalComponent } from "../../modules/modal/components/modal.component";

@Component({
  selector: 'app-modal-apply-job',
  templateUrl: './modal-apply-job.component.html',
  styleUrls: ['./modal-apply-job.component.scss']
})
export class ModalApplyJobComponent extends ModalComponent<any, any> {
  public accept = ['application/pdf'];
  public job: any;
  public vocabularies: any;

  public form = this.formBuilder.group({
    cv: [null, [Validators.required]],
    coverLetter: [null],
  });

  public readonly control = new FileUploadControl(
    {
      listVisible: true,
      accept: this.accept,
      discardInvalid: true,
      multiple: false
    },
    [
      FileUploadValidators.accept(this.accept),
      FileUploadValidators.filesLimit(1),
      FileUploadValidators.sizeLimit(1000000)
    ]
  );

  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
  }

  public submit(): void {
    if (this.form.valid) {
      this.result = {
        ...this.form.value,
        cv: this.form.value.cv?.[0],
      };
      this.close();
    }
  }
}
