import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, finalize, of, Subject, takeUntil } from 'rxjs';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AutocompleteService } from '../../services/autocomplete.service';
import { CoreActions } from '../../../core/actions/core.actions';

@Component({
  selector: 'app-location-autocomplete',
  templateUrl: './location-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationAutocompleteComponent),
      multi: true
    }
  ],
})
export class LocationAutocompleteComponent implements OnInit, ControlValueAccessor {
  @Input() color = 'white' // black, yellow.
  @Input() labelForId?: string;
  @Input() types?: string;
  @Output() loadComplete = new EventEmitter();

  public minLength = 2;
  public locations: any[] = [];
  public locationsLoading = false;
  public locationInput$ = new Subject<string>();
  public autocompleteFC = this.formBuilder.control([]);

  private onTouched = () => {
  };
  private onChange = (m: any) => {
  };

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    private autocompleteService: AutocompleteService,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.autocompleteFC.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.onChange(value));

    this.locationInput$.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged(),
      debounceTime(700),
      filter(address => !!address && address.length > 1),
    ).subscribe(address => {
      this.locationsLoading = true;
      this.store.dispatch(CoreActions.apiCall());

      const params: {
        address: string;
        types?: string;
      } = { address };

      if (this.types) {
        params.types = this.types;
      }

      this.autocompleteService.locationAutocomplete(params).pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => {
          this.locationsLoading = false;
          this.loadComplete.emit();
        }),
        catchError(({error}) => of(CoreActions.apiCallFailure({error, silent: true}))),
      ).subscribe(({addresses}) => {
        this.store.dispatch(CoreActions.apiCallSuccess());
        this.locations = addresses;
        this.cdRef.markForCheck();
      })
    })
  }

  public clearAutocomplete(): void {
    this.locationInput$.next('');
    this.locations = [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.autocompleteFC.disable() : this.autocompleteFC.enable();
  }

  writeValue(value: any): void {
    this.autocompleteFC.patchValue(value, {emitEvent: false, onlySelf: true});
  }

  public onBlur(): void {
    this.onTouched();
    this.onChange(this.autocompleteFC.value);
  }
}
