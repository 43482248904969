import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as format from 'string-template';
import { Observable } from 'rxjs';

import { TokenResolver } from '../../../shared/modules/breadcrumbs/types/token-resolver.interface';
import { selectVocabularies } from '../../../core/selectors/core.selectors';
import { selectSelectedJob } from '../selectors/jobs.selectors';

@Injectable({
  providedIn: 'root'
})
export class JobTokenResolver implements TokenResolver {

  static readonly JOB_NAME = 'JOB_NAME';

  constructor(
    private store: Store,
  ) {
  }

  public resolve(text$: Observable<string>): Observable<string> {
    return text$.pipe(
      switchMap(text => this.store.pipe(
        select(selectSelectedJob),
        withLatestFrom(this.store.pipe(select(selectVocabularies))),
        map(([job, vocabularies]) => {
          return format(
            text,
            {[JobTokenResolver.JOB_NAME]: vocabularies?.position?.terms[job?.position]?.title}
          );
        }),
      ))
    );
  }
}
