<div class="flex flex-col gap-y-3" [formGroup]="form">
  <div class="flex gap-4 items-center">
    <div class="relative">
      <input
        id="salary"
        type="number"
        min="0"
        step="100"
        [ngClass]="{ 'black':  theme === 'light', 'white': theme !== 'light'}"
        formControlName="salaryFrom"
      >
    </div>
    <span class="h-px bg-white dark:bg-black w-4 lg:w-7 flex-none"></span>
    <div class="relative">
      <input
        type="number"
        min="0"
        step="100"
        [ngClass]="{ 'black':  theme === 'light', 'white': theme !== 'light'}"
        formControlName="salaryTo"
      >
    </div>
  </div>

  <div class="form-group">
    <label>
      {{ 'search.modal.options' | translate }}
    </label>

    <app-switch
      formControlName="sharedOption"
      [themeColor]="theme === 'light' ? 'white' : 'black'"
    ></app-switch>
  </div>
</div>
