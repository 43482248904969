import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';
import { selectUser } from '../../auth/selectors/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class TalentProfileCanActivateGuard implements CanActivate {
  constructor(
    private guardHelperService: GuardHelperService,
    private store: Store,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardHelperService.getTalentProfile(route.parent?.params['talentSlug'])
      .pipe(
        withLatestFrom(this.store.pipe(select(selectUser))),
        map(([talent, user]) => {
          const { type, id } = user;

          if (id !== talent.id) {
            this.router.navigate([`/${type}/${user[type].slug}/profile`])
            return false;
          }

          return true;
        }),
      );
  }
}
