import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, interval } from 'rxjs';
import { map, delayWhen } from 'rxjs/operators';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit, OnChanges {
  @Input() percents: number = 0;
  @Input() autoUpdate: boolean = false;
  @Input() delay: number = 1000;

  private percentsSubject = new BehaviorSubject(0);
  public percentsValue$ = this.percentsSubject.asObservable()
    .pipe(
      distinctUntilChanged(),
      delayWhen(() => this.autoUpdate ? interval(this.delay) : interval(150)),
      map(val => `--progress: ${val}%`),
    );

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['percents'] && !changes['percents'].firstChange && this.autoUpdate) {
      this.refresh();
    }
  }

  public refresh(): void {
    this.percentsSubject.next(this.percents);
  }
}
