import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

import { ModalComponent } from '../../modules/modal/components/modal.component';

@Component({
  selector: 'app-modal-cropper',
  templateUrl: './modal-cropper.component.html'
})
export class ModalCropperComponent extends ModalComponent<any, any> {
  public transform: ImageTransform = {};
  public canvasRotation = 0;
  public rotation = 0;
  public scale = 1;
  public isBrowser = isPlatformBrowser(this.platformId);
  public croppedImage: any = '';
  public imageFile!: any;

  override optionsOverride: any = {
    autoClose: false,
    closeOnEscape: false,
    closeOnClickOutside: false,
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Platform,
  ) {
    super();
  }

  public zoomOut(): void {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  public zoomIn(): void {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  public resetImage(resetTransform = true): void {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;

    if (resetTransform) {
      this.transform = {};
    }
  }

  public saveImage(): void {
    this.result = this.croppedImage;
    this.close();
  }
}
