<label class="wrapper-checkbox not-required" [class.disabled]="disabled">
  <input
    type="checkbox"
    [disabled]="disabled"
    [checked]="isChecked"
    (blur)="onTouched()"
    (change)="onChanged($event)"
  >
  <span class="element">
    <svg-icon class="flex" src="check-box.svg" [ngStyle]="{ 'width.%': 80, 'height.%': 80 }"></svg-icon>
  </span>
  <span [innerHTML]="label | translate" [class]="className"></span>

  <i
    *ngIf="counter !== undefined && counter !== null"
    class="ml-0.5 font-normal"
  >
    ({{counter}})
  </i>
</label>
