import { HttpClient } from '@angular/common/http';
import { serialize } from 'object-to-formdata';
import { Injectable } from '@angular/core';
import { delay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(
    private http: HttpClient
  ) {
  }

  public getCompany(slug: string): Observable<any> {
    return this.http.get(`${environment.api}/company/${slug}`);
  }

  public checkCompanyName(name: string): Observable<any> {
    return this.http.get(`${environment.api}/company/is-name-available/${name}`);
  }

  public patchGeneralInfo(id: string, body: any): Observable<any> {
    const serializeOptions = {indices: true, dotsForObjectNotation: false};

    return this.http.patch(`${environment.api}/company/${id}/general-info`, serialize(body, serializeOptions));
  }

  public patchAbout(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/company/${id}/about`, body);
  }

  public patchResults(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/company/${id}/results`, body);
  }

  public patchClients(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/company/${id}/clients`, body);
  }

  public patchTeam(id: string, body: any): Observable<any> {
    const serializeOptions = {indices: true, dotsForObjectNotation: false};

    return this.http.patch(`${environment.api}/company/${id}/team`, serialize(body, serializeOptions));
  }

  public patchVideos(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/company/${id}/videos`, body);
  }

  public patchPhotos(id: string, body: any): Observable<any> {
    const serializeOptions = {
      indices: true,
      dotsForObjectNotation: false,
      noFilesWithArrayNotation: true,
    };

    return this.http.patch(`${environment.api}/company/${id}/photos`, serialize(body, serializeOptions));
  }

  public patchValues(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/company/${id}/values`, body);
  }

  public patchResponsibilities(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/company/${id}/responsibilities`, body);
  }

  public patchBenefits(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/company/${id}/benefits`, body);
  }

  // Products.
  public createProduct(id: string, body: any): Observable<any> {
    const serializeOptions = {indices: true, dotsForObjectNotation: false};

    return this.http.post(`${environment.api}/company/${id}/product`, serialize(body, serializeOptions)).pipe(
      delay(1000) // for better UI animation.
    );
  }

  public patchProduct(id: string, body: any): Observable<any> {
    const serializeOptions = {indices: true, dotsForObjectNotation: false};

    return this.http.patch(`${environment.api}/company/product/${id}`, serialize(body, serializeOptions));
  }

  public deleteProduct(id: string): Observable<any> {
    return this.http.delete(`${environment.api}/company/product/${id}`);
  }

  public getCompanyRecommendedTalents(id: string): Observable<any> {
    return this.http.get(`${environment.api}/company/${id}/recommended-talents`);
  }

  public getCompanyLatestFeedbacks(id: string): Observable<any> {
    return this.http.get(`${environment.api}/company/${id}/latest-job-feedbacks`);
  }
}
