import { filter, first, Observable, switchMap, tap, of } from 'rxjs';
import { TransferState } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import {
  selectCompanyEntities,
  selectCompanyProfile,
  selectSelectedCompany
} from '../../modules/company/selectors/company.selectors';
import {
  selectSelectedTalent,
  selectTalentEntities,
  selectTalentProfile
} from '../../modules/talent/selectors/talent.selectors';
import { selectJobEntities, selectSelectedJob } from '../../modules/jobs/selectors/jobs.selectors';
import { CompanyActions } from '../../modules/company/actions/company.actions';
import { TalentActions } from '../../modules/talent/actions/talent.actions';
import { selectUser } from '../../modules/auth/selectors/auth.selectors';
import { AuthService } from '../../modules/auth/services/auth.service';
import { AuthActions } from '../../modules/auth/actions/auth.actions';
import { JobsActions } from '../../modules/jobs/actions/job.actions';
import { selectLoading } from '../../selectors/loader.selector';
import { Utils } from '../../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class GuardHelperService {
  constructor(
    private transferState: TransferState,
    private authService: AuthService,
    private store: Store,
  ) {
  }

  public getUser(): Observable<any> {
    const loadingKey = Utils.getRequestName(AuthActions.getUser);

    return this.store.pipe(
      select(selectLoading, [loadingKey]),
      tap(loading => {
        if (loading === null) {
          this.store.dispatch(AuthActions.getUser());
        }
      }),
      filter(loading => loading === false),
      first(),
      switchMap(() => this.store.pipe(select(selectUser))),
    );
  }

  public getCompany(slug: string): Observable<any> {
    this.store.dispatch(CompanyActions.selectCompany({slug}));

    const loadingKey = Utils.getRequestName(CompanyActions.getCompany);

    return this.store.pipe(select(selectCompanyEntities))
      .pipe(
        tap(companies => {
          if (!companies[slug]) {
            this.store.dispatch(CompanyActions.getCompany({slug}));
          }
        }),
        first(),
        switchMap(companies => companies[slug] ? of(companies[slug]) : this.store.pipe(select(selectLoading, [loadingKey])).pipe(
          filter(loading => loading === false),
          first(),
          switchMap(() => this.store.pipe(select(selectSelectedCompany)))
        )),
      );
  }

  public getCompanyProfile(slug: string): Observable<any> {
    const loadingKey = Utils.getRequestName(CompanyActions.getCompanyProfile);

    return this.store.pipe(
      select(selectLoading, [loadingKey]),
      tap(loading => {
        if (loading === null) {
          this.store.dispatch(CompanyActions.getCompanyProfile({slug}));
        }
      }),
      filter(loading => loading === false),
      first(),
      switchMap(() => this.store.pipe(select(selectCompanyProfile)))
    );
  }

  public getTalent(slug: string): Observable<any> {
    this.store.dispatch(TalentActions.selectTalent({slug}));

    const loadingKey = Utils.getRequestName(TalentActions.getTalent);

    return this.store.pipe(select(selectTalentEntities))
      .pipe(
        tap(talents => {
          if (!talents[slug]) {
            this.store.dispatch(TalentActions.getTalent({slug}));
          }
        }),
        first(),
        switchMap(talents => talents[slug] ? of(talents[slug]) : this.store.pipe(select(selectLoading, [loadingKey])).pipe(
          filter(loading => loading === false),
          first(),
          switchMap(() => this.store.pipe(select(selectSelectedTalent)))
        )),
      );
  }

  public getTalentProfile(slug: string): Observable<any> {
    const loadingKey = Utils.getRequestName(TalentActions.getTalentProfile);

    return this.store.pipe(
      select(selectLoading, [loadingKey]),
      tap(loading => {
        if (loading === null) {
          this.store.dispatch(TalentActions.getTalentProfile({slug}));
        }
      }),
      filter(loading => loading === false),
      first(),
      switchMap(() => this.store.pipe(select(selectTalentProfile)))
    );
  }

  public getJob(slug: string): Observable<any> {
    this.store.dispatch(JobsActions.selectJob({slug}));

    const loadingKey = Utils.getRequestName(JobsActions.getJob);

    return this.store.pipe(select(selectJobEntities))
      .pipe(
        tap(jobs => {
          if (!jobs[slug]) {
            this.store.dispatch(JobsActions.getJob({slug}));
          }
        }),
        first(),
        switchMap(jobs => jobs[slug] ? of(jobs[slug]) : this.store.pipe(select(selectLoading, [loadingKey])).pipe(
          filter(loading => loading === false),
          first(),
          switchMap(() => this.store.pipe(select(selectSelectedJob)))
        )),
      );
  }
}
