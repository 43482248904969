<div class="breadcrumbs">
  <ng-container *ngFor="let breadcrumb of breadcrumbs$ | async; let last = last">
    <a
      *ngIf="breadcrumb.url && !last"
      class="breadcrumbs__item"
      [routerLink]="breadcrumb.url"
    >
      {{ breadcrumb.title | translate }}
    </a>

    <span *ngIf="!breadcrumb.url || last" class="breadcrumbs__item">
      {{ breadcrumb.title | translate }}
    </span>
  </ng-container>
</div>
