import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';
import { UserType } from '../../../config';

@Injectable({
  providedIn: 'root'
})
export class RegistrationCanActivateGuard implements CanActivate {
  constructor(
    private guardHelperService: GuardHelperService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardHelperService.getUser()
      .pipe(
        switchMap(user => {
          if (!user || (user && !user.isEmailVerified)) {
            this.router.navigate(['/login']);
            return of(false);
          }

          if (user?.type && !user.registrationCompletedAt && !state.url.includes('member')) {
            this.router.navigate(['/registration/member']);
            return of(false);
          }

          if (user && user.registrationCompletedAt) {
            const command = [
              user.type === UserType.TALENT
                ? `/talent/${user?.talent?.slug}/dashboard`
                : `/company/${user?.company?.slug}/dashboard`
            ];

            this.router.navigate(command);
            return of(false);
          }

          return of(true);
        })
      );
  }
}
