import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { fadeOutOnLeaveAnimation } from 'angular-animations';
import { asyncScheduler } from 'rxjs';

@Component({
  selector: 'app-loader-circle',
  templateUrl: './loader-circle.component.html',
  styleUrls: ['./loader-circle.component.scss'],
  animations: [
    fadeOutOnLeaveAnimation({duration: 300})
  ]
})
export class LoaderCircleComponent implements OnChanges, AfterViewInit {
  @Input() loading: boolean | null = false;

  @Output() loaded = new EventEmitter<void>();

  @ViewChild('loader') loader: any;

  public showLoader: boolean = false;
  public showCheckMark: boolean = false;

  private loaderElRef!: Element;

  constructor(
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit(): void {
    this.loaderElRef = this.loader?.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.loaderElRef) {
      return
    }

    if (changes['loading']?.currentValue && !changes['loading']?.previousValue) {
      this.loaderElRef.classList.add('show');
      this.showLoader = true;
    }

    if (!changes['loading']?.currentValue && changes['loading']?.previousValue) {
      this.showLoader = false;
      this.showCheckMark = true;

      asyncScheduler.schedule(() => {
        this.loaderElRef.classList?.add('hide');
        this.loaderElRef.classList?.remove('show');
      }, 1600)
    }

    this.loaderElRef.addEventListener('animationend', (event: any) => {
      if (event.animationName === 'hideLoaderCircle') {
        this.loaderElRef.classList.remove('hide');
        this.showCheckMark = false;
        this.cdRef.detectChanges();
        this.loaded.emit();
      }
    });
  }
}
