import { Actions, createEffect } from '@ngrx/effects';
import { delayWhen, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { filter, interval } from 'rxjs';

import { CoreActions } from '../core/actions/core.actions';

const patchRegex = /\b(patch|update)\b.*\brequest\b/i;
const patchSuccessRegex = /\b(patch|update)\b.*\bsuccess\b/i;
const patchFailureRegex = /\b(patch|update)\b.*\bfailure|error\b/i;

const deleteRegex = /\b(delete|remove)\b.*\brequest\b/i;
const deleteSuccessRegex = /\b(delete|remove)\b.*\bsuccess\b/i;
const deleteFailureRegex = /\b(delete|remove)\b.*\b(failure|error)\b/i;

export const getDelay = (props: any, defaultDelay = 500) => {
  let delay = defaultDelay;

  if (props.hasOwnProperty('delay')) {
    if ((typeof props.delay === 'boolean' && !props.delay) || typeof props.delay === 'number') {
      delay = props.delay;
    }
  }

  return delay;
}

export const getTracking = (props: any, defaultTracking = true) => {
  let tracking = defaultTracking;

  if (props.hasOwnProperty('tracking') && !props.tracking) {
    tracking = props.delay;
  }

  return tracking;
}

@Injectable()
export class LoadingEffects {
  constructor(
    private actions$: Actions
  ) {
  }

  patchLoadingStart$ = createEffect(() =>
    this.actions$.pipe(
      filter(({type, ...props}) => patchRegex.test(type) && props.hasOwnProperty('id')),
      filter(({type, ...props}: any) => getTracking(props)),
      map(({id}: any) => CoreActions.patchingEntity({id})),
    )
  );

  patchLoadingFinished$ = createEffect(() =>
    this.actions$.pipe(
      filter(({type, ...props}) => (patchSuccessRegex.test(type) || patchFailureRegex.test(type)) && props.hasOwnProperty('id')),
      delayWhen(({...props}: any) => {
        let delay = getDelay(props);
        return !!delay ? interval(delay) : interval(0);
      }),
      map(({id}: any) => CoreActions.patchingEntityFinished({id})),
    )
  );

  deleteLoadingStart$ = createEffect(() =>
    this.actions$.pipe(
      filter(({type, ...props}) => deleteRegex.test(type) && props.hasOwnProperty('id')),
      map(({id}: any) => CoreActions.deletingEntity({id})),
    )
  );

  deleteLoadingFinished$ = createEffect(() =>
    this.actions$.pipe(
      filter(({type, ...props}) => (deleteSuccessRegex.test(type) || deleteFailureRegex.test(type)) && props.hasOwnProperty('id')),
      delayWhen(({...props}: any) => {
        let delay = getDelay(props);
        return !!delay ? interval(delay) : interval(0);
      }),
      map(({id}: any) => CoreActions.deletingEntityFinished({id})),
    )
  );
}
