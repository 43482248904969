import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { fadeInUpOnEnterAnimation, fadeOutDownOnLeaveAnimation } from 'angular-animations';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { salaryRangeValidator } from '../../validators/salary-range.validator';

@Component({
  selector: 'app-salary-field',
  templateUrl: './salary-field.component.html',
  styleUrls: ['./salary-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SalaryFieldComponent),
      multi: true
    }
  ],
  animations: [
    fadeInUpOnEnterAnimation({duration: 400}),
    fadeOutDownOnLeaveAnimation({duration: 400})
  ]
})
export class SalaryFieldComponent implements ControlValueAccessor, OnInit {
  @Input() sharedOption?: boolean;
  @Input() theme = 'light';

  @Output() closed = new EventEmitter();

  public form = this.formBuilder.group({
    salaryFrom: [null, [Validators.required]],
    salaryTo: [null, [Validators.required]],
    sharedOption: [false]
  }, {
    validators: [
      salaryRangeValidator('salaryFrom', 'salaryTo', 'salaryTo')
    ]
  })

  private unsubscribe$ = new Subject();

  private onTouched = () => {
  };
  private onChange = (m: any) => {
  };

  constructor(
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit() {
    if (this.sharedOption !== undefined) {
      this.form.get('sharedOption')?.patchValue(this.sharedOption);
    }

    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.onChange(value));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.form.patchValue(value, {emitEvent: false, onlySelf: true});
  }
}
