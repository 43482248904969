import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Component, forwardRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { fileSizeValidator } from './file-size.validator';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-multiple-image-upload',
  templateUrl: './multiple-image-upload.component.html',
  styleUrls: ['./multiple-image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleImageUploadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultipleImageUploadComponent),
      multi: true
    }
  ]
})
export class MultipleImageUploadComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() label: string = 'Upload/Drop a Photos';
  @Input() labelForId?: string;
  @Input() sizeLimit: number = 500_000;
  @Input() filesLimit: number = 10;
  @Input() filesLabel?: string = 'JPG, PNG, GIF, SVG, SVG';

  public items: any[] = [];

  private readonly validators = [
    FileUploadValidators.accept(['image/*']),
    fileSizeValidator(this.sizeLimit),
  ];

  public readonly fileUploadControl = new FileUploadControl(
    {listVisible: false, accept: ['image/*'], discardInvalid: true, multiple: true},
    [
      FileUploadValidators.filesLimit(this.filesLimit),
      ...this.validators,
    ],
  );

  private imagesList: any[] = [];
  private filesList: any[] = [];
  private errors: any[] = [];

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  private onTouched = (m: any) => {
  };
  private onChange = (m: any) => {
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  ngOnInit(): void {
    this.fileUploadControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.filesList = value;

        this.items = [...this.imagesList, ...this.filesList];
        this.onTouched(this.items);
        this.onChange(this.items);
      });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.errors = this.fileUploadControl.getError();
    return this.errors && this.errors.length > 0 ? this.errors[0] : null;
  }

  public removeFile(file: any, index: number): void {
    this.items = this.items.filter((item, i) => i !== index);
    this.onTouched(this.items);
    this.onChange(this.items);

    if (this.isFile(file)) {
      this.fileUploadControl.removeFile(file);
    } else {
      this.imagesList = this.imagesList.filter(({id}) => id !== file.id);
      this.fileUploadControl.setValidators(
        FileUploadValidators.filesLimit(this.filesLimit - this.imagesList.length),
      );
    }
  }

  public hasError(file: any): boolean {
    return Boolean(
      this.errors[0]?.fileSize
      && this.errors[0]?.fileSize?.find(({errorFile}: any) => errorFile === file),
    );
  }

  public isFile(file: any): boolean {
    return file instanceof File;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.fileUploadControl.disable() : this.fileUploadControl.enable();
  }

  writeValue(value: any): void {
    if (value) {
      this.imagesList = value;
      this.items = [...this.imagesList];

      this.fileUploadControl.setValidators([
        FileUploadValidators.filesLimit(this.filesLimit - this.imagesList.length),
        ...this.validators,
      ]);
    }
  }
}
