import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';
import { UserType } from '../../../config';

@Injectable({
  providedIn: 'root'
})
export class TalentsCanActivateGuard implements CanActivate {
  constructor(
    private guardHelperService: GuardHelperService,
    private store: Store,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardHelperService.getUser().pipe(
      switchMap((user) => {
        const type = user?.type ? user?.type : UserType.TALENT;

        if (type === UserType.TALENT) {
          this.router.navigate(['/search/jobs']);
          return of(false);
        }

        return of(true);
      }),
    );
  }
}
