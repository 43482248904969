import { createFeatureSelector, createSelector } from '@ngrx/store';

import { adapter, selectId, State, talentFeatureKey } from '../reducers/talent.reducer';

export const selectTalentState = createFeatureSelector<State>(
  talentFeatureKey
);

export const selectTalentProfile = createSelector(selectTalentState, (state: any) => state.profile);

export const {
  selectIds,
  selectEntities: selectTalentEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectTalentState);


export const selectSelectedTalentId = createSelector(
  selectTalentState,
  selectId
);

export const selectSelectedTalent = createSelector(
  selectTalentEntities,
  selectSelectedTalentId,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

export const selectTalentRecommendedJobs = createSelector(
  selectTalentState,
  state => state.recommendedJobs
);

export const selectTalentFeedbacks = createSelector(
  selectTalentState,
  state => state.feedbacks
);

export const selectTalentLatestFeedbacks = createSelector(
  selectTalentFeedbacks,
  feedbacks => feedbacks.slice(0, 3)
);
