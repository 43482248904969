import { Component, Input } from '@angular/core';

import { openClose } from '../../animations';

@Component({
  selector: 'app-talent-job-summary-list',
  templateUrl: './talent-job-summary-list.component.html',
  styleUrls: ['./talent-job-summary-list.component.scss'],
  animations: [
    openClose()
  ]
})
export class TalentJobSummaryListComponent {
  @Input() items: any = [];
  @Input() label!: string;
  @Input() vocabularies!: any;
  @Input() talent!: any;
  @Input() isOpen = true;
  @Input() isOpenRow = true;

  constructor() {
  }

  toggleRowItem() {
    this.isOpen = !this.isOpen
    this.isOpenRow = !this.isOpenRow
  }
}
