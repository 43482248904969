<div class="block-info-accordion {{ className }}">
  <div class="info-item">

    <div class="info-item__name font-extrabold">
      {{ title | translate }}

      <div
        *ngIf="showBtnAccordion"
        class="arrow-btn info-item__btn bg-yellow dark:bg-alabaster"
        [class.open]="isShow"
        (click)="toggleAccordion()"
      >
        <span class="arrow-btn__icon text-black"></span>
      </div>
    </div>

    <div class="info-item__icon" *ngIf="infoText">
      <div
        class="info-label-btn text-gray"
        [popper]="infoText | translate"
        [popperShowOnStart]="false"
        [popperHideOnClickOutside]="true"
      >
        <svg-icon class="flex" src="info-about.svg"></svg-icon>
      </div>
    </div>

    <div class="info-item__main">
      <p
        class="font-medium info-item__content overflow-hidden quill-content"
        #descriptionText
        [class.is-shadow]="showBtnAccordion"
        [class.is-open]="showBtnAccordion && isShow"
        [innerHTML]="description | safe"
      ></p>
    </div>

  </div>
</div>
