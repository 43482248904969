import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export function openClose(): AnimationTriggerMetadata {
  return trigger('openClose', [
    state('open', style({
      height: '*', opacity: '1', filter: 'blur(0px)',  transform: 'scale(1)'
    })),
    state('closed', style({
      height: '0px', minHeight: '0', opacity: '0', filter: 'blur(10px)', transform: 'scale(.9)'
    })),
    transition('open <=> closed',
      animate('404ms ease-in-out')
    )
  ]);
}
