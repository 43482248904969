import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-auth-socials',
  templateUrl: './auth-socials.component.html',
  styleUrls: ['./auth-socials.component.scss']
})
export class AuthSocialsComponent {
  @Input() color = 'black'

  @Output() socialAuth = new EventEmitter();

  public socials = [
    {
      name: 'linkedin',
      icon: 'social-linkedin.svg'
    },
    {
      name: 'google',
      icon: 'social-google.svg'
    },
    {
      name: 'facebook',
      icon: 'social-facebook.svg'
    }
  ];

  constructor() {
  }
}
