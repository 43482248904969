import { Pipe, PipeTransform } from '@angular/core';

export function getNestedProperty(object: any, key: string): boolean {
  const nestedKeys: string[] = key.split('.');
  let value = object;

  for (const nestedKey of nestedKeys) {
    if (!value.hasOwnProperty(nestedKey) || value[nestedKey] == null) {
      return false;
    }

    value = value[nestedKey];
  }

  return true;
}

@Pipe({
  name: 'appHasValues'
})
export class HasValuesPipe implements PipeTransform {
  transform(object: any, keys: string[], each = false): boolean {
    if (each) {
      return keys.every(key => getNestedProperty(object, key));
    } else {
      return keys.some(key => getNestedProperty(object, key));
    }
  }
}
