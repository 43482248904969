import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, Input, forwardRef } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() disabled = false;
  @Input() isChecked = false;
  @Input() className = 'text-sm';
  @Input() counter?: number

  public onTouched = () => {
  };
  private onChange = (m: any) => {
  };

  constructor() {
  }

  public onChanged(event: any): void {
    this.isChecked = event && event.target && event.target.checked;
    this.onChange(this.isChecked);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(isChecked: boolean): void {
    this.isChecked = isChecked;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
