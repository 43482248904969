import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { AfterViewInit, Component, forwardRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { CdkDragEnter, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { isPlatformBrowser } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-multiple-image-upload-dnd',
  templateUrl: './multiple-image-upload-dnd.component.html',
  styleUrls: ['./multiple-image-upload-dnd.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleImageUploadDndComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultipleImageUploadDndComponent),
      multi: true
    }
  ]
})
export class MultipleImageUploadDndComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {
  @Input() label?: string;
  @Input() labelForId?: string;
  @Input() sizeLimit: number = 500_000;
  @Input() filesLimit: number = 10;
  @Input() filesLabel?: string = 'JPG, PNG, GIF, SVG, SVG';

  @ViewChild(CdkDropListGroup) listGroup!: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder!: CdkDropList;

  public items: any[] = [];

  public readonly fileUploadControl = new FileUploadControl(
    {listVisible: false, accept: ['image/*'], discardInvalid: true},
    [
      FileUploadValidators.accept(['image/*']),
      FileUploadValidators.filesLimit(this.filesLimit),
      FileUploadValidators.sizeLimit(this.sizeLimit)
    ]
  );

  private target: CdkDropList | undefined;
  private targetIndex!: number;
  private source: CdkDropList | undefined;
  private sourceIndex!: number;
  private activeContainer: any;
  private imagesList: any[] = [];
  private filesList: any[] = [];
  private readonly isBrowser = isPlatformBrowser(this.platformId);

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  private onTouched = (m: any) => {
  };
  private onChange = (m: any) => {
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  ngOnInit(): void {
    this.fileUploadControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.filesList = value;

        this.items = [...this.imagesList, ...this.filesList];
        this.onTouched(this.items);
        this.onChange(this.items);
      });
  }

  ngAfterViewInit() {
    const phElement = this.placeholder?.element?.nativeElement;

    phElement.style.display = 'none';
    phElement.parentElement?.removeChild(phElement);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const errors = this.fileUploadControl.getError();
    return errors && errors.length > 0 ? errors[0] : null;
  }

  public removeFile(file: any, index: number): void {
    this.items = this.items.filter((item, i) => i !== index);
    this.onTouched(this.items);
    this.onChange(this.items);

    if (this.isFile(file)) {
      this.fileUploadControl.removeFile(file);
    } else {
      this.imagesList = this.imagesList.filter(({id}) => id !== file.id);
      this.fileUploadControl.setValidators(
        FileUploadValidators.filesLimit(this.filesLimit - this.imagesList.length),
      );
    }
  }

  public dropListDropped(): void {
    if (!this.target) {
      return;
    }

    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent?.removeChild(phElement);
    parent?.appendChild(phElement);
    parent?.insertBefore(
      this.source!.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    this.target = undefined;
    this.source = undefined;
    this.activeContainer = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.items, this.sourceIndex, this.targetIndex);
      this.onTouched(this.items);
      this.onChange(this.items);
    }
  }

  public dropListEntered(e: CdkDragEnter): boolean {
    const drag = e.item;
    const drop = e.container;

    if (drop === this.placeholder) {
      return true;
    }

    const phElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex = this.indexOf(
      dropElement.parentElement?.children,
      this.source ? phElement : sourceElement
    );
    const dropIndex = this.indexOf(
      dropElement.parentElement?.children,
      dropElement
    );

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = dropElement.clientWidth + 'px';
      phElement.style.height = dropElement.clientHeight + 'px';

      sourceElement.parentElement?.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement?.insertBefore(
      phElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );

    if (this.isBrowser) {
      requestAnimationFrame(() => {
        this.placeholder._dropListRef.enter(
          drag._dragRef,
          drag.element.nativeElement.offsetLeft,
          drag.element.nativeElement.offsetTop
        );
      });
    }

    return false;
  }

  public isFile(file: any): boolean {
    return file instanceof File;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.fileUploadControl.disable() : this.fileUploadControl.enable();
  }

  writeValue(value: any): void {
    if (value) {
      this.imagesList = value;
      this.items = [...this.imagesList];

      this.fileUploadControl.setValidators(
        FileUploadValidators.filesLimit(this.filesLimit - this.imagesList.length),
      );
    }
  }

  private indexOf(collection: any, node: any) {
    return Array.prototype.indexOf.call(collection, node);
  }
}
