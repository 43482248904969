<div
  class="short-filter-item shadow-cod-gray-two dark:shadow-alabaster"
  [ngClass]="{'active' : opened}"
  (click)="opened = !opened"
>
  <span *ngIf="title">
    {{ title | translate }}
  </span>
</div>

<div class="short-filter-content flex flex-col items-center w-[350px] text-black">
  <form
    *ngIf="opened"
    @fadeInUpOnEnter
    @fadeOutDownOnLeave
    [formGroup]="form"
    (submit)="submit()"
    class="filter-salary-item bg-white"
  >
    <div class="flex flex-col items-center gap-y-5">
      <label for="salary">
        {{ 'search.salary' | translate }}
      </label>

      <div class="filter-salary-item__inputs">
        <div class="relative">
          <input type="number" min="0" step="50" class="white hidden-arrows-number" formControlName="salaryFrom">
        </div>
        <span class="h-px bg-mine-shaft w-full"></span>
        <div class="relative">
          <input type="number" min="0" step="50" class="white hidden-arrows-number" formControlName="salaryTo">
        </div>
      </div>
    </div>

    <div class="flex flex-1 justify-between">
      <button
        type="button"
        class="w-auto text-sm min-w-[120px] font-bold underline"
        (click)="reset()"
      >
        {{ 'btn.reset' | translate }}
      </button>

      <button
        type="submit"
        class="btn-small w-auto-auto bg-mine-shaft w-auto text-white text-sm min-w-[120px]"
      >
        {{ 'btn.save' | translate }}
      </button>
    </div>
  </form>
</div>
