import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AppAnimatedPageComponent } from './core/containers/app-animated-page/app-animated-page.component';
import { RegistrationNotCompletedGuard } from './modules/auth/guards/registration-not-completed.guard';
import { WithoutEmailCanActivateGuard } from './modules/auth/guards/without-email-can-activate.guard';
import { WithoutTokenCanActivateGuard } from './modules/auth/guards/without-token-can-activate.guard';
import { RegistrationCanActivateGuard } from './modules/auth/guards/registration-can-activate.guard';
import { LoadUserResolver } from './modules/auth/resolvers/load-user.resolver';
import { RedirectGuard } from './modules/auth/guards/redirect.guard';
import { NoAuthGuard } from './modules/auth/guards/no-auth.guard';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { environment } from '../environments/environment';
import { AppRoutes } from './models/app-routes.type';

const routes: AppRoutes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    component: AppAnimatedPageComponent,
    children: [
      {
        path: 'talent',
        title: `${environment.baseTitle} | Talent`,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/talent/talent.module').then(m => m.TalentModule),
      },
      {
        path: 'company',
        title: `${environment.baseTitle} | Company`,
        canActivate: [RegistrationNotCompletedGuard],
        loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule),
      },
      {
        path: 'search',
        title: `${environment.baseTitle} | Search`,
        loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
        resolve: [LoadUserResolver],
      },
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/modules/login/login.module').then(m => m.LoginModule),
    canActivate: [NoAuthGuard],
    title: `${environment.baseTitle} | Sign In`,
  },
  {
    path: 'logout',
    loadChildren: () => import('./modules/auth/modules/logout/logout.module').then(m => m.LogoutModule),
    canActivate: [AuthGuard],
    title: `${environment.baseTitle} | Log Out`,
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./modules/auth/modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    canActivate: [NoAuthGuard],
    title: `${environment.baseTitle} | Forgot Password`,
  },
  {
    path: 'auth-redirect',
    canActivate: [AuthGuard, RedirectGuard],
    pathMatch: 'full',
    children: [],
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./modules/auth/modules/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule),
    canActivate: [
      WithoutTokenCanActivateGuard,
      WithoutEmailCanActivateGuard,
    ],
    title: `${environment.baseTitle} | Unsubscribe`,
  },
  {
    path: 'send-confirmation',
    loadChildren: () => import('./modules/auth/modules/send-confirmation/send-confirmation.module').then(m => m.SendConfirmationModule),
    title: `${environment.baseTitle} | Send Confirmation`,
  },
  {
    path: 'recovery-password',
    loadChildren: () => import('./modules/auth/modules/recovery-password/recovery-password.module').then(m => m.RecoveryPasswordModule),
    canActivate: [
      NoAuthGuard,
      WithoutTokenCanActivateGuard
    ],
    title: `${environment.baseTitle} | Password Recovery`,
  },
  {
    path: 'auth/email',
    loadChildren: () => import('./modules/auth/modules/auth-email/auth-email.module').then(m => m.AuthEmailModule),
    canActivate: [
      NoAuthGuard,
      WithoutTokenCanActivateGuard
    ],
  },
  {
    path: 'auth/email/password',
    loadChildren: () => import('./modules/auth/modules/auth-email-password/auth-email-password.module').then(m => m.AuthEmailPasswordModule),
    data: {
      theme: 'dark',
    },
    canActivate: [
      NoAuthGuard,
      WithoutTokenCanActivateGuard
    ],
  },
  {
    path: 'auth/:provider',
    loadChildren: () => import('./modules/auth/modules/auth-social/auth-social.module').then(m => m.AuthSocialModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'sign-up/talent',
    loadChildren: () => import('./modules/auth/modules/sign-up/sign-up.module').then(m => m.SignUpModule),
    canActivate: [NoAuthGuard],
    title: `${environment.baseTitle} | Talent Sign Up`,
    data: {
      theme: 'light',
    },
  },
  {
    path: 'sign-up/company',
    loadChildren: () => import('./modules/auth/modules/sign-up/sign-up.module').then(m => m.SignUpModule),
    canActivate: [NoAuthGuard],
    title: `${environment.baseTitle} | Company Sign Up`,
    data: {
      theme: 'dark',
    },
  },
  {
    path: 'registration',
    loadChildren: () => import('./modules/auth/modules/registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [RegistrationCanActivateGuard],
  },
  {
    path: 'not-found',
    loadChildren: () => import('./modules/auth/modules/not-found/not-found.module').then(m => m.NotFoundModule),
  },
  {
    path: '**',
    redirectTo: 'auth-redirect'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
