import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function sameStringValidator(lineCompare: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value) {
      return null;
    }

    return value !== lineCompare ? {passwordStrength: true} : null;
  }
}
