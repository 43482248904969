<ng-select
  appOpenSelect
  [labelForId]="labelForId"
  [formControl]="autocompleteFC"
  class="select"
  [ngClass]="{ 'yellow': color === 'yellow', 'dark': color === 'black' }"
  [multiple]="false"
  [items]="locations"
  [loading]="locationsLoading"
  [minTermLength]="2"
  [typeahead]="locationInput$"
  (clear)="clearAutocomplete()"
  (blur)="onBlur()"
  [typeToSearchText]="'label.enter_min_length' | translate: {number: minLength} "
></ng-select>
