<label
  [class.disabled]="isDisabled"
  [class.green]="themeColor === 'green'"
  [class.white]="themeColor === 'white'"
  class="switch not-opacity"
>
  <input type="checkbox" [formControl]="switchFC">
  <span class="slider"></span>
  <span class="label right" *ngIf="label">{{ label | translate }}</span>
</label>
