import { HttpClient } from '@angular/common/http';
import { serialize } from 'object-to-formdata';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  constructor(
    private http: HttpClient
  ) {
  }

  public createJob(body: any): Observable<any> {
    return this.http.post(`${environment.api}/job/create`, body);
  }

  public openJob(id: string): Observable<any> {
    return this.http.patch(`${environment.api}/job/${id}/open`, {});
  }

  public closeJob(id: string): Observable<any> {
    return this.http.patch(`${environment.api}/job/${id}/close`, {});
  }

  public patchGeneralInfo(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/job/${id}/general-info`, body);
  }

  public patchConditions(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/job/${id}/conditions`, body);
  }

  public patchSalary(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/job/${id}/salary`, body);
  }

  public patchAbout(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/job/${id}/about`, body);
  }

  public patchRequirements(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/job/${id}/requirements`, body);
  }

  public patchResults(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/job/${id}/results`, body);
  }

  public getJobs(slug: string): Observable<any> {
    return this.http.get(`${environment.api}/company/${slug}/jobs`);
  }

  public getJob(slug: string): Observable<any> {
    return this.http.get(`${environment.api}/job/${slug}`);
  }

  public getJobFeedbacks(slug: string): Observable<any> {
    return this.http.get(`${environment.api}/job/${slug}/feedbacks`);
  }

  public feedbackToJob(id: string, body: any): Observable<any> {
    const serializeOptions = {indices: true, dotsForObjectNotation: false};

    return this.http.post(`${environment.api}/job/${id}/feedback`, serialize(body, serializeOptions));
  }

  public updateFeedbackStep(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/job/${id}/feedback-step`, body);
  }

  public createFeedbackComment(id: string, body: any): Observable<any> {
    return this.http.post(`${environment.api}/job/feedback/${id}/comment`, body);
  }

  public updateFeedbackComment(id: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/job/feedback/comment/${id}`, body);
  }

  public deleteFeedbackComment(id: string): Observable<any> {
    return this.http.delete(`${environment.api}/job/feedback/comment/${id}`);
  }

  public deleteJob(id: string): Observable<any> {
    return this.http.delete(`${environment.api}/job/${id}`);
  }
}
