<div class="photos col-span-full">
  <h3>Put the photos in the order you want</h3>

  <div class="photos__main" cdkDropListGroup>
    <div
      cdkDropList
      (cdkDropListDropped)="dropListDropped()"
      (cdkDropListEntered)="dropListEntered($event)"
    ></div>
    <div
      cdkDropList
      *ngFor="let item of items; let i = index"
      (cdkDropListDropped)="dropListDropped()"
      (cdkDropListEntered)="dropListEntered($event)"
    >
      <div cdkDrag class="photos__item photo-item">
        <img [src]="item | fileToImage" alt="" *ngIf="isFile(item); else notFile">

        <ng-template #notFile>
          <img [src]="item?.url" alt="">
        </ng-template>

        <button type="button" class="photo-item__close text-white" (click)="removeFile(item, i)">
          <svg-icon src="close.svg"></svg-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="form-group">
  <file-upload
    [id]="labelForId"
    appErrorMessage
    [control]="fileUploadControl"
    [multiple]="true"
    [animation]="false"
    class="file-upload"
  >
    <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
      <div class="file-upload-text">
        <div class="file-upload-text__title font-bold">
          {{ 'label.drag_drop' | translate }}
        </div>
        <div class="file-upload-text__sub-title">
          {{ 'label.files_upload' | translate: { size: sizeLimit / 1000 / 1000, formats: filesLabel} }}
        </div>
      </div>
    </ng-template>
  </file-upload>

  <span class="length-counter">
    {{items.length}} / {{filesLimit}}
  </span>
</div>
