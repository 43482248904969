export const environment = {
  production: false,
  local: false,
  api: 'https://app.bazait.xyz/api',
  projectName: 'bazait_platform',
  baseTitle: 'BazaIT',
  translateProject: 'platform',
  landingURL: 'https://bazait.xyz/',
  amplitudeApiKey: 'd58c346d140dee440ffcbf927f0be3a1',
};
