import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { INavigationInterface } from '../../models';
import { UserType } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private readonly navigationSettings = {
    authorized: {
      showNavigation: true,
      showLoginButton: false
    },
    anonymous: {
      showNavigation: false,
      showLoginButton: false
    }
  };

  private _mainNavigation$ = new BehaviorSubject<INavigationInterface[] | null>(null);
  private _secondaryNavigation$ = new BehaviorSubject<INavigationInterface[] | null>(null);

  public mainNavigation$ = this._mainNavigation$.asObservable();
  public secondaryNavigation$ = this._secondaryNavigation$.asObservable();

  constructor() {
  }

  public buildMainNavigation(userType: UserType, slug: string): INavigationInterface[] {
    const slugURL = `${userType}/${slug}`;

    const navigation = [
      {
        icon: 'nav-home.svg',
        name: userType === UserType.COMPANY ? `company.navigation.dashboard` : `talent.navigation.dashboard`,
        url: `/${slugURL}/dashboard`
      },
      {
        icon: 'nav-profile.svg',
        name: userType === UserType.COMPANY ? `company.navigation.profile` : `talent.navigation.profile`,
        url: `/${slugURL}/profile`,
        selected: {
          paths: [`/${slugURL}`],
        }
      },
      {
        icon: 'nav-jobs.svg',
        name: userType === UserType.COMPANY ? `company.navigation.my_jobs` : `talent.navigation.feedbacks`,
        url: userType === UserType.COMPANY ? `/${slugURL}/jobs/list` : `/${slugURL}/feedbacks`,
      },
      {
        icon: 'nav-search.svg',
        name: userType === UserType.COMPANY ? `company.navigation.search` : `talent.navigation.search`,
        url: userType === UserType.COMPANY ? `/search/talents` : `/search/jobs`,
        selected: {
          paths: [`/search/jobs`, `/search/talents`, `/search/companies`],
        }
      },
      {
        icon: 'nav-settings.svg',
        name: userType === UserType.COMPANY ? `company.navigation.settings` : `talent.navigation.settings`,
        url: `/${slugURL}/settings/account`,
        selected: {
          paths: [`/${slugURL}/settings/members`],
        }
      },
      {
        openNewWindow: true,
        icon: 'nav-price.svg',
        name: 'company.navigation.pricing',
        url: `https://bazait.com/company#pricing`,
      }
    ];

    this._mainNavigation$.next(navigation);

    return navigation;
  }

  public buildSecondaryNavigation(userType: UserType, slug: string): INavigationInterface[] {
    const slugURL = `${userType}/${slug}`;

    const navigation = [
      {
        name: 'second_navigation.profile',
        url: `/${slugURL}/profile`
      },
      {
        name: 'second_navigation.settings',
        url: `/${slugURL}/settings`,
      }
    ];

    this._secondaryNavigation$.next(navigation);

    return navigation;
  }

  public getNavigationConfig(activatedRoute: ActivatedRoute, user: any): any {
    let child = activatedRoute.firstChild;
    const userRole = typeof user !== 'undefined' && !!user ? 'authorized' : 'anonymous';

    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else{
        const routeNavigationConfigByRole = child.snapshot.data?.['navigationSettings']?.[userRole];
        const defaultRouteNavigationConfigByRole = this.navigationSettings[userRole];

        return {
          showNavigation: routeNavigationConfigByRole?.hasOwnProperty('showNavigation')
            ? routeNavigationConfigByRole.showNavigation
            : defaultRouteNavigationConfigByRole?.showNavigation,
          showLoginButton: routeNavigationConfigByRole?.hasOwnProperty('showLoginButton')
            ? routeNavigationConfigByRole.showLoginButton
            : defaultRouteNavigationConfigByRole?.showLoginButton,
        };
      }
    }
  }
}
