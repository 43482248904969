import { AbstractControl, ValidatorFn } from '@angular/forms';

export function PasswordNotMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const password = control.get(controlName);
    const confirmPassword = control.get(matchingControlName);
    const isPasswordMatch = password?.value === confirmPassword?.value;

    if (!isPasswordMatch && confirmPassword?.touched && confirmPassword?.valid) {
      confirmPassword?.setErrors({passwordNotMatch: true});
    } else {
      confirmPassword?.setErrors(null);
    }

    return !isPasswordMatch ? {passwordNotMatch: true} : null;
  };
}
