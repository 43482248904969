<div class="search-block__header" [formGroup]="form">
  <div class="short-filter short-border text-white dark:text-black">
    <div
      class="relative"
      *ngFor="let field of formStructure?.fields | filterBy : ['isInShortForm'] : true"
    >
      <app-filter-item
        *ngIf="field.type === 'select-multiple' || field.type === 'checkbox'"
        [formControlName]="field.name"
        [items]="field | processSearchItems : vocabularies : aggregations"
        [title]="field.label"
        [bindValue]="field.bindValue || 'id'"
        [bindLabel]="field.bindLabel || 'title'"
      ></app-filter-item>

      <app-filter-salary-item
        *ngIf="field.type === 'salary'"
        [formControlName]="field.name"
        [title]="field.label"
      ></app-filter-salary-item>
    </div>
  </div>

  <div class="short-border flex items-center text-white dark:text-black w-full lg:w-auto lg:px-5">
    <button
      class="btn-filter bg-mine-shaft text-white dark:text-black lg:text-black lg:bg-white dark:bg-white lg:dark:text-white lg:dark:bg-mine-shaft"
      (click)="openModal()"
    >
      <svg-icon
        src="filter.svg"
        class="bg-white text-mine-shaft lg:text-black dark:text-white dark:bg-mine-shaft"
      ></svg-icon>
      {{ 'btn.open_all_filters' | translate }}
    </button>
  </div>
</div>
