import { catchError, delay, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { filter, of, tap } from 'rxjs';

import {
  ModalCompanyNotVerifiedComponent
} from '../../../shared/components/modal-company-not-verified/modal-company-not-verified.component';
import { CustomModalComponent } from '../../../shared/components/custom-modal/custom-modal.component';
import { ModalService } from '../../../shared/modules/modal/services/modal.service';
import { FormHelperService } from '../../../shared/services/form-helper.service';
import { AmplitudeService } from '../../../shared/services/amplitude.service';
import { ConfettiService } from '../../../shared/services/confetti.service';
import { selectUser, selectUserType } from '../selectors/auth.selectors';
import { CompanyActions } from '../../company/actions/company.actions';
import { TalentActions } from '../../talent/actions/talent.actions';
import { CoreActions } from '../../../core/actions/core.actions';
import { UserType, ModalType, Roles } from '../../../config';
import { AuthService } from '../services/auth.service';
import { AuthActions } from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private formHelperService: FormHelperService,
    private translateService: TranslateService,
    private amplitudeService: AmplitudeService,
    private confettiService: ConfettiService,
    private cookieService: CookieService,
    private modalService: ModalService,
    private authService: AuthService,
    private actions$: Actions,
    private router: Router,
    private store: Store,
  ) {
  }

  socialAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.socialAuth),
      exhaustMap(({provider}: any) =>
        this.authService.socialAuth(provider).pipe(
          map(({url}) => AuthActions.socialAuthSuccess({url})),
          catchError(({error}) => of(AuthActions.socialAuthFailure({error})))
        )
      )
    )
  );

  socialAuthSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.socialAuthSuccess),
      tap(({url}: any) => {
        if (isPlatformBrowser(this.platformId)) {
          this.document.defaultView?.open(url, '_self');
        }
      })
    ), {dispatch: false}
  );

  socialAuthCallback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.socialAuthCallback),
      exhaustMap(({provider, params}) =>
        this.authService.socialAuthCallback(provider, params).pipe(
          map(({user}) => AuthActions.socialAuthCallbackSuccess({user})),
          catchError(({error}) => of(AuthActions.socialAuthCallbackFailure({error})))
        )
      )
    )
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signUp),
      exhaustMap(({body}) =>
        this.authService.signUp(body).pipe(
          map(() => AuthActions.signUpSuccess()),
          catchError(({error}) => of(AuthActions.signUpFailure({error})))
        )
      )
    )
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUser),
      withLatestFrom(this.store),
      map(([_, store]: any) => {
        const isServerAuthenticated = isPlatformServer(this.platformId) && this.authService.isUserAuthenticated();
        const isBrowserAuthenticated = isPlatformBrowser(this.platformId) && store?.auth?.authorized;

        return isServerAuthenticated || isBrowserAuthenticated;
      }),
      exhaustMap((isAuthenticated) =>
        isAuthenticated ?
          this.authService.getUser().pipe(
            map((user) => AuthActions.getUserSuccess({user})),
            catchError(({error}) => of(AuthActions.getUserFailure({error})))
          )
          : of(AuthActions.getUserFailure({error: {}, silent: true}))
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateUser),
      exhaustMap(({body}) =>
        this.authService.updateUser(body).pipe(
          map(user => AuthActions.updateUserSuccess({user})),
          catchError(({error}) => of(AuthActions.updateUserFailure({error})))
        )
      )
    )
  );

  setTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.signInSuccess,
        AuthActions.getUserSuccess,
        AuthActions.confirmEmailSuccess,
        AuthActions.resetPasswordSuccess,
      ),
      filter(({user}) => user.type),
      map(({user}) => CoreActions.setTheme({
        theme: user.type === UserType.TALENT ? 'light' : 'dark'
      }))
    )
  );

  getUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserSuccess),
      tap(({user}: any) => {
        const type = user.type === UserType.TALENT ? 'TALENT' : 'COMPANY';
        const roles = user?.roles
          ?.map(({ name }: any) => name)
          ?.filter((name: string) => name !== Roles.AUTHENTICATED);

        this.amplitudeService.setUserId(user.id);
        this.amplitudeService.setUserProperties('type', type);

        if (user.type === UserType.COMPANY) {
          this.amplitudeService.setUserProperties('company_id', user.company.id);
        }

        if (roles?.length > 0) {
          this.amplitudeService.setUserProperties('roles', roles.join(', '));
        }
      }),
    ), {dispatch: false}
  );

  loadTalentProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserSuccess),
      filter(({user}) => user.type === UserType.TALENT),
      map(({user}) => TalentActions.getTalentProfile({slug: user.talent.slug}))
    )
  );

  loadCompanyProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserSuccess),
      filter(({user}) => user.type === UserType.COMPANY),
      map(({user}) => CompanyActions.getCompanyProfile({slug: user.company.slug}))
    )
  );

  signUpSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signUpSuccess),
      withLatestFrom(this.store.pipe(select(selectUserType))),
      tap(([, userType]) => {
        const type = userType ? userType : UserType.TALENT;
        const event = type === UserType.TALENT ? 'talent_sign_up_completed' : 'company_sign_up_completed';

        this.amplitudeService.track(event);
      }),
      switchMap(() => {
        return this.modalService.addModal(
          CustomModalComponent,
          {
            type: ModalType.SUCCESS,
            message: this.translateService.instant('modal.registrationSuccessMessage')
          },
          {autoClose: 5000}
        );
      })
    ), {dispatch: false}
  );

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signIn),
      exhaustMap(({credentials}) =>
        this.authService.signIn(credentials).pipe(
          map(({user}) => AuthActions.signInSuccess({user})),
          catchError(({error}) => of(AuthActions.signInFailure({error})))
        )
      )
    )
  );

  confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.confirmEmail),
      exhaustMap(({token}) =>
        this.authService.confirmEmail({token}).pipe(
          map(({user}) => AuthActions.confirmEmailSuccess({user})),
          catchError(({error}) => of(AuthActions.confirmEmailFailure({error})))
        )
      )
    )
  );

  confirmEmailPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.confirmEmailPassword),
      exhaustMap(({token, password}) =>
        this.authService.confirmEmailPassword({token, password}).pipe(
          map(({user}) => AuthActions.confirmEmailPasswordSuccess({user})),
          catchError(({error}) => of(AuthActions.confirmEmailPasswordFailure({error})))
        )
      )
    )
  );

  confirmEmailPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.confirmEmailPasswordSuccess),
      tap(() => {
        this.router.navigate([`/registration/member`])
      })
    ), {dispatch: false}
  );

  confirmEmailSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.confirmEmailSuccess),
      filter(({user}) => !user.type),
      withLatestFrom(this.store.pipe(select(selectUserType))),
      tap(([, userType]) => {
        const type = userType ? userType : UserType.TALENT;

        this.router.navigate([`/registration/${type}`])
      })
    ), {dispatch: false}
  );

  logOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logOut),
      exhaustMap(() =>
        this.authService.logOut().pipe(
          map(() => AuthActions.logOutSuccess()),
          catchError(({error}) => of(AuthActions.logOutFailure({error})))
        )
      )
    )
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      exhaustMap(({email}) =>
        this.authService.forgotPassword(email).pipe(
          map(() => AuthActions.forgotPasswordSuccess()),
          catchError(({error}) => of(AuthActions.forgotPasswordFailure({error})))
        )
      )
    )
  );

  forgotPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgotPasswordSuccess),
      switchMap(() => {
        return this.modalService.addModal(
          CustomModalComponent,
          {
            type: ModalType.SUCCESS,
            message: this.translateService.instant('modal.forgotPasswordSuccess')
          },
          {autoClose: 5000}
        );
      })
    ), {dispatch: false}
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      exhaustMap(({body}) =>
        this.authService.resetPassword(body).pipe(
          map(({user}) => AuthActions.resetPasswordSuccess({user})),
          catchError(({error}) => of(AuthActions.resetPasswordFailure({error})))
        )
      )
    )
  );

  resendConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resendConfirmation),
      exhaustMap(({email}) =>
        this.authService.resendConfirmationLink(email).pipe(
          map(() => AuthActions.resendConfirmationSuccess()),
          catchError(({error}) => of(AuthActions.resendConfirmationFailure({error})))
        )
      )
    )
  );

  resendConfirmationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resendConfirmationSuccess),
      switchMap(() => {
        return this.modalService.addModal(
          CustomModalComponent,
          {
            type: ModalType.SUCCESS,
            message: this.translateService.instant('modal.resendConfirmationSuccess')
          },
          {autoClose: 5000}
        );
      })
    ), {dispatch: false}
  );

  registerTalent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerTalent),
      exhaustMap(({body}) =>
        this.authService.registerTalent(body).pipe(
          map(() => AuthActions.registerTalentSuccess()),
          catchError(({error}) => of(AuthActions.registerTalentFailure({error})))
        )
      )
    )
  );

  registerCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerCompany),
      exhaustMap(({body}) =>
        this.authService.registerCompany(body).pipe(
          map(company => AuthActions.registerCompanySuccess({company})),
          catchError(({error}) => of(AuthActions.registerCompanyFailure({error})))
        )
      )
    )
  );

  registerMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerMember),
      exhaustMap(({body}) =>
        this.authService.registerMember(body).pipe(
          map(user => AuthActions.registerMemberSuccess({user})),
          catchError(({error}) => of(AuthActions.registerMemberFailure({error})))
        )
      )
    )
  );

  registerMemberSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerMemberSuccess),
      delay(1000),
      tap(() => this.confettiService.play()),
      tap(() => this.router.navigate(['/auth-redirect'])),
      switchMap(() => {
        return this.modalService.addModal(
          CustomModalComponent,
          {
            type: ModalType.SUCCESS,
            message: this.translateService.instant('modal.registration_success_contact')
          },
          {
            autoClose: 5000,
            wrapperClass: 'custom-modal--fade-in',
            closeOnEscape: false,
            closeOnClickOutside: false,
          }
        );
      }),
    ), {dispatch: false}
  );

  registerTalentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerTalentSuccess),
      delay(1000),
      tap(() => this.confettiService.play()),
      tap(() => this.router.navigate(['/auth-redirect'])),
      switchMap(() => {
        return this.modalService.addModal(
          CustomModalComponent,
          {
            type: ModalType.SUCCESS,
            message: this.translateService.instant('modal.registration_success_talent')
          },
          {
            autoClose: 5000,
            wrapperClass: 'custom-modal--fade-in',
            closeOnEscape: false,
            closeOnClickOutside: false,
          }
        );
      }),
    ), {dispatch: false}
  );

  registerCompanySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerCompanySuccess),
      delay(1000),
      tap(() => this.confettiService.play()),
      tap(() => this.router.navigate(['/auth-redirect'])),
      switchMap(() => {
        return this.modalService.addModal(
          CustomModalComponent,
          {
            type: ModalType.SUCCESS,
            message: this.translateService.instant('modal.registration_success_company')
          },
          {
            autoClose: 5000,
            wrapperClass: 'custom-modal--fade-in',
            closeOnEscape: false,
            closeOnClickOutside: false,
          }
        );
      }),
    ), {dispatch: false}
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePassword),
      exhaustMap(({body}) =>
        this.authService.changePassword(body).pipe(
          map(user => AuthActions.changePasswordSuccess({user})),
          catchError(({error}) => of(AuthActions.changePasswordFailure({error})))
        )
      )
    )
  );

  changePasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePasswordSuccess),
      switchMap(() => {
        return this.modalService.addModal(
          CustomModalComponent,
          {
            type: ModalType.SUCCESS,
            message: this.translateService.instant('modal.password_updated')
          },
          {
            autoClose: 5000,
          }
        );
      }),
    ), {dispatch: false}
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.deleteUser),
      exhaustMap(() =>
        this.authService.deleteUser().pipe(
          switchMap(() => [
            AuthActions.deleteUserSuccess(),
            AuthActions.logOutSuccess(),
          ]),
          catchError(({error}) => of(AuthActions.deleteUserFailure({error})))
        )
      )
    )
  );

  deleteUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.deleteUserSuccess),
      switchMap(() => {
        return this.modalService.addModal(
          CustomModalComponent,
          {
            type: ModalType.SUCCESS,
            message: this.translateService.instant('modal.profile_deleted_successfully')
          },
          {
            autoClose: 5000,
          }
        );
      }),
    ), {dispatch: false}
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.signInSuccess,
        AuthActions.registerTalentSuccess,
        AuthActions.registerCompanySuccess,
        AuthActions.socialAuthCallbackSuccess,
        AuthActions.registerMemberSuccess
      ),
      map(() => AuthActions.getUser()),
    )
  );

  companyNotVerifiedMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signInSuccess),
      withLatestFrom(this.store.pipe(select(selectUser))),
      filter(([, user]) => user && user?.type === UserType.COMPANY && !user.company.verified),
      switchMap(() =>
        this.modalService.addModal(ModalCompanyNotVerifiedComponent, {}, {autoClose: 5000})
      ),
    ), {dispatch: false}
  );

  refreshPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.logOutSuccess,
        AuthActions.logOutFailure,
      ),
      tap(() => {
        this.router.navigate(['/login']);
        this.amplitudeService.reset();
      })
    ), {dispatch: false}
  );

  redirectToLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.socialAuthCallbackFailure,
        AuthActions.resendConfirmationSuccess,
        AuthActions.forgotPasswordSuccess,
        AuthActions.signUpSuccess,
        AuthActions.confirmEmailFailure,
        AuthActions.mailUnsubscribeSuccess,
        AuthActions.mailUnsubscribeFailure
      ),
      tap(() => this.router.navigate(['/login']))
    ), {dispatch: false}
  );

  redirectToDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.resetPasswordSuccess,
        AuthActions.signInSuccess,
        AuthActions.socialAuthCallbackSuccess
      ),
      tap(() => this.router.navigate(['/auth-redirect']))
    ), {dispatch: false}
  );

  clearAuthForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.signInSuccess,
        AuthActions.signUpSuccess,
        AuthActions.forgotPasswordSuccess,
        AuthActions.resetPasswordSuccess,
        AuthActions.resendConfirmationSuccess,
      ),
      tap(() => this.formHelperService.clearForm())
    ), {dispatch: false}
  );

  mailUnsubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.mailUnsubscribe),
      exhaustMap(({token, email}) =>
        this.authService.mailUnsubscribe({token, email}).pipe(
          map(() => AuthActions.mailUnsubscribeSuccess()),
          catchError(({error}) => of(AuthActions.mailUnsubscribeFailure({error})))
        )
      )
    )
  );

  mailUnsubscribeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.mailUnsubscribeSuccess),
      switchMap(() => {
        return this.modalService.addModal(
          CustomModalComponent,
          {
            type: ModalType.WARNING,
            message: this.translateService.instant('modal.mail_unsubscribed_successfully')
          },
          {
            autoClose: 5000,
          }
        );
      }),
    ), {dispatch: false}
  );
}
