import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injector,
  ModuleWithProviders,
  NgModule, PLATFORM_ID,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  defaultModalOptions,
  DefaultModalOptionConfig,
  ModalOptions,
} from './modal-options';
import { ModalWrapperComponent } from './components/modal-wrapper.component';
import { ModalService, ModalServiceConfig } from './services/modal.service';
import { ModalHolderComponent } from './components/modal-holder.component';
import { ModalServiceFactory } from './services/modal-service.factory';
import { ModalDraggableDirective } from './modal-draggable.directive';

@NgModule({
  declarations: [
    ModalHolderComponent,
    ModalWrapperComponent,
    ModalDraggableDirective
  ],
  providers: [
    ModalService,
    {
      provide: DefaultModalOptionConfig,
      useValue: defaultModalOptions,
    },
  ],
  imports: [CommonModule],
  entryComponents: [
    ModalHolderComponent,
    ModalWrapperComponent
  ],
})
export class ModalModule {
  static forRoot(
    config: ModalServiceConfig,
    defaultModalOptions?: ModalOptions
  ): ModuleWithProviders<ModalModule> {
    return {
      ngModule: ModalModule,
      providers: [
        {provide: ModalServiceConfig, useValue: config},
        {
          provide: ModalService,
          useFactory: ModalServiceFactory,
          deps: [ComponentFactoryResolver, ApplicationRef, Injector, ModalServiceConfig, PLATFORM_ID],
        },
        {
          provide: DefaultModalOptionConfig,
          useValue: defaultModalOptions || defaultModalOptions,
        }
      ],
    };
  }

  constructor() {
  }
}
