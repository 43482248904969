import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';
import { selectUser } from '../../auth/selectors/auth.selectors';
import { UserType } from '../../../config';

@Injectable({
  providedIn: 'root'
})
export class JobViewCanActivateGuard implements CanActivate {
  constructor(
    private guardHelperService: GuardHelperService,
    private router: Router,
    private store: Store
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { jobSlug: slug } = route.params;

    return this.guardHelperService.getJob(slug).pipe(
      withLatestFrom(this.store.pipe(select(selectUser))),
      map(([job, user]) => {
        if (job?.completedAt === null) {
          const type = user?.type ? user?.type : UserType.TALENT;

          const command = [
            type === UserType.TALENT
              ? `/talent/${user?.talent?.slug}/dashboard`
              : `/company/${user?.company?.slug}/jobs/${slug}/edit`
          ];

          user ? this.router.navigate(command) : this.router.navigate(['/login']);
          return false;
        }

        return true;
      })
    );
  }
}
