import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { DynamicFormArray, DynamicFormField, DynamicFormGroup } from '../../models/dynamic-form-control.type';
import { Utils } from '../../helpers/utils';

@Injectable({providedIn: 'root'})
export class DynamicFormBuilderService {

  constructor(private fb: FormBuilder) {
  }

  public patchForm(form: FormGroup, defaultValues: any, { fields }: DynamicFormGroup, emitEvent = true) {
    const values = Object.keys(defaultValues).reduce((acc, key) => {
      return Utils.isObject(defaultValues?.[key]?.[0])
        ? ({
          array: { ...acc.array, [key]: defaultValues[key] },
          others: { ...acc.others }
        })
        : ({
          array: { ...acc.array },
          others: { ...acc.others, [key]: defaultValues[key] }
        });
    }, { array: {}, others: {} });

    // Add array values.
    Object.keys(values.array).forEach((key) => {
      const fieldSettings = fields.find((field) => field.name === key) as DynamicFormArray;

      const controlsArray = defaultValues[key].map((value: any) => {
        const fg = this.buildForm(fieldSettings);

        fg.patchValue(value);

        return fg;
      });

      form.removeControl(key);
      form.addControl(key, this.fb.array(controlsArray));
    });

    // Add others values.
    form.patchValue(values.others, { emitEvent });
  }

  public buildForm(
    { fields, validators = [] }: DynamicFormGroup | DynamicFormArray,
    fg: FormGroup = this.fb.group({}),
    formValues?: any
  ) {
    if (validators) {
      fg.addValidators(validators);
    }

    fields.forEach((field: any) => fg?.addControl(field.name, this.buildFormControl(field, formValues?.[field.name])));

    return fg;
  }

  public buildFormControl(field: DynamicFormField, controlValue: any = null) {
    if (field.type === 'formArray') {
      return this.fb.array([this.buildForm(field, undefined, controlValue)]);
    }

    return this.fb.control(controlValue);
  }
}
