import { Directive, ViewContainerRef, OnInit, Input, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

import { ErrorMessageComponent } from './error-message.component';

@Directive({
  selector: '[appErrorMessage]',
})
export class ErrorMessageDirective implements OnInit {
  @Input() patternKey?: string;
  @Input() closestParent = 'form-group';
  @Input() showMessage = true;

  constructor(
    private ngControl: NgControl,
    public container: ViewContainerRef,
  ) {
  }

  ngOnInit(): void {
    const hostViewContainerRef = this.container;

    hostViewContainerRef.clear();

    const componentRef = hostViewContainerRef.createComponent(ErrorMessageComponent);

    componentRef.instance.ngControl = this.ngControl;
    componentRef.instance.patternKey = this.patternKey;
    componentRef.instance.closestParent = this.closestParent;
    componentRef.instance.showMessage = this.showMessage;
    componentRef.instance.container = this.container.element;
  }

  @HostListener('focusout')
  private onBlur() {
    this.ngControl.control?.updateValueAndValidity();
  }
}
