<div class="modal-form text-black">
  <div class="modal-form__title">
    {{ 'modal.apply_job.title' | translate }}
  </div>

  <form class="modal-form__main" [formGroup]="form" (submit)="submit()">
    <div class="modal-form__wrapper grid items-start">
      <div class="apply-job-info">
        <div class="apply-job-info__logo bg-white" *ngIf="job?.company?.logo">
          <img [src]="job?.company?.logo" [alt]="job.company.name">
        </div>

        <div class="apply-job-info__name font-semibold">
          {{vocabularies?.['position']?.terms[job?.position]?.title}}
        </div>

        <div class="block-salary bg-white" *ngIf="!job.salaryHidden || job.sharedOption || job.salaryBonus">
          <div class="block-salary__price" *ngIf="!job.salaryHidden">
            {{job.salaryFrom}}-{{job.salaryTo}}$
          </div>

          <div
            *ngIf="job.sharedOption || job.salaryBonus"
            class="block-salary__options"
            [class.block-salary--not-salary]="job.salaryHidden"
          >
            <div class="block-salary__option" *ngIf="job.sharedOption">
              <svg-icon src="moneybag.svg"></svg-icon>
              <span class="font-medium">
                {{ 'label.options' | translate }}
              </span>
            </div>
            <div class="block-salary__option" *ngIf="job.salaryBonus">
              <svg-icon src="bonuses.svg"></svg-icon>
              <span class="font-medium">
                {{ 'label.bonuses' | translate }}
              </span>
            </div>
          </div>

        </div>
      </div>

      <div class="row-cv-apply">
        <div class="form-group grid">
          <file-upload
            id="file-upload"
            formControlName="cv"
            [multiple]="false"
            [accept]="accept.join(',')"
            [animation]="false"
            class="file-upload"
          >
            <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
              <div *ngIf="control.size === 0">
                <div class="file-upload-text" *ngIf="isFileDragDropAvailable">
                  <div class="file-upload-text__title font-bold">
                    {{ 'modal.apply_job.upload_sub_title' | translate }}
                  </div>
                  <span class="cropper-input__sub-title">
                    {{ 'modal.apply_job.btn_upgrade_profile' | translate }}
                  </span>
                </div>
              </div>
            </ng-template>

            <ng-template let-i="index" let-file="file" let-control="control" #item>
              <div class="remove-file" (click)="control.removeFile(file)">
                <svg-icon src="close.svg"></svg-icon>
              </div>

              <div class="file-info">
                <svg-icon
                  class="flex text-white" src="picture.svg"
                  [ngStyle]="{ 'width.px': 20, 'height.px': 18 }"
                ></svg-icon>

                <span class="file-name">{{ file.name }}</span>
              </div>
            </ng-template>
          </file-upload>
        </div>
      </div>

      <div class="form-group">
        <label for="cover-letter">
          {{ 'modal.apply_job.cover_letter' | translate }}
        </label>
        <textarea id="cover-letter" class="yellow resize-none h-52 p-5" formControlName="coverLetter"></textarea>
      </div>
    </div>

    <div class="modal-form__action flex justify-end">
      <button
        [disabled]="form.invalid || form.disabled || form.pristine"
        type="submit"
        class="big-btn big-btn--small w-full bg-white justify-end"
      >
        {{ 'btn.save' | translate }}
      </button>
    </div>
  </form>

  <button class="modal-form__close" (click)="close()">
    <svg-icon src="close.svg"></svg-icon>
  </button>
</div>
