import { Container, MoveDirection, OutMode, StartValueType, tsParticles } from 'tsparticles-engine';
import { RecursivePartial } from 'tsparticles-engine/types/Types/RecursivePartial';
import { IOptions } from 'tsparticles-engine/types/Options/Interfaces/IOptions';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Subject, take, timer } from 'rxjs';
import { loadFull } from 'tsparticles';

@Injectable({
  providedIn: 'root'
})
export class ConfettiService implements OnDestroy {
  public particlesOptions: RecursivePartial<IOptions> = {
    autoPlay: false,
    background: {
      color: {
        value: 'transparent'
      }
    },
    fullScreen: {
      'zIndex': 1
    },
    fpsLimit: 120,
    emitters: [
      {
        'position': {
          'x': 0,
          'y': 30
        },
        'rate': {
          'quantity': 5,
          'delay': 0.15
        },
        'particles': {
          'move': {
            'direction': 'top-right',
            'outModes': {
              'top': 'none',
              'left': 'none',
              'default': 'destroy'
            }
          }
        }
      },
      {
        'position': {
          'x': 100,
          'y': 30
        },
        'rate': {
          'quantity': 5,
          'delay': 0.15
        },
        'particles': {
          'move': {
            'direction': 'top-left',
            'outModes': {
              'top': 'none',
              'right': 'none',
              'default': 'destroy'
            }
          }
        }
      }
    ],
    particles: {
      color: {
        'value': [
          '#ffffff',
          '#FF0000'
        ]
      },
      move: {
        'decay': 0.05,
        'direction': MoveDirection.top,
        'enable': true,
        'gravity': {
          'enable': true
        },
        'outModes': {
          'top': OutMode.none,
          'default': OutMode.destroy
        },
        'speed': {
          'min': 10,
          'max': 50
        }
      },
      number: {
        'value': 0
      },
      opacity: {
        'value': 1
      },
      rotate: {
        'value': {
          'min': 0,
          'max': 360
        },
        'direction': 'random',
        'animation': {
          'enable': true,
          'speed': 30
        }
      },
      tilt: {
        'direction': 'random',
        'enable': true,
        'value': {
          'min': 0,
          'max': 360
        },
        'animation': {
          'enable': true,
          'speed': 30
        }
      },
      size: {
        'value': {
          'min': 0,
          'max': 2
        },
        'animation': {
          'enable': true,
          'startValue': StartValueType.min,
          'count': 1,
          'speed': 16,
          'sync': true
        }
      },
      roll: {
        'darken': {
          'enable': true,
          'value': 25
        },
        'enable': true,
        'speed': {
          'min': 5,
          'max': 15
        }
      },
      wobble: {
        'distance': 30,
        'enable': true,
        'speed': {
          'min': -7,
          'max': 7
        }
      },
      shape: {
        'type': [
          'circle',
          'square',
          'image'
        ],
        'options': {
          'image': [
            {
              'src': '/assets/confetti/circle-1.png',
              'width': 32,
              'height': 32,
              'particles': {
                'size': {
                  'value': 16
                }
              }
            },
            {
              'src': '/assets/confetti/circle-2.png',
              'width': 32,
              'height': 32,
              'particles': {
                'size': {
                  'value': 16
                }
              }
            },
            {
              'src': '/assets/confetti/circle-3.png',
              'width': 32,
              'height': 32,
              'particles': {
                'size': {
                  'value': 16
                }
              }
            },
            {
              'src': '/assets/confetti/circle-4.png',
              'width': 32,
              'height': 32,
              'particles': {
                'size': {
                  'value': 16
                }
              }
            },
            {
              'src': '/assets/confetti/item-1.png',
              'width': 32,
              'height': 32,
              'particles': {
                'size': {
                  'value': 16
                }
              }
            },
            {
              'src': '/assets/confetti/item-2.png',
              'width': 32,
              'height': 32,
              'particles': {
                'size': {
                  'value': 16
                }
              }
            },
            {
              'src': '/assets/confetti/item-3.png',
              'width': 32,
              'height': 32,
              'particles': {
                'size': {
                  'value': 16
                }
              }
            },
            {
              'src': '/assets/confetti/item-4.png',
              'width': 32,
              'height': 32,
              'particles': {
                'size': {
                  'value': 16
                }
              }
            },
          ]
        }
      }
    },
    detectRetina: true
  };

  private container!: Container;
  private containerElement!: HTMLElement | null;

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    @Inject(PLATFORM_ID) protected platformId: string,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.initParticles().then();
    }
  }

  public async play(autoHide = 5000): Promise<void> {
    if (!this.container) {
      return;
    }

    this.containerElement?.classList.remove('hide');
    await this.container.refresh();
    this.container.play();

    timer(autoHide)
      .pipe(take(1))
      .subscribe(() => this.stop());
  }

  public stop(): void {
    if (!this.container) {
      return;
    }

    this.containerElement?.classList.add('hide');

    timer(1000)
      .pipe(take(1))
      .subscribe(() => this.container.stop());
  }

  private async initParticles(): Promise<void> {
    await loadFull(tsParticles);

    if (!this.container) {
      const container = await tsParticles.load('confetti-container', this.particlesOptions);

      this.containerElement = this.document.querySelector('#confetti-container');

      if (container) {
        this.container = container;
      }

      this.container.stop();
    }
  }

  ngOnDestroy(): void {
    if (this.container) {
      this.container.destroy();
    }
  }
}
