import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appMapValues'
})
export class MapValuesPipe implements PipeTransform {
  transform(value: any[], propertyName: string): any[] {
    return value.map(x => x[propertyName]);
  }
}
