export const talentStatuses = [
  {
    label: 'Active looking for a job',
    value: 'active'
  },
  {
    label: 'Passive looking for a job',
    value: 'passive'
  },
  {
    label: 'Not looking for a job',
    value: 'none'
  },
];
