import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';

import { SearchPageComponent } from './containers/search-page/search-page.component';
import { searchFeatureKey, searchReducer } from './reducers/search.reducer';
import { SearchRoutingModule } from './search-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { SearchEffects } from './effects/search.effects';

@NgModule({
  declarations: [
    SearchPageComponent,
  ],
  imports: [
    SearchRoutingModule,
    StoreModule.forFeature(searchFeatureKey, searchReducer),
    EffectsModule.forFeature([SearchEffects]),
    CommonModule,
    SharedModule
  ]
})
export class SearchModule {
}
