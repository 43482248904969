import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of, switchMap } from 'rxjs';

import { SearchService } from '../services/search.service';
import { SearchActions } from '../actions/search.actions';

@Injectable()
export class SearchEffects {
  constructor(
    private actions$: Actions,
    private searchService: SearchService
  ) {
  }

  getCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(SearchActions.getCompanies),
    switchMap(({ body }) => this.searchService.searchCompanies(body)),
    map((result) => SearchActions.getCompaniesSuccess({ result })),
    catchError((error) => of(SearchActions.getCompaniesFailure({ error })))
  ));

  getJobs$ = createEffect(() => this.actions$.pipe(
    ofType(SearchActions.getJobs),
    switchMap(({ body }) => this.searchService.searchJobs(body)),
    map((result) => SearchActions.getJobsSuccess({ result })),
    catchError((error) => of(SearchActions.getJobsFailure({ error })))
  ));

  getTalents$ = createEffect(() => this.actions$.pipe(
    ofType(SearchActions.getTalents),
    switchMap(({ body }) => this.searchService.searchTalents(body)),
    map((result) => SearchActions.getTalentsSuccess({ result })),
    catchError((error) => of(SearchActions.getTalentsFailure({ error })))
  ));
}
