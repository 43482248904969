import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { fadeInUpOnEnterAnimation, fadeOutDownOnLeaveAnimation, } from 'angular-animations';
import { Component, ElementRef, forwardRef, HostListener, Input } from '@angular/core';

import { salaryRangeValidator } from '../../validators/salary-range.validator';

@Component({
  selector: 'app-filter-salary-item',
  templateUrl: './filter-salary-item.component.html',
  styleUrls: ['./filter-salary-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterSalaryItemComponent),
      multi: true
    }
  ],
  animations: [
    fadeInUpOnEnterAnimation({duration: 404}),
    fadeOutDownOnLeaveAnimation({duration: 404})
  ]
})
export class FilterSalaryItemComponent implements ControlValueAccessor {
  @Input() title?: string;

  public opened = false;
  public rangeSalary: any = {};

  public form = this.formBuilder.group({
    salaryFrom: [null, [Validators.required]],
    salaryTo: [null, [Validators.required]],
  }, {
    validators: [
      salaryRangeValidator('salaryFrom', 'salaryTo', 'salaryTo')
    ]
  })

  private onTouched = () => {
  };
  private onChange = (m: any) => {
  };

  constructor(
    private formBuilder: FormBuilder,
    private elRef: ElementRef,
  ) {
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any): void {
    const clickedInside = this.elRef.nativeElement.parentElement.contains(target);

    if (!clickedInside) {
      this.opened = false;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.form.patchValue(value, {emitEvent: false, onlySelf: true});
  }

  public submit(): void {
    if (this.form.valid) {
      this.rangeSalary = this.form.value;
      this.onChange(this.form.value);
    }
  }

  public reset(): void {
    this.form.reset();
    this.rangeSalary = {};
    this.onChange(null);
  }
}
