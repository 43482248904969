import { ActivatedRoute, Params } from '@angular/router';
import { Action } from '@ngrx/store';

export class Utils {
  static capitalizeFirstLetter(val: string) {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }

  static filterData(data: any): any {
    if (Array.isArray(data)) {
      return data.filter(item => Utils.filterData(item))
    }

    if (typeof data === 'object' && data !== null) {
      return Object.keys(data).reduce((acc, key) => {
        const value = Utils.filterData(data[key]);

        if (value !== undefined && value !== null) {
          if (Array.isArray(value) && !value?.length) {
            return acc;
          }

          return { ...acc, [key]: value };
        } else {
          return acc;
        }
      }, {});
    }

    return data;
  }

  static isArraysEqual(first: any[], second: any[]): boolean {
    return first.length === second.length && first.every((value, index) => value === second[index]);
  }

  static isObjectEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  static isObject(obj: any): boolean {
    return obj !== null && typeof obj === 'object';
  }

  static objectKeysToArray(obj: any = {}, equals = true): string[] {
    return Object.keys(obj).filter(key => obj[key] === equals);
  }

  static getRequestName(action: Action): string {
    const matches = /(.*)\s(Request|Success|Failure)/.exec(action.type);

    return matches![1];
  }

  static getRequestsName(actions: Action[]): string[] {
    return actions.map(action => Utils.getRequestName(action));
  }

  static getYouTubeVideoId(str: string): any | null {
    const regex = /^(?:https?:\/\/(?:www\.)?)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = str.match(regex);

    if (match) {
      return match[1];
    }

    return null;
  }

  static getRouteParams(route: ActivatedRoute): Params {
    let child = route;
    let params = { ...child.snapshot.params };

    while (child?.firstChild) {
      child = child?.firstChild;
      params = { ...params, ...child.snapshot.params };
    }

    return params;
  }

  static getRouteDataByKey<T>(route: ActivatedRoute, key: string): T[] {
    let child = route;
    let data = [route.snapshot.data?.[key]];

    while (child?.firstChild) {
      child = child?.firstChild;
      data = [...data, child.snapshot.data?.[key]];
    }

    return data.filter(item => !!item);
  }
}
