<div
  class="event-columns event-columns--col-3 gap-2 items-start"
  [formGroup]="form"
>
  <span *ngIf="items.length === 0 && emptyText">{{emptyText}}</span>
  <app-checkbox
    *ngFor="let item of items"
    [label]="item[bindLabel]"
    [formControlName]="item[bindValue]"
    [counter]="counterField ? item[counterField] : null"
  ></app-checkbox>
</div>
