import { createAction, props } from '@ngrx/store';

import { IRequestError } from '../../models';

const changeLanguage = createAction('[Core] Change Language', props<{ language: string }>());
const setTheme = createAction('[Core] Set Theme', props<{ theme: string }>());

const clearError = createAction('[Core] Error Clear', props<{ error?: string[] }>());

const toggleMainNav = createAction('[Core] Toggle Main Navigation', props<{ mainNavOpened: boolean }>());

const getVocabularies = createAction('[Core] Get Vocabularies Request');
const getVocabulariesSuccess = createAction('[Core] Get Vocabularies Success', props<{ vocabularies: any[] }>());
const getVocabulariesFailure = createAction('[Core] Get Vocabularies Failure', props<IRequestError>());

const apiCall = createAction('[Core] API Call Request');
const apiCallSuccess = createAction('[Core] API Call Success');
const apiCallFailure = createAction('[Core] API Call Failure', props<IRequestError>());

const patchingEntity = createAction('[Core] Patching Entity', props<{ id: string }>());
const patchingEntityFinished = createAction('[Core] Patching Entity Finished', props<{ id: string }>());

const deletingEntity = createAction('[Core] Deleting Entity', props<{ id: string }>());
const deletingEntityFinished = createAction('[Core] Deleting Entity Finished', props<{ id: string }>());

export const CoreActions = {
  changeLanguage,
  setTheme,

  clearError,

  toggleMainNav,

  getVocabularies,
  getVocabulariesSuccess,
  getVocabulariesFailure,

  apiCall,
  apiCallSuccess,
  apiCallFailure,

  patchingEntity,
  patchingEntityFinished,

  deletingEntity,
  deletingEntityFinished,
}
