import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';

import { JobsListPageComponent } from './containers/jobs-list-page/jobs-list-page.component';
import { jobFeatureKey, jobReducer } from './reducers/jobs.reducer';
import { JobsRoutingModule } from './jobs-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { JobsEffects } from './effects/jobs.effects';

@NgModule({
  declarations: [
    JobsListPageComponent,
  ],
  imports: [
    CommonModule,
    JobsRoutingModule,
    SharedModule,
    StoreModule.forFeature(jobFeatureKey, jobReducer),
    EffectsModule.forFeature([JobsEffects]),
  ]
})
export class JobsModule {
}
