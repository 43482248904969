<app-smooth-height
  class="wrapper-select"
  [class.is-empty]="!selectFC.value?.length"
  [trigger]="selectFC.value"
>
  <div
    class="flex flex-wrap gap-2 items-start multiple-select-labels"
    [ngClass]="{ 'yellow': color === 'yellow', 'dark': color === 'black' }"
    [class.disabled]="selectFC.disabled"
  >
    <span
      *ngFor="let id of selectFC.value; let first = first"
      (click)="removeTag(id)"
      class="label-select cursor-pointer"
      [ngClass]="{ 'yellow': color === 'yellow', 'dark': color === 'black' }"
      [class.disabled]="selectFC.disabled"
    >
      {{getTag(id)?.[bindLabel]}}
      <svg-icon src="close.svg"></svg-icon>
    </span>
  </div>
</app-smooth-height>

<ng-select
  appOpenSelect
  class="select"
  [ngClass]="{ 'yellow': color === 'yellow', 'dark': color === 'black' }"
  [searchable]="true"
  [clearable]="false"
  [hideSelected]="false"
  [maxSelectedItems]="maxSelectedItems"
  [closeOnSelect]="closeOnSelect"
  [items]="items"
  [labelForId]="labelForId"
  [formControl]="selectFC"
  [multiple]="true"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [addTag]="false"
  (blur)="onBlur()"
>
  <ng-template ng-label-tmp></ng-template>

  <ng-container *ngIf="optionTemplate">
    <ng-template ng-option-tmp let-item="item">
      <ng-container *ngTemplateOutlet="optionTemplate; context: {item: item, label: bindLabel}"></ng-container>
    </ng-template>
  </ng-container>
</ng-select>
