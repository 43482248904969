import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject, filter, Subject, takeUntil, tap } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';

import { DynamicFormBuilderService } from '../../services/dynamic-form-builder.service';
import { ModalFilterComponent } from '../modal-filter/modal-filter.component';
import { DynamicFormGroup } from '../../../models/dynamic-form-control.type';
import { ModalService } from '../../modules/modal/services/modal.service';
import { UrlQueryService } from '../../services/url-query.service';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
})
export class SearchFormComponent implements OnInit, OnChanges {
  @Input() theme!: any;
  @Input() vocabularies!: any;
  @Input() aggregations: any;
  @Input() foundItemCount: any;
  @Input() formStructure!: DynamicFormGroup;

  @Output() filtersChange = new EventEmitter();

  public form = this.fb.group({});

  private modalData$ = new BehaviorSubject({ aggregations: null, foundItemCount: null });

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private urlQueryService: UrlQueryService,
    private dynamicFormBuilderService: DynamicFormBuilderService
  ) {
  }

  ngOnInit() {
    this.dynamicFormBuilderService.buildForm(this.formStructure, this.form);
  }

  ngOnChanges({ vocabularies, aggregations, foundItemCount }: SimpleChanges) {
    if (vocabularies?.currentValue && !vocabularies?.previousValue) {
      const formValues = this.urlQueryService.prepareVocabularyTermsToForm(
        this.activatedRoute.snapshot.queryParams,
        this.vocabularies,
        this.formStructure
      );

      // We need to rebuild the form with default values here
      // because this.dynamicFormBuilderService.patchForm doesn't work with SSR.
      this.form = this.dynamicFormBuilderService.buildForm(this.formStructure, undefined, formValues);

      this.form.valueChanges
        .pipe(
          takeUntil(this.unsubscribe$),
          map(values => this.urlQueryService.prepareVocabularyTermsToQuery(values, this.vocabularies))
        )
        .subscribe((values) => {
          this.filtersChange.emit(this.form.value);
          this.urlQueryService.saveFormValuesToQueryParams(values);
        });

      this.filtersChange.emit(formValues);
    }

    if (aggregations?.currentValue || foundItemCount?.currentValue) {
      this.modalData$.next({ aggregations: this.aggregations, foundItemCount: this.foundItemCount});
    }
  }

  public openModal() {
    this.modalService.addModal(
      ModalFilterComponent, {
        data$: this.modalData$.asObservable(),
        vocabularies: this.vocabularies,
        formStructure: this.formStructure,
        theme: this.theme,
        defaultValues: this.form.value
      }, {
        wrapperDefaultClasses: 'custom-modal text-white dark:text-black'
      })
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(value => !!value),
        tap(values => this.dynamicFormBuilderService.patchForm(this.form, values, this.formStructure)),
        map(values => this.urlQueryService.prepareVocabularyTermsToQuery(values, this.vocabularies))
      )
      .subscribe((values) => {
        this.filtersChange.emit(this.form.value);
        this.urlQueryService.saveFormValuesToQueryParams(values);
      });
  }
}
