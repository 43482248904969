import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function salaryRangeValidator(fromField: string, toField: string, fieldForError: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const from = control.get(fromField)?.value;
    const to = control.get(toField)?.value;

    if ((from !== null && to !== null) && from > to) {
      if (fieldForError) {
        control.get(fieldForError)?.setErrors({invalidSalaryRange: true});
      }

      return fieldForError ? null : {invalidSalaryRange: true};
    }

    return null;
  };
}
