import { NgxPopperjsModule, NgxPopperjsPlacements } from 'ngx-popperjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'swiper/angular';
import { DateFnsModule } from 'ngx-date-fns';
import { NgPipesModule } from 'ngx-pipes';
import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';

import {
  ModalCompanyNotVerifiedComponent
} from './components/modal-company-not-verified/modal-company-not-verified.component';
import {
  MultipleImageUploadDndComponent
} from './components/multiple-image-upload-dnd/multiple-image-upload-dnd.component';
import { TalentJobSummaryListComponent } from './components/talent-job-summary-list/talent-job-summary-list.component';
import { LocationAutocompleteComponent } from './components/location-autocomplete/location-autocomplete.component';
import { MultipleImageUploadComponent } from './components/multiple-image-upload/multiple-image-upload.component';
import { ModalChangePasswordComponent } from './components/modal-change-password/modal-change-password.component';
import { ModalCongratulationComponent } from './components/modal-congratulation/modal-congratulation.component';
import { ModalNeedRegisterComponent } from './components/modal-need-register/modal-need-register.component';
import { UserAvatarCropperComponent } from './components/user-avatar-cropper/user-avatar-cropper.component';
import { FilterSalaryItemComponent } from './components/filter-salary-item/filter-salary-item.component';
import { RegistrationInfoComponent } from './components/registration-info/registration-info.component';
import { AccordionTalentComponent } from './components/accordion-talent/accordion-talent.component';
import { MultipleSelectComponent } from './components/multiple-select/multiple-select.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { LoaderProgressComponent } from './components/loader-progress/loader-progress.component';
import { ModalApplyJobComponent } from './components/modal-apply-job/modal-apply-job.component';
import { ButtonLoadingComponent } from './components/button-loading/button-loading.component';
import { AccordionTextComponent } from './components/accordion-text/accordion-text.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { BlockYouTubeComponent } from './components/block-you-tube/block-you-tube.component';
import { ModalCropperComponent } from './components/modal-cropper/modal-cropper.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { LoaderCircleComponent } from './components/loader-circle/loader-circle.component';
import { SmoothHeightComponent } from './components/smooth-height/smooth-height.component';
import { AuthSocialsComponent } from './components/auth-socials/auth-socials.component';
import { EllipseMenuComponent } from './components/ellipse-menu/ellipse-menu.component';
import { TooltipInfoComponent } from './components/tooltip-info/tooltip-info.component';
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ModalFilterComponent } from './components/modal-filter/modal-filter.component';
import { SalaryFieldComponent } from './components/salary-field/salary-field.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { FilterItemComponent } from './components/filter-item/filter-item.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LoaderDotsComponent } from './components/loader-dots/loader-dots.component';
import { ErrorMessageModule } from './modules/error-message/error-message.module';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { TalentRowComponent } from './components/talent-row/talent-row.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { ProgressComponent } from './components/progress/progress.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { BreadcrumbsModule } from './modules/breadcrumbs/breadcrumbs.module';
import { JobCardComponent } from './components/job-card/job-card.component';
import { ConnectFormDirective } from './directives/connect-form.directive';
import { ProcessSearchItemsPipe } from './pipes/process-search-items.pipe';
import { IntersectionByKeyPipe } from './pipes/intersection-by-key.pipe';
import { JobRowComponent } from './components/job-row/job-row.component';
import { OpenSelectDirective } from './directives/open-select.directive';
import { SwitchComponent } from './components/switch/switch.component';
import { ResetFormDirective } from './directives/reset-form.directive';
import { LineClampDirective } from './directives/line-clamp.directive';
import { EditorComponent } from './components/editor/editor.component';
import { SaveFormDirective } from './directives/save-form.directive';
import { HasRolesDirective } from './directives/has-roles.directive';
import { defaultModalOptions } from './modules/modal/modal-options';
import { PagerComponent } from './components/pager/pager.component';
import { IncludesActionsPipe } from './pipes/includes-actions.pipe';
import { CountUpDirective } from './directives/count-up.directive';
import { CounterDirective } from './directives/counter.directive';
import { AmplitudeService } from './services/amplitude.service';
import { FileToImagePipe } from './pipes/file-to-image.pipe';
import { ModalModule } from './modules/modal/modal.module';
import { HasValuesPipe } from './pipes/has-values.pipe';
import { MapValuesPipe } from './pipes/map-values.pipe';
import { UniqueByPipe } from './pipes/unique-by.pipe';
import { SafePipe } from './pipes/safe.pipe';

const SHARED_COMPONENTS = [
  ModalCompanyNotVerifiedComponent,
  MultipleImageUploadDndComponent,
  LocationAutocompleteComponent,
  TalentJobSummaryListComponent,
  MultipleImageUploadComponent,
  ModalChangePasswordComponent,
  ModalCongratulationComponent,
  ModalNeedRegisterComponent,
  UserAvatarCropperComponent,
  RegistrationInfoComponent,
  FilterSalaryItemComponent,
  AccordionTalentComponent,
  NavigationMenuComponent,
  MultipleSelectComponent,
  LoaderProgressComponent,
  ModalApplyJobComponent,
  ButtonLoadingComponent,
  AccordionTextComponent,
  CheckboxGroupComponent,
  ProcessSearchItemsPipe,
  BlockYouTubeComponent,
  IntersectionByKeyPipe,
  ClickOutsideDirective,
  SmoothHeightComponent,
  LoaderCircleComponent,
  ModalCropperComponent,
  ModalConfirmComponent,
  AuthSocialsComponent,
  TooltipInfoComponent,
  CustomModalComponent,
  ConnectFormDirective,
  ImageUploadComponent,
  ModalFilterComponent,
  EllipseMenuComponent,
  SalaryFieldComponent,
  SearchFormComponent,
  FileUploadComponent,
  LoaderDotsComponent,
  IncludesActionsPipe,
  OpenSelectDirective,
  FilterItemComponent,
  DatePickerComponent,
  LineClampDirective,
  PageTitleComponent,
  ResetFormDirective,
  TalentRowComponent,
  DateRangeComponent,
  HasRolesDirective,
  ProgressComponent,
  JobCardComponent,
  SaveFormDirective,
  CheckboxComponent,
  UserMenuComponent,
  CounterDirective,
  CountUpDirective,
  EditorComponent,
  FileToImagePipe,
  SwitchComponent,
  JobRowComponent,
  PagerComponent,
  HasValuesPipe,
  MapValuesPipe,
  UniqueByPipe,
  SafePipe
];

@NgModule({
  imports: [
    AngularSvgIconModule,
    ReactiveFormsModule,
    YouTubePlayerModule,
    ErrorMessageModule,
    ImageCropperModule,
    BreadcrumbsModule,
    NgScrollbarModule,
    FileUploadModule,
    TranslateModule,
    NgSelectModule,
    DragDropModule,
    NgPipesModule,
    DateFnsModule,
    CommonModule,
    RouterModule,
    SwiperModule,
    FormsModule,
    QuillModule.forRoot({
      formats: ['bold', 'italic', 'underline', 'link', 'list'],
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline', {'list': 'bullet'}, 'link'],
        ]
      }
    }),
    ModalModule.forRoot(
      {container: 'body'},
      {
        ...defaultModalOptions, ...{
          closeOnEscape: true,
          closeOnClickOutside: true,
          wrapperDefaultClasses: 'custom-modal',
          wrapperClass: 'custom-modal--fade-in shadow',
          animationDuration: 500,
          autoFocus: true,
          autoClose: false,
        }
      }
    ),
    NgxPopperjsModule.forRoot({
      placement: NgxPopperjsPlacements.TOP
    }),
  ],
  exports: [
    ...SHARED_COMPONENTS,
    AngularSvgIconModule,
    ReactiveFormsModule,
    YouTubePlayerModule,
    ImageCropperModule,
    ErrorMessageModule,
    BreadcrumbsModule,
    NgxPopperjsModule,
    NgScrollbarModule,
    FileUploadModule,
    TranslateModule,
    NgSelectModule,
    DragDropModule,
    NgPipesModule,
    DateFnsModule,
    CommonModule,
    RouterModule,
    SwiperModule,
    QuillModule,
    ModalModule,
    FormsModule,
  ],
  declarations: [
    ...SHARED_COMPONENTS
  ],
  providers: [
    AmplitudeService
  ]
})
export class SharedModule {
}
