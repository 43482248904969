import { NgSelectComponent } from '@ng-select/ng-select';
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOpenSelect]'
})
export class OpenSelectDirective {

  @HostListener('click', ['$event']) onClick(event: Event | any): void {
    if (event.target.nodeName === 'NG-SELECT') {
      event.stopPropagation();
      event.preventDefault();
      this.select.open();
    }
  }

  constructor(
    private select: NgSelectComponent,
  ) {
  }
}
