import { createReducer, on } from '@ngrx/store';

import { JobsActions } from '../../jobs/actions/job.actions';
import { SearchActions } from '../actions/search.actions';

export const searchFeatureKey = 'search';

export interface SearchResult<T = any> {
  meta: {
    page: number,
    take: number,
    itemCount: number,
    pageCount: number,
    hasPreviousPage: boolean,
    hasNextPage: boolean
  },
  aggregations: any,
  data: T[];
}

export interface State {
  companies: Partial<SearchResult>,
  jobs: Partial<SearchResult>,
  talents: Partial<SearchResult>,
}

export const initialState: State = {
  companies: {},
  talents: {},
  jobs: {},
};

export const searchReducer = createReducer(
  initialState,

  on(SearchActions.getCompaniesSuccess, (state, { result }) => ({
    ...state,
    companies: result
  })),

  on(SearchActions.getJobsSuccess, (state, { result }) => ({
    ...state,
    jobs: result
  })),

  on(JobsActions.feedbackToJobSuccess, (state, {feedback}) => ({
    ...state,
    jobs: {
      ...state.jobs,
      data: state?.jobs?.data?.map((job: any) => {
        if (job.slug === feedback.job.slug) {
          return {
            ...job,
            appliedAt: new Date().toISOString(),
            isApplied: true
          }
        }
        return job;
      })
    }
  })),

  on(SearchActions.getTalentsSuccess, (state, { result }) => ({
    ...state,
    talents: result
  })),
);

