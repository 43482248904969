<div
  class="user-menu"
  [class.opened]="isOpenedUserMenu"
  appClickOutside (clickOutside)="isOpenedUserMenu = false"
>
  <div class="user-menu__main" (click)="isOpenedUserMenu = !isOpenedUserMenu">
    <span class="flex relative">
      <svg-icon src="person.svg"></svg-icon>

      <span class="user-menu__is-admin bg-yellow text-yellow" *appHasRoles="[roles.COMPANY_ADMIN]">
        <svg-icon src="icon-star.svg"></svg-icon>
      </span>
    </span>

    <span class="user-menu__name truncate select-none">{{userName}}</span>

    <svg-icon src="arrow-down.svg" [ngStyle]="{ 'width.px': 6}"></svg-icon>
  </div>

  <div
    class="dropdown-list bg-white dark:bg-black ring-alabaster dark:ring-white/30"
    [class.dropdown-list--top]="userType === 'talent'"
    *ngIf="isOpenedUserMenu"
    @fadeInOnEnter
    @fadeOutOnLeave
  >
    <div class="dropdown-list__arrow text-white dark:text-black"></div>

    <ul class="dropdown-list__items">
      <ng-container *ngIf="navigation && navigation.length > 0">
        <li *ngFor="let item of navigation">
          <a
            [routerLink]="item.url"
            routerLinkActive="active"
            class="text-gray dark:text-white ring-alabaster dark:ring-white/10"
          >
            {{ item.name | translate }}
          </a>
        </li>
      </ng-container>

      <li class="line"></li>
      <li>
        <a
          class="cursor-pointer text-gray dark:text-white ring-alabaster dark:ring-white/10"
          routerLink="logout"
        >
          {{ 'second_navigation.logout' | translate }}
        </a>
      </li>
    </ul>
  </div>
</div>
