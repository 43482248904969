import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { Injectable, QueryList } from '@angular/core';
import { Subject } from 'rxjs';

import { Utils } from '../../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {
  private clearFormSource = new Subject<boolean | null>();

  public clearForm$ = this.clearFormSource.asObservable();

  constructor() {
  }

  public clearForm(): void {
    this.clearFormSource.next(true);

    setTimeout(() => {
      this.clearFormSource.next(null);
    });
  }

  public getFormInfo(formGroup: FormGroup, percents: number): any {
    const controls = this.flattenControls(formGroup);

    const filledControls = controls.filter(({value}) => {
      const isFilledObject = Utils.isObject(value) ? !Utils.isObjectEmpty(value) : true;

      return value !== null && value !== '' && (value.length > 0 || isFilledObject);
    });

    const filledRequired = controls.filter((ctrl) => {
      const { value } = ctrl;

      return ctrl.hasValidator(Validators.required) &&
        (value === null || value === '' || value.length === 0);
      }
    );

    return {
      percents: this.percentage(percents, filledControls.length, controls.length),
      filled: !filledRequired.length,
    };
  }

  private flattenControls(formGroup: FormGroup): AbstractControl[] {
    const controlsValues = Object.values(formGroup.controls);
    const controls: AbstractControl[] = [];

    function addControls(control: AbstractControl) {
      if (control instanceof FormGroup) {
        Object.values(control.controls).forEach(addControls);
      } else if (control instanceof FormArray) {
        control.controls.forEach(addControls);
      } else {
        controls.push(control);
      }
    }

    controlsValues.forEach(addControls);
    return controls;
  }

  private percentage(weight: number, partialValue: number, totalValue: number): number {
    return (weight * partialValue) / totalValue;
  }

  public getFilledPercentage(forms: QueryList<any>) {
    let total = 0;
    const formsInfo: any = {};

    forms.forEach((component: any) => {
      if (component.form) {
        const { percents, filled } = this.getFormInfo(component.form, component.formWeight || 20);

        if (component.formName) {
          formsInfo[component.formName] = {
            percents,
            filled,
          };
        }

        total += percents;
      } else {
        total += component.formWeight || 0;
      }
    });

    return {
      items: formsInfo,
      totalPercents: Math.floor(total)
    };
  }

}
