export const languages = [
  {
    name: 'EN',
    value: 'en'
  },
  {
    name: 'UK',
    value: 'uk'
  }
];
