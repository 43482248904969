import { map, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as format from 'string-template';
import { Observable } from 'rxjs';

import { TokenResolver } from '../../../shared/modules/breadcrumbs/types/token-resolver.interface';
import { selectSelectedCompany } from '../selectors/company.selectors';

@Injectable({
  providedIn: 'root'
})
export class CompanyTokenResolver implements TokenResolver {
  static readonly COMPANY_NAME = 'COMPANY_NAME';

  constructor(
    private store: Store,
  ) {
  }

  public resolve(text$: Observable<string>): Observable<string> {
    return text$.pipe(
      switchMap(text => this.store.pipe(
        select(selectSelectedCompany),
        map((company) => format(
          text,
          { [CompanyTokenResolver.COMPANY_NAME]: company?.name, }
        )),
      ))
    );
  }
}
