<div [class.app-container]="navigationSettings?.showNavigation" class="flex flex-col grow">
  <div class="app-container__navigation sticky top-0 flex z-10 lg:static" *ngIf="navigationSettings?.showNavigation">
    <app-navigation-menu
      [navigation]="mainNav"
      [opened]="mainNavOpened"
      [secondaryNav]="secondaryNav"
      [language]="language"
      [user]="user"
      (toggle)="toggleMainNav.emit($event)"
      class="text-black"
    ></app-navigation-menu>
  </div>

  <header class="container-fluid" *ngIf="!navigationSettings?.showNavigation">
    <a
      [href]="landingURL"
      class="logo"
      aria-label="Logo"
    >
      <svg-icon src="logo-icon.svg" class="logo__icon"></svg-icon>
      <svg-icon src="logo-text.svg" class="logo__text"></svg-icon>
    </a>

    <label [attr.for]="'language-switcher'">
      <ng-select
        class="language-select font-semibold text-black dark:text-white"
        [formControl]="languageSwitcher"
        (change)="languageChange.emit($event.value)"
        [items]="languagesList"
        labelForId="language-switcher"
        [multiple]="false"
        [clearable]="false"
        [searchable]="false"
        bindLabel="name"
        bindValue="value">
      </ng-select>
    </label>

    <div class="flex gap-3 ml-auto" *ngIf="navigationSettings?.showLoginButton">
      <button class="btn-small bg-yellow text-black" routerLink="login">
        Login
      </button>
      <button class="btn-small bg-yellow text-black" routerLink="sign-up/talent">
        Sign Up
      </button>
    </div>
  </header>

  <div class="app-container__main">
    <div class="app-container-header pt-6 pb-3" *ngIf="navigationSettings?.showNavigation">
      <div class="app-container-header__info lg:mr-auto">
        <app-breadcrumbs></app-breadcrumbs>

        <app-page-title></app-page-title>
      </div>

      <button
        *ngIf="user?.type === 'company' && user?.company?.verified"
        class="btn-add-vacancy text-yellow max-w-[180px] w-full md:ml-auto text-sm self-center md:self-auto"
        [routerLink]="'company/' + user?.company?.slug + '/post-job'"
        (click)="postJob()"
      >
        {{ 'btn.add_new_job' | translate }}
      </button>

      <label [attr.for]="'language-switcher'" class="hidden lg:flex relative z-10 not-opacity">
        <ng-select
          class="language"
          [formControl]="languageSwitcher"
          (change)="languageChange.emit($event.value)"
          [items]="languagesList"
          labelForId="language-switcher"
          [multiple]="false"
          [clearable]="false"
          [searchable]="false"
          bindLabel="name"
          bindValue="value">
        </ng-select>
      </label>

      <div class="app-container-header__action hidden lg:flex">
        <app-user-menu
          class="hidden lg:flex"
          [navigation]="secondaryNav"
          [userName]="user?.fullName || user?.email"
        ></app-user-menu>
      </div>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>

<div id="confetti-container" class="fixed inset-0 pointer-events-none w-full h-full hide"></div>
