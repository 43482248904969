import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-job-row',
  templateUrl: './job-row.component.html',
  styleUrls: ['./job-row.component.scss']
})
export class JobRowComponent {
  @Input() job!: any;
  @Input() vocabularies: any;

  @Output() feedbackToJob = new EventEmitter();

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor() {
  }

  // @TODO: move to pipe.
  public joinTerms(vocabulary: string, separator = ', '): string {
    return this?.job && this?.job[vocabulary] ?
      this?.job[vocabulary]
        .map((term: any) => this?.vocabularies?.[vocabulary]?.terms?.[term]?.title)
        .join(separator)
      : '';
  }

  public applyJob(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.feedbackToJob.emit({job: this.job});
  }
}
