import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { of, tap } from 'rxjs';

import { CompanyActions } from '../../modules/company/actions/company.actions';
import { TalentActions } from '../../modules/talent/actions/talent.actions';
import { JobsActions } from '../../modules/jobs/actions/job.actions';
import { CoreService } from '../services/core.service';
import { CoreActions } from '../actions/core.actions';

@Injectable()
export class CoreEffects {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private translateService: TranslateService,
    private coreService: CoreService,
    private actions$: Actions,
    private router: Router,
  ) {
  }

  changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.changeLanguage),
      tap(({language}) => {
        const {currentLang} = this.translateService;

        if (!currentLang || currentLang !== language) {
          this.translateService.use(language);
        }
      })
    ), {dispatch: false}
  );

  setTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.setTheme),
      tap(({theme}) => {
        const html = this.document.documentElement;
        html.setAttribute('data-mode', theme);
      })
    ), {dispatch: false}
  );

  getVocabularies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.getVocabularies),
      exhaustMap(() =>
        this.coreService.getVocabularies().pipe(
          map((vocabularies) => CoreActions.getVocabulariesSuccess({vocabularies})),
          catchError(({error}) => of(CoreActions.getVocabulariesFailure({error})))
        )
      )
    )
  );

  entityLoadFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CompanyActions.getCompanyFailure,
        TalentActions.getTalentFailure,
        JobsActions.getJobFailure,
      ),
      tap(() => this.router.navigate(['/not-found'])),
    ), {dispatch: false}
  );
}
