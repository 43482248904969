import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { skip, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true
    }
  ]
})
export class EditorComponent implements ControlValueAccessor, OnInit {
  @Input() classList?: any;
  @Input() maxlength = 2500;
  @Input() disabled = false;
  @Input() showCounter = true;
  @Input() height = 'h-52';

  public count = 0;

  public editorFC = this.formBuilder.control('');

  public onTouched = () => {
  };
  private onChange = (m: any) => {
  };

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.editorFC.valueChanges
      .pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(val => this.onChange(val));
  }

  public counter(event: any): void {
    const {html} = event;

    this.count = html ? html.length : 0;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.count = value ? value.length : 0;
    this.editorFC.patchValue(value, {emitEvent: false, onlySelf: true});
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changedStatus() {
    this.onTouched();

    if (!this.editorFC.value) {
      this.onChange(this.editorFC.value);
    }
  }
}
