export enum JobStatus {
  OPEN = 'open',
  CLOSED = 'closed',
  DRAFT = 'draft',
}

export const jobStatuses = [
  {
    label: 'Open',
    value: 'open'
  },
  {
    label: 'Draft',
    value: 'draft',
    disabled: true
  },
  {
    label: 'Closed',
    value: 'closed'
  },
];
