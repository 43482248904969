import { fadeInUpOnEnterAnimation, fadeOutDownOnLeaveAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { Component, ElementRef, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterItemComponent),
      multi: true
    }
  ],
  animations: [
    fadeInUpOnEnterAnimation({duration: 404}),
    fadeOutDownOnLeaveAnimation({duration: 404}),
    fadeOutOnLeaveAnimation({duration: 404})
  ]
})
export class FilterItemComponent implements OnInit, ControlValueAccessor {
  @Input() items!: any[];
  @Input() title?: string;
  @Input() bindLabel: string = 'title';
  @Input() bindValue: string = 'id';
  @Input() labelForId?: string;

  public opened = false;
  public selectFC = this.formBuilder.control([]);

  private onTouched = () => {
  };
  private onChange = (m: any) => {
  };

  @AutoUnsubscribe()
  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private elRef: ElementRef,
  ) {
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any): void {
    const clickedInside = this.elRef.nativeElement.parentElement.contains(target);

    if (!clickedInside) {
      this.opened = false;
    }
  }

  ngOnInit(): void {
    this.selectFC.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.onChange(value));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.selectFC.patchValue(value, {emitEvent: false, onlySelf: true});
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.selectFC.disable() : this.selectFC.enable();
  }

  public getTag(id: string): any {
    return this.items?.find(item => item[this.bindValue] === id);
  }

  public removeAll(): void {
    this.selectFC.patchValue(null);
  }

  public removeTag(target: any, tagId: string): void {
    if (this.selectFC.disabled) {
      return;
    }

    target.classList.add('remove');

    const newValue: any = this.selectFC.value?.filter(id => id !== tagId);
    this.selectFC.patchValue(newValue);
  }
}
