<div class="modal-form modal-form--small modal-form--small-py z-50 mx-auto bg-yellow text-black">
  <div class="modal-form__icon">
    <img src="/assets/smiles/folded-hands.png" alt="Icon">
  </div>

  <div class="modal-confirm__content py-5 max-w-[350px] mx-auto text-center">
    {{message | translate}}
  </div>

  <div class="modal-form__action gap-4 flex flex-col md:flex-row justify-center">
    <button
      routerLink="/login"
      (click)="close()"
      class="big-btn big-btn--small bg-black text-white max-w-none md:flex-grow"
    >
      {{'btn.sign_in' | translate}}
    </button>
  </div>
</div>
