<div class="job-row" [routerLink]="['/company', job.company.slug, 'jobs', job.slug]">
  <div class="job-row__date">
    {{ job?.createdAt | dfnsParseIso | dfnsFormat: 'dd.MM.yyyy'}}
  </div>

  <div class="job-row__name flex flex-col">
    {{ job?.company?.name }}
  </div>

  <div class="job-row__item flex flex-col">
    <span>{{ vocabularies?.position?.terms?.[job.position]?.title }}</span>
  </div>

  <div class="job-row__item">
    <ng-container *ngIf="!job?.salaryHidden; else salaryHidden">
      {{ job?.salaryFrom }} - {{ job?.salaryTo }}$
    </ng-container>
    <ng-template #salaryHidden>
      —
    </ng-template>
  </div>

  <div class="job-row__location flex items-center gap-2">
    <svg-icon src="location.svg"></svg-icon>
    {{ job?.location?.city }}
  </div>

  <div class="job-row__item">
    {{ joinTerms('sphere') }}
  </div>

  <div class="job-row__item">
    {{ joinTerms('typeOfWork') }}
  </div>

  <button
    [disabled]="job.isApplied"
    class="btn-small job-row__btn text-black"
    (click)="applyJob($event)"
  >
    <ng-container *ngIf="!job.isApplied; else alreadyApplied">
      {{ 'talent.dashboard.apply' | translate }}
    </ng-container>

    <ng-template #alreadyApplied>
      {{ 'btn.search.applied' | translate }}
    </ng-template>
  </button>

  <!--@TODO: Version 2.1-->
  <div class="job-row__favorite" *ngIf="false">
    <svg-icon src="heart.svg"></svg-icon>
  </div>
</div>
