import { Component, ElementRef, HostListener } from '@angular/core';
import { Observable, Observer, BehaviorSubject } from 'rxjs';

import { ModalOptions } from '../modal-options';

@Component({template: ''})
export abstract class ModalComponent<Input, Output> {
  public handle: ElementRef | undefined;
  public result!: Output;
  public wrapper!: ElementRef;
  public options!: ModalOptions;
  public optionsOverride?: ModalOptions;
  public _ready$ = new BehaviorSubject<boolean>(false);

  protected observer!: Observer<Output>;
  protected closerCallback: (component: any) => Promise<any> = () => Promise.resolve();

  protected constructor() {
  }

  mapDataObject(data: Input): void {
    data = data || <Input>{};
    const keys = Object.keys(data);

    for (let i = 0, length = keys.length; i < length; i++) {
      const key = keys[i];

      // @ts-ignore
      if (data[key] && this[key] && typeof data[key] === 'object' && typeof this[key] === 'object') {
        // @ts-ignore
        Object.assign(this[key], data[key]);
      } else {
        // @ts-ignore
        this[key] = data[key];
      }
    }
  }

  setupObserver(): Observable<Output> {
    return Observable.create((observer: any) => {
      this.observer = observer;

      return () => {
        this.close();
      };
    });
  }

  onClosing(callback: (component: ModalComponent<any, any>) => Promise<any>): void {
    this.closerCallback = callback;
  }

  close(): Promise<any> {
    return this.closerCallback(this).then(cb => {
      if (this.observer) {
        this.observer.next(this.result);
        this.observer.complete();
      }
      return cb;
    });
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(evt: KeyboardEvent) {
    if (this.options && this.options.closeOnEscape) {
      this.close();
    }
  }

  get ready$() {
    return this._ready$.asObservable();
  }

  markAsReady() {
    this._ready$.next(true);
  }
}
