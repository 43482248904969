import { ElementRef, Inject, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Pipe({
  name: 'fileToImage'
})
export class FileToImagePipe implements PipeTransform {
  private readonly isBrowser = isPlatformBrowser(this.platformId);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private elRef: ElementRef,
  ) {
  }

  transform(value: Blob, ...args: unknown[]): any {
    if (this.isBrowser && value) {
      const fr = new FileReader();
      fr.onload = (e) => {
        this.elRef.nativeElement.src = e.target?.result;
      };
      fr.readAsDataURL(value);
    }
  }
}
