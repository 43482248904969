import { TranslateService } from '@ngx-translate/core';
import { ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';

import { regEx, requiredRegex } from '../../../config';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  private errorMessage!: string;

  constructor(
    private translateService: TranslateService
  ) {
  }

  public getErrorMessage(controlErrors: ValidationErrors | null, patternKey?: string): string {
    const lastError = controlErrors && Object.entries(controlErrors).pop();
    if (lastError) {
      const lastErrorType = typeof lastError[1] !== 'boolean';
      lastErrorType
        ? this.setErrorMessage(lastError[0], lastError[1], patternKey)
        : this.setErrorMessage(lastError[0]);
    }
    return this.errorMessage;
  }

  private setErrorMessage(
    key: string,
    value?: Record<string, unknown> | undefined,
    patternKey?: string
  ): void {
    const requiredValue = value
      ? this.getValueByRegexFromObject(value, requiredRegex)
      : undefined;
    this.errorMessage =
      key !== 'pattern'
        ? this.getMessage(key, requiredValue)
        : this.patternMatchExpression(value, patternKey);
  }

  private patternMatchExpression(
    value: Record<string, unknown> | undefined,
    patternKey?: string
  ): string {
    const regExpDefault = value && Object.entries(regEx).find(
      ([_, val]) => val.toString() === value['requiredPattern']
    );
    const messageKey = regExpDefault ? regExpDefault[0] : patternKey;

    return this.getMessage(`pattern.${messageKey}`);
  }

  private getValueByRegexFromObject(
    obj: Record<string, unknown>,
    regex: RegExp
  ): string | undefined {
    const findValue = Object.entries(obj).find(([key]) => regex.test(key));
    return findValue && findValue[1] as string;
  }

  private getMessage(key: string, param?: any): string {
    return this.translateService.instant(`validations.${key}`, {param})
  }
}
