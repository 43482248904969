import { Pipe, PipeTransform } from '@angular/core';

import { DynamicFormControl } from '../../models/dynamic-form-control.type';
import { Utils } from '../../helpers/utils';

@Pipe({
  name: 'processSearchItems'
})
export class ProcessSearchItemsPipe implements PipeTransform {
  transform(field: DynamicFormControl, vocabularies: any, aggregations: any): any {
    if (field.type !== "salary" && field.type !== "location-autocomplete" && !!field?.values) {
      return field?.values;
    }

    if (vocabularies?.[field.name]) {
      return aggregations?.[field.name]?.map(({ key, doc_count }: any) => ({
        ...vocabularies?.[field.name].terms?.[key],
        count: doc_count,
        disabled: doc_count === 0,
      }))
    }

    return aggregations?.[field.name]?.map(({ key, doc_count }: any) => ({
      id: key,
      title: Utils.capitalizeFirstLetter(key),
      count: doc_count,
      disabled: doc_count === 0,
    }));
  }
}
