import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';

@Injectable({
  providedIn: 'root'
})
export class IsCompanyVerifiedGuard implements CanActivate {
  constructor(
    private guardHelperService: GuardHelperService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const companySlug = route.parent?.params['companySlug'];

    return this.guardHelperService.getCompany(companySlug)
      .pipe(
        map(company => {
          if (!company.verified) {
            this.router.navigate([`/company/${companySlug}/profile`])
            return false;
          }

          return true;
        }),
      );
  }
}
