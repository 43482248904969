import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { GuardHelperService } from '../../../shared/services/guard-helper.service';
import { selectUserType } from '../selectors/auth.selectors';
import { UserType } from '../../../config';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private guardHelperService: GuardHelperService,
    private store: Store,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardHelperService.getUser().pipe(
      withLatestFrom(this.store.pipe(select(selectUserType))),
      switchMap(([user, userType]) => {
        const type = userType ? userType : UserType.TALENT;

        if (!user || (user && !user.isEmailVerified)) {
          this.router.navigate(['/login']);
          return of(false);
        }

        if (user && !user.registrationCompletedAt) {
          this.router.navigate([`/registration/${type}`]);
          return of(false);
        }

        return of(true);
      }),
    );
  }
}
