import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { of, tap } from 'rxjs';

import { selectCompanyProfile } from '../selectors/company.selectors';
import { AuthActions } from '../../auth/actions/auth.actions';
import { CompanyService } from '../services/company.service';
import { JobsActions } from '../../jobs/actions/job.actions';
import { CompanyActions } from '../actions/company.actions';

@Injectable()
export class CompanyEffects {
  constructor(
    private companyService: CompanyService,
    private actions$: Actions,
    private router: Router,
    private store: Store,
  ) {
  }

  loadCompanyRecommendedTalentsAndLatestFeedbacks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.getCompanyProfileSuccess),
      switchMap(({ profile }) => [
        CompanyActions.getCompanyRecommendedTalents({ id: profile.id }),
        CompanyActions.getCompanyLatestFeedbacks({ id: profile.id }),
      ])
    )
  );

  updateFeedbackStepSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.updateFeedbackStepSuccess),
      withLatestFrom(this.store.pipe(select(selectCompanyProfile))),
      switchMap(([, profile]) => [
        CompanyActions.getCompanyLatestFeedbacks({ id: profile.id }),
      ])
    )
  );

  getCompanyProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.getCompanyProfile),
      exhaustMap(({slug}) =>
        this.companyService.getCompany(slug).pipe(
          map(profile => CompanyActions.getCompanyProfileSuccess({profile})),
          catchError(({error}) => of(CompanyActions.getCompanyProfileFailure({error})))
        )
      )
    )
  );

  getCompanyRecommendedTalents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.getCompanyRecommendedTalents),
      exhaustMap(({id}) =>
        this.companyService.getCompanyRecommendedTalents(id).pipe(
          map(recommendedTalents => CompanyActions.getCompanyRecommendedTalentsSuccess({recommendedTalents})),
          catchError(({error}) => of(CompanyActions.getCompanyRecommendedTalentsFailure({error})))
        )
      )
    )
  );

  getCompanyLatestFeedbacks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.getCompanyLatestFeedbacks),
      exhaustMap(({id}) =>
        this.companyService.getCompanyLatestFeedbacks(id).pipe(
          map(latestFeedbacks => CompanyActions.getCompanyLatestFeedbacksSuccess({latestFeedbacks})),
          catchError(({error}) => of(CompanyActions.getCompanyLatestFeedbacksFailure({error})))
        )
      )
    )
  );

  getCompanyProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.getCompanyProfileSuccess),
      map(({profile}) => JobsActions.getJobs({slug: profile.slug}))
    )
  );

  getCompanyProfileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.getCompanyProfileFailure),
      map(() => AuthActions.logOutSuccess()),
    )
  );

  getCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.getCompany),
      exhaustMap(({slug}) =>
        this.companyService.getCompany(slug).pipe(
          map(company => CompanyActions.getCompanySuccess({company})),
          catchError(({error}) => of(CompanyActions.getCompanyFailure({error, silent: true})))
        )
      )
    )
  );

  patchGeneralInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchGeneralInfo),
      exhaustMap(({id, body, slug}) =>
        this.companyService.patchGeneralInfo(id, body).pipe(
          switchMap(profile => [
            CompanyActions.patchGeneralInfoSuccess({profile, slug}),
            AuthActions.updateCompanyUser({profile})
          ]),
          catchError(({error}) => of(CompanyActions.patchGeneralInfoFailure({error})))
        )
      )
    )
  );

  patchGeneralInfoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchGeneralInfoSuccess),
      tap(({profile}) => {
        this.router.navigate([`/company/${profile.slug}/profile`]);
      })
    ), { dispatch: false }
  );

  patchAbout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchAbout),
      exhaustMap(({id, body}) =>
        this.companyService.patchAbout(id, body).pipe(
          map(profile => CompanyActions.patchAboutSuccess({profile})),
          catchError(({error}) => of(CompanyActions.patchAboutFailure({error})))
        )
      )
    )
  );

  patchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchResults),
      exhaustMap(({id, body}) =>
        this.companyService.patchResults(id, body).pipe(
          map(profile => CompanyActions.patchResultsSuccess({profile})),
          catchError(({error}) => of(CompanyActions.patchResultsFailure({error})))
        )
      )
    )
  );

  patchClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchClients),
      exhaustMap(({id, body}) =>
        this.companyService.patchClients(id, body).pipe(
          map(profile => CompanyActions.patchClientsSuccess({profile})),
          catchError(({error}) => of(CompanyActions.patchClientsFailure({error})))
        )
      )
    )
  );

  patchTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchTeam),
      exhaustMap(({id, body}) =>
        this.companyService.patchTeam(id, body).pipe(
          map(profile => CompanyActions.patchTeamSuccess({profile})),
          catchError(({error}) => of(CompanyActions.patchTeamFailure({error})))
        )
      )
    )
  );

  patchVideos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchVideos),
      exhaustMap(({id, body}) =>
        this.companyService.patchVideos(id, body).pipe(
          map(profile => CompanyActions.patchVideosSuccess({profile})),
          catchError(({error}) => of(CompanyActions.patchVideosFailure({error})))
        )
      )
    )
  );

  patchPhotos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchPhotos),
      exhaustMap(({id, body}) =>
        this.companyService.patchPhotos(id, body).pipe(
          map(profile => CompanyActions.patchPhotosSuccess({profile})),
          catchError(({error}) => of(CompanyActions.patchPhotosFailure({error})))
        )
      )
    )
  );

  patchValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchValues),
      exhaustMap(({id, body}) =>
        this.companyService.patchValues(id, body).pipe(
          map(profile => CompanyActions.patchValuesSuccess({profile})),
          catchError(({error}) => of(CompanyActions.patchValuesFailure({error})))
        )
      )
    )
  );

  patchResponsibilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchResponsibilities),
      exhaustMap(({id, body}) =>
        this.companyService.patchResponsibilities(id, body).pipe(
          map(profile => CompanyActions.patchResponsibilitiesSuccess({profile})),
          catchError(({error}) => of(CompanyActions.patchResponsibilitiesFailure({error})))
        )
      )
    )
  );

  patchBenefits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchBenefits),
      exhaustMap(({id, body}) =>
        this.companyService.patchBenefits(id, body).pipe(
          map(profile => CompanyActions.patchBenefitsSuccess({profile})),
          catchError(({error}) => of(CompanyActions.patchBenefitsFailure({error})))
        )
      )
    )
  );

  createProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.createProduct),
      exhaustMap(({id, body}) =>
        this.companyService.createProduct(id, body).pipe(
          map(products => CompanyActions.createProductSuccess({products})),
          catchError(({error}) => of(CompanyActions.createProductFailure({error})))
        )
      )
    )
  );

  patchProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.patchProduct),
      exhaustMap(({id, body}) =>
        this.companyService.patchProduct(id, body).pipe(
          map(product => CompanyActions.patchProductSuccess({id, product})),
          catchError(({error}) => of(CompanyActions.patchProductFailure({error})))
        )
      )
    )
  );

  deleteProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.deleteProduct),
      exhaustMap(({id}) =>
        this.companyService.deleteProduct(id).pipe(
          map(() => CompanyActions.deleteProductSuccess({id})),
          catchError(({error}) => of(CompanyActions.deleteProductFailure({error})))
        )
      )
    )
  );
}
