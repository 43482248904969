import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { SearchPageComponent } from './containers/search-page/search-page.component';
import { TalentsCanActivateGuard } from './guards/talents-can-activate.guard';
import { AppRoutes } from '../../models/app-routes.type';
import { AuthGuard } from '../auth/guards/auth.guard';

const routes: AppRoutes = [
  {
    path: '',
    component: SearchPageComponent,
    data: {
      breadcrumbs: [{title: 'search.title'}],
      pageTitle: {title: 'search.title'}
    },
    children: [
      {
        path: 'talents',
        loadChildren: () => import('./modules/talents/talents.module').then(m => m.TalentsModule),
        canActivate: [
          AuthGuard,
          TalentsCanActivateGuard
        ],
      },
      {
        path: 'jobs',
        loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
        data: {
          navigationSettings: {
            anonymous: {
              showNavigation: false,
              showLoginButton: true
            }
          }
        }
      },
      {
        path: 'companies',
        loadChildren: () => import('./modules/companies/companies.module').then(m => m.CompaniesModule),
        data: {
          navigationSettings: {
            anonymous: {
              showNavigation: false,
              showLoginButton: true
            }
          }
        }
      },
      {
        path: '**',
        redirectTo: 'jobs'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule {
}
