import { Component } from '@angular/core';

import { ModalComponent } from '../../modules/modal/components/modal.component';

@Component({
  selector: 'app-modal-congratulation',
  templateUrl: './modal-congratulation.component.html',
  styleUrls: ['./modal-congratulation.component.scss']
})
export class ModalCongratulationComponent extends ModalComponent<any, any> {
  public slug: any;

  constructor() {
    super()
  }
}
