import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { JobsActions } from '../../jobs/actions/job.actions';
import { TalentActions } from '../actions/talent.actions';

export const talentFeatureKey = 'talent';

export interface State extends EntityState<any> {
  profile: any;
  recommendedJobs: any[];
  feedbacks: any[];
  selectedTalentId: string | null;
}

export const adapter: EntityAdapter<any> = createEntityAdapter({
  selectId: (talent: any) => talent.slug,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  profile: null,
  recommendedJobs: [],
  feedbacks: [],
  selectedTalentId: null,
});

export const talentReducer = createReducer(
  initialState,

  on(TalentActions.getTalentProfileSuccess, (state, {profile}) => adapter.addOne(profile, state)),

  on(TalentActions.getTalentSuccess, (state, {talent}) => adapter.addOne(talent, state)),

  on(TalentActions.selectTalent, (state, {slug}) => ({...state, selectedTalentId: slug})),

  on(
    TalentActions.getTalentProfileSuccess,
    TalentActions.patchPersonalInfoSuccess,
    TalentActions.patchExperienceSuccess,
    TalentActions.patchSkillsSuccess,
    TalentActions.patchLanguagesSuccess,
    TalentActions.patchAdditionalInfoSuccess,
    (state, {profile}) => ({...state, profile})
  ),

  on(JobsActions.feedbackToJobSuccess, (state, {feedback}) => ({
    ...state,
    feedbacks: [feedback, ...state.feedbacks],
    recommendedJobs: state.recommendedJobs.map((job: any) => {
      if (job.id === feedback.job.id) {
        return {
          ...job,
          isApplied: true,
          appliedAt: new Date().toISOString(),
        };
      }

      return job;
    })
  })),

  on(TalentActions.getTalentFeedbacksSuccess, (state, {feedbacks}) => ({...state, feedbacks})),

  on(TalentActions.getTalentRecommendedJobsSuccess, (state, {recommendedJobs}) => ({...state, recommendedJobs})),

  on(
    TalentActions.patchExperienceSuccess,
    TalentActions.patchSkillsSuccess,
    TalentActions.patchLanguagesSuccess,
    TalentActions.patchAdditionalInfoSuccess,
    (state, {profile}) => adapter.updateOne({id: profile.slug, changes: profile}, state)
  ),

  on(
    TalentActions.patchPersonalInfoSuccess,
    (state, {profile, slug}) => adapter.mapOne({
      id: slug,
      map: () => profile,
    }, state)
  ),

  // Talent Work Experience.
  on(TalentActions.createWorkExpSuccess, (state, {workExperiences}) => {
    const profile = {
      ...state.profile,
      workExperiences
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),

  on(TalentActions.patchWorkExpSuccess, (state, {id, workExp}) => {
    const workExpIndex = state.profile.workExperiences.findIndex((edu: any) => edu.id === id);
    const workExperiences = [...state.profile.workExperiences];
    workExperiences[workExpIndex] = workExp;

    const profile = {
      ...state.profile,
      workExperiences
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),

  on(TalentActions.deleteWorkExpSuccess, (state, {id}) => {
    const profile = {
      ...state.profile,
      workExperiences: [
        ...state.profile.workExperiences.filter((workExp: any) => workExp.id !== id),
      ]
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),


  // Talent Education.
  on(TalentActions.createEducationSuccess, (state, {educations}) => {
    const profile = {
      ...state.profile,
      educations
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),

  on(TalentActions.patchEducationSuccess, (state, {id, education}) => {
    const educationIndex = state.profile.educations.findIndex((edu: any) => edu.id === id);
    const educations = [...state.profile.educations];
    educations[educationIndex] = education;

    const profile = {
      ...state.profile,
      educations
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),

  on(TalentActions.deleteEducationSuccess, (state, {id}) => {
    const profile = {
      ...state.profile,
      educations: [
        ...state.profile.educations.filter((education: any) => education.id !== id),
      ]
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),

  // Talent Course.
  on(TalentActions.createCourseSuccess, (state, {courses}) => {
    const profile = {
      ...state.profile,
      courses
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),

  on(TalentActions.patchCourseSuccess, (state, {id, course}) => {
    const courseIndex = state.profile.courses.findIndex((c: any) => c.id === id);
    const courses = [...state.profile.courses];
    courses[courseIndex] = course;

    const profile = {
      ...state.profile,
      courses
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),

  on(TalentActions.deleteCourseSuccess, (state, {id}) => {
    const profile = {
      ...state.profile,
      courses: [
        ...state.profile.courses.filter((course: any) => course.id !== id),
      ]
    };

    return adapter.updateOne({id: state.profile.slug, changes: profile}, ({...state, profile}));
  }),
);

export const selectId = (state: State) => state.selectedTalentId;
