import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Optional,
  PLATFORM_ID,
  Type,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { ModalHolderComponent } from './../components/modal-holder.component';
import { ModalComponent } from './../components/modal.component';
import { ModalOptionsOverrides } from './../modal-options';

export class ModalServiceConfig {
  container: HTMLElement | string | null = null;
}

@Injectable()
export class ModalService {
  private modalHolderComponent!: ModalHolderComponent;
  private _container: any;

  public static instance: ModalService | null = null;

  constructor(
    private resolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    @Optional() config: ModalServiceConfig,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    ModalService.instance = this;

    if (isPlatformBrowser(platformId) && config) {
      this.container = config.container as any;
    }
  }

  addModal<T, T1>(
    component: Type<ModalComponent<T, T1>> | any,
    data?: T,
    options?: ModalOptionsOverrides
  ): Observable<T1 | any> {
    if (isPlatformServer(this.platformId)) {
      return of(null);
    }

    if (!this.modalHolderComponent) {
      this.modalHolderComponent = this.createSimpleModalHolder();
    }
    return this.modalHolderComponent.addModal<T, T1>(component, data, options);
  }

  removeModal(component: ModalComponent<any, any>): Promise<{}> {
    if (!this.modalHolderComponent) {
      return Promise.resolve({});
    }
    return this.modalHolderComponent.removeModal(component);
  }

  removeAll(): Promise<{}> {
    if (!this.modalHolderComponent) {
      return Promise.resolve({});
    }
    return this.modalHolderComponent.removeAllModals();
  }

  private set container(c) {
    this._container = c;
  }

  private get container(): HTMLElement {
    if (typeof this._container === 'string') {
      this._container = document.getElementById(this._container);
    }

    if (!this._container && this.applicationRef['components'].length) {
      const componentRootViewContainer = this.applicationRef['components'][0];
      this.container = (componentRootViewContainer.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
    }

    if (!this._container || typeof this._container === 'string') {
      this._container = document.getElementsByTagName('body')[0];
    }

    return this._container;
  }

  private createSimpleModalHolder(): ModalHolderComponent {
    const componentFactory = this.resolver.resolveComponentFactory(ModalHolderComponent);

    const componentRef = componentFactory.create(this.injector);
    const componentRootNode = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    this.applicationRef.attachView(componentRef.hostView);

    componentRef.onDestroy(() => {
      this.applicationRef.detachView(componentRef.hostView);
    });

    this.container.appendChild(componentRootNode);

    return componentRef.instance;
  }
}
