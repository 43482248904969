<div class="modal-form modal-form--small modal-form--small-py z-50 mx-auto bg-yellow text-black">
  <div class="modal-form__icon">
    <img src="/assets/smiles/clapperboard.png" alt="Icon">
  </div>

  <div class="modal-confirm__content py-5 max-w-[350px] mx-auto text-center">
    {{ 'modal.congratulation.title' | translate }}
  </div>

  <div class="modal-form__action gap-4 flex flex-col md:flex-row">
    <button
      routerLink="/talent/{{slug}}/profile"
      (click)="close()"
      class="big-btn big-btn--small big-btn__border max-w-none md:flex-grow"
    >
      {{ 'modal.congratulation.btn_upgrade_profile' | translate }}
    </button>

    <button
      routerLink="/search/jobs"
      (click)="close()"
      class="big-btn big-btn--small bg-black text-white max-w-none md:flex-grow"
    >
      {{ 'modal.congratulation.btn_back' | translate }}
    </button>
  </div>
</div>
