import { createFeatureSelector, createSelector } from '@ngrx/store';

import { adapter, companyFeatureKey, selectId, State } from '../reducers/company.reducer';

export const selectCompanyState = createFeatureSelector<State>(
  companyFeatureKey
);

export const selectCompanyProfile = createSelector(selectCompanyState, (state: any) => state.profile);

export const {
  selectIds,
  selectEntities: selectCompanyEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectCompanyState);


export const selectSelectedCompanyId = createSelector(
  selectCompanyState,
  selectId
);

export const selectSelectedCompany = createSelector(
  selectCompanyEntities,
  selectSelectedCompanyId,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

export const selectRecommendedTalents = createSelector(selectCompanyState, state => state.recommendedTalents);

export const selectLatestFeedbacks = createSelector(selectCompanyState, state => state.latestFeedbacks);
