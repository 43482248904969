<div class="modal-form modal-form--small">
  <div class="modal-form__title dark:text-black">
    {{ 'modal.cropper.title' | translate }}
  </div>

  <form class="modal-form__main">
    <div class="modal-form__wrapper">
      <div class="p-1 bg-white">
        <image-cropper
          *ngIf="isBrowser"
          class="cropper"
          [imageFile]="imageFile"
          [maintainAspectRatio]="true"
          [aspectRatio]="1"
          [transform]="transform"
          [cropperMinWidth]="200"
          [containWithinAspectRatio]="false"
          format="webp"
          [roundCropper]="true"
          (imageCropped)="imageCropped($event)"
        ></image-cropper>
      </div>

      <div class="cropper-action flex justify-center gap-3">
        <button type="button" class="circle-btn w-12" (click)="zoomOut()">
          <svg-icon src="minus.svg"></svg-icon>
        </button>
        <button type="button" class="circle-btn w-12" (click)="zoomIn()">
          <svg-icon src="place.svg"></svg-icon>
        </button>
      </div>
    </div>

    <div class="modal-form__action flex gap-5 dark:text-black">
      <button type="button" class="big-btn big-btn--small w-full bg-white justify-end" (click)="resetImage()">
        {{ 'btn.reset_image' | translate }}
      </button>
      <button class="big-btn big-btn--small w-full bg-white justify-end" (click)="saveImage()">
        {{ 'btn.save' | translate }}
      </button>
    </div>
  </form>

  <button class="modal-form__close dark:text-black" (click)="close()">
    <svg-icon src="close.svg"></svg-icon>
  </button>
</div>
