import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';

import { RouteWithAnimationComponent } from '../../../../shared/components/abstract/route-with-animation.component';
import { Roles } from '../../../../config';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPageComponent extends RouteWithAnimationComponent {
  public roles = Roles;
  public activeTab = 'talents';

  get activatedTab() {
    return this.tabGroup?.nativeElement?.querySelector('.active');
  }

  get styles() {
    return {'left.px': this.activatedTab?.offsetLeft || 0, 'width.px': this.activatedTab?.clientWidth || 0}
  }

  @ViewChild('tabGroup') tabGroup!: ElementRef;

  constructor() {
    super();
  }

  public toggleTab(event: any, name: string): void {
    this.activeTab = name;
  }
}
