<div class="loader bg-white dark:bg-tundora" #loader>
  <div
    class="circle text-black dark:text-white"
    *ngIf="showLoader"
    @fadeOutOnLeave
  >
    <svg-icon src="loader.svg"></svg-icon>
  </div>

  <div class="checked text-black dark:text-white show" *ngIf="showCheckMark">
    <svg-icon src="checked.svg"></svg-icon>
  </div>
</div>
