/* eslint-disable import/no-duplicates */

import { InjectionToken } from '@angular/core';
import { getYear, Locale } from 'date-fns';
import locale from 'date-fns/locale/en-US';

export interface DatepickerOptions {
  minDate?: Date | null;
  maxDate?: Date | null;
  minYear?: number;
  maxYear?: number;
  format?: string;
  formatTitleMonths?: string;
  formatTitleYears?: string;
  formatDays?: string;
  firstCalendarDay?: number;
  locale?: Locale;
  position?: 'left' | 'right' | 'bottom' | 'top';
  inputClass?: string;
  calendarClass?: string;
  scrollBarColor?: string;
  enableKeyboard?: boolean;
  view: 'months' | 'days';
}

export const DATEPICKER_OPTIONS = new InjectionToken<DatepickerOptions>('Datepicker config');

export function mergeDatepickerOptions(opts: DatepickerOptions): DatepickerOptions {
  return {...defaultOptions, ...opts};
}

export const defaultOptions: DatepickerOptions = {
  minDate: null,
  maxDate: new Date(),
  minYear: getYear(new Date(1980, 1, 1)),
  maxYear: getYear(new Date()),
  format: 'MMMM yyyy',
  formatTitleMonths: 'LLLL',
  formatTitleYears: 'yyyy',
  formatDays: 'EEEEEE',
  firstCalendarDay: 1,
  locale,
  position: 'bottom',
  inputClass: '',
  calendarClass: 'datepicker-default',
  scrollBarColor: '#FDD835',
  enableKeyboard: true,
  view: 'months',
};

export interface IDay {
  date: Date;
  day: number;
  month: number;
  year: number;
  inThisMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
  isSelectable: boolean;
}
