import { FileUploadControl } from '@iplab/ngx-file-upload';
import { ValidationErrors } from '@angular/forms';

function IsNullOrEmpty(value: any): boolean {
  return value == null || value.length === 0;
}

const checkFileSize = (actualSize: number, maxSize: number, minSize: number = 0, errorFile: File): ValidationErrors | null => {
  return (!IsNullOrEmpty(maxSize) && actualSize > maxSize) || actualSize < minSize ?
    {maxSize, minSize, actual: actualSize, errorFile} : null;
};

const checkValueType = (value: any ): void => {
  if (!Array.isArray(value)) {
    throw Error(`FormControl.setValue was provided with wrong argument type, ${value} was provided instead Array<File>`);
  }
};

export function fileSizeValidator(maxSize: number): any {
  return (control: FileUploadControl): ValidationErrors | null => {
    const files: Array<File> = control.value;

    if (IsNullOrEmpty(files)) {
      return null;
    }

    checkValueType(files);

    // @ts-ignore
    control.errors = control.errors.filter((error: any) => {
      return Object.keys(error)[0] !== 'fileSize';
    });

    const toLargeFiles = files.map((file: File, index: number) => checkFileSize(file.size, maxSize, 0, file))
      .filter((error) => error);


    return toLargeFiles.length > 0 ? {'fileSize': toLargeFiles} : null;
  };
}
