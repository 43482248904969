import { ChangeDetectionStrategy, Component } from '@angular/core';

import { RouteWithAnimationComponent } from '../../../../shared/components/abstract/route-with-animation.component';

@Component({
  selector: 'app-company-page',
  template: `
    <main [@routeSlide]="routeTrigger$ | async" class="w-full relative">
      <router-outlet (activate)="onActivate()"></router-outlet>
    </main>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyPageComponent extends RouteWithAnimationComponent {
  constructor() {
    super();
  }
}
