import { createAction, props } from '@ngrx/store';

import { IRequestError } from '../../../models';

const postJob = createAction('[Jobs] Post Job');

const createJob = createAction('[Jobs] Create Job Request', props<{ body: any }>());
const createJobSuccess = createAction('[Jobs] Create Job Success', props<{ job: any }>());
const createJobFailure = createAction('[Jobs] Create Job Failure', props<IRequestError>());

const getJobs = createAction('[Jobs] Get Jobs Request', props<{ slug: string }>());
const getJobsSuccess = createAction('[Jobs] Get Jobs Success', props<{ jobs: any[] }>());
const getJobsFailure = createAction('[Jobs] Get Jobs Failure', props<IRequestError>());

const getJob = createAction('[Jobs] Get Job Request', props<{ slug: string }>());
const getJobSuccess = createAction('[Jobs] Get Job Success', props<{ job: any }>());
const getJobFailure = createAction('[Jobs] Get Job Failure', props<IRequestError>());

const getJobFeedbacks = createAction('[Jobs] Get Job Feedbacks Request', props<{ slug: string }>());
const getJobFeedbacksSuccess = createAction(
  '[Jobs] Get Job Feedbacks Success', props<{ feedbacks: any[], flow: any[] }>(),
);
const getJobFeedbacksFailure = createAction('[Jobs] Get Job Feedbacks Failure', props<IRequestError>());

const openJob = createAction('[Jobs] Open Job Request', props<{ id: string, slug: string, redirect: boolean }>());
const openJobSuccess = createAction('[Jobs] Open Job Success', props<{ job: any, slug: string, redirect: boolean }>());
const openJobFailure = createAction('[Jobs] Open Job Failure', props<IRequestError>());

const closeJob = createAction('[Jobs] Close Job Request', props<{ id: string }>());
const closeJobSuccess = createAction('[Jobs] Close Job Success', props<{ job: any }>());
const closeJobFailure = createAction('[Jobs] Close Job Failure', props<IRequestError>());

const patchGeneralInfo = createAction('[Jobs] Patch General Info Request', props<{ id: string, body: any }>());
const patchGeneralInfoSuccess = createAction('[Jobs] Patch General Info Success', props<{ id: string, job: any }>());
const patchGeneralInfoFailure = createAction('[Jobs] Patch General Info Failure', props<IRequestError>());

const patchAbout = createAction('[Jobs] Patch About Request', props<{ id: string, body: any }>());
const patchAboutSuccess = createAction('[Jobs] Patch About Success', props<{ id: string, job: any }>());
const patchAboutFailure = createAction('[Jobs] Patch About Failure', props<IRequestError>());

const patchRequirements = createAction('[Jobs] Patch Requirements Request', props<{ id: string, body: any }>());
const patchRequirementsSuccess = createAction('[Jobs] Patch Requirements Success', props<{ id: string, job: any }>());
const patchRequirementsFailure = createAction('[Jobs] Patch Requirements Failure', props<IRequestError>());

const patchResults = createAction('[Jobs] Patch Results Request', props<{ id: string, body: any }>());
const patchResultsSuccess = createAction('[Jobs] Patch Results Success', props<{ id: string, job: any }>());
const patchResultsFailure = createAction('[Jobs] Patch Results Failure', props<IRequestError>());

const patchSalary = createAction('[Jobs] Patch Salary Request', props<{ id: string, body: any }>());
const patchSalarySuccess = createAction('[Jobs] Patch Salary Success', props<{ id: string, job: any }>());
const patchSalaryFailure = createAction('[Jobs] Patch Salary Failure', props<IRequestError>());

const patchConditions = createAction('[Jobs] Patch Conditions Request', props<{ id: string, body: any }>());
const patchConditionsSuccess = createAction('[Jobs] Patch Conditions Success', props<{ id: string, job: any }>());
const patchConditionsFailure = createAction('[Jobs] Patch Conditions Failure', props<IRequestError>());

const deleteJob = createAction('[Jobs] Delete Job Request', props<{ id: string, slug: string }>());
const deleteJobSuccess = createAction('[Jobs] Delete Job Success', props<{ slug: string }>());
const deleteJobFailure = createAction('[Jobs] Delete Job Failure', props<IRequestError>());

const feedbackToJobOpenModal = createAction('[Jobs] Feedback To Job Open Modal', props<{ job: any }>());
const feedbackToJobCancel = createAction('[Jobs] Feedback To Job Cancel', props<{ job: any }>());
const feedbackToJob = createAction('[Jobs] Feedback To Job Request', props<{ id: string, body: any, slug: string }>());
const feedbackToJobSuccess = createAction('[Jobs] Feedback To Job Success', props<{ feedback: any }>());
const feedbackToJobFailure = createAction('[Jobs] Feedback To Job Failure', props<IRequestError>());

const updateFeedbackStep = createAction('[Jobs] Update Feedback Step', props<{ id: string, body: any }>());
const updateFeedbackStepSuccess = createAction('[Jobs] Update Feedback Step Success', props<{ flowStep: any, id: string }>());
const updateFeedbackStepFailure = createAction('[Jobs] Update Feedback Step Failure', props<IRequestError>());

const createFeedbackComment = createAction('[Jobs] Create Feedback Comment', props<{ id: string, body: any }>());
const createFeedbackCommentSuccess = createAction('[Jobs] Create Feedback Comment Success', props<{ id: string, comment: any }>());
const createFeedbackCommentFailure= createAction('[Jobs] Create Feedback Comment Failure', props<IRequestError>());

const updateFeedbackComment = createAction(
  '[Jobs] Update Feedback Comment',
  props<{ id: string, feedbackId: string, body: any }>(),
);
const updateFeedbackCommentSuccess = createAction(
  '[Jobs] Update Feedback Comment Success',
  props<{ id: string, feedbackId: string, comment: any }>(),
);
const updateFeedbackCommentFailure= createAction('[Jobs] Update Feedback Comment Failure', props<IRequestError>());

const deleteFeedbackComment = createAction('[Jobs] Delete Feedback Comment', props<{ id: string, feedbackId: string }>());
const deleteFeedbackCommentSuccess = createAction(
  '[Jobs] Delete Feedback Comment Success',
  props<{ id: string, feedbackId: string }>(),
);
const deleteFeedbackCommentFailure= createAction('[Jobs] Delete Feedback Comment Failure', props<IRequestError>());


const selectJob = createAction('[Jobs] Select Job', props<{ slug: string }>());

export const JobsActions = {
  postJob,

  createJob,
  createJobSuccess,
  createJobFailure,

  getJobs,
  getJobsSuccess,
  getJobsFailure,

  getJob,
  getJobSuccess,
  getJobFailure,

  getJobFeedbacks,
  getJobFeedbacksSuccess,
  getJobFeedbacksFailure,

  openJob,
  openJobSuccess,
  openJobFailure,

  closeJob,
  closeJobSuccess,
  closeJobFailure,

  patchGeneralInfo,
  patchGeneralInfoSuccess,
  patchGeneralInfoFailure,

  patchAbout,
  patchAboutSuccess,
  patchAboutFailure,

  patchRequirements,
  patchRequirementsSuccess,
  patchRequirementsFailure,

  patchResults,
  patchResultsSuccess,
  patchResultsFailure,

  patchSalary,
  patchSalarySuccess,
  patchSalaryFailure,

  patchConditions,
  patchConditionsSuccess,
  patchConditionsFailure,

  deleteJob,
  deleteJobSuccess,
  deleteJobFailure,

  feedbackToJobOpenModal,
  feedbackToJobCancel,
  feedbackToJob,
  feedbackToJobSuccess,
  feedbackToJobFailure,

  updateFeedbackStep,
  updateFeedbackStepSuccess,
  updateFeedbackStepFailure,

  createFeedbackComment,
  createFeedbackCommentSuccess,
  createFeedbackCommentFailure,

  updateFeedbackComment,
  updateFeedbackCommentSuccess,
  updateFeedbackCommentFailure,

  deleteFeedbackComment,
  deleteFeedbackCommentSuccess,
  deleteFeedbackCommentFailure,

  selectJob,
};
