import { NgModule } from '@angular/core';

import { CompanyModule } from './company/company.module';
import { TalentModule } from './talent/talent.module';
import { SearchModule } from './search/search.module';
import { AuthModule } from './auth/auth.module';
import { JobsModule } from './jobs/jobs.module';

@NgModule({
  declarations: [],
  imports: [
    AuthModule,
    SearchModule,
    TalentModule,
    CompanyModule,
    JobsModule,
  ]
})
export class ModulesModule {
}
